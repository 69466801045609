/**
 * ██╗  ██╗ ██████╗ ██████╗  ██████╗
 * ██║ ██╔╝██╔═══██╗██╔══██╗██╔═══██╗
 * █████╔╝ ██║   ██║██████╔╝██║   ██║
 * ██╔═██╗ ██║   ██║██╔══██╗██║   ██║
 * ██║  ██╗╚██████╔╝██████╔╝╚██████╔╝
 * ╚═╝  ╚═╝ ╚═════╝ ╚═════╝  ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */


// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

import * as TOLINO from "./tolino/";
import * as KOBO from "./kobo/";
import * as KOBOJP from './kobojp/';

import { createUtils } from './common/utils';


// -----------------------------------------------------------------------------
// EXPORTS / COMPOSITION OF NAMED THEMES
// -----------------------------------------------------------------------------

const THEME_SET = {
  "tolino": TOLINO,
  "kobo":   KOBO,
  "kobojp": KOBOJP
};


/**
 * Imports a theme set identified by @themeSetName (= module path),
 * see https://2ality.com/2017/01/import-operator.html
 *
 * @param {String} [themeSetName="tolino"] Name of the theme set that is identical to the module path
 * @return {Promise} Promise resolved with the imported theme set
 *
 * NOTE: If we ever have multiple themes, this function will be used to load
 *       the theme properties, i.e. colors, variables etc. For now, we disable
 *       it, because otherwise we would need to handle metro/webpack config.
 */
// export function loadThemeSet(themeSetName = "tolino") {
//   const modulePath = `./${themeSetName}.js`;
//   return import(modulePath);
// }


/**
 * Returns the map of themes from all theme sets combined.
 * Currently there's only a single set of variables ("default");
 * a nested loop is required if new sets of variables are added in the future.
 *
 * @return {Object}
 */
export function getThemes() {
  const themes = {};

  /**
   * Theme generator function
   *
   * @param {String} colorKey Name/key of the color palette
   * @param {String} [variablesKey] Name/key of the set of variables
   * @return {Object} Theme object with attributes `colors`, `variables`, and `mixins`
   */
  function createTheme(themeSet, colorKey, variablesKey) {
    const colors    = { ...themeSet.COLORS_DEFAULT, ...themeSet.COLORS[colorKey] };
    const variables = variablesKey in themeSet.VARIABLES
      ? { ...themeSet.VARIABLES_DEFAULT, ...themeSet.VARIABLES[variablesKey] }
      : themeSet.VARIABLES_DEFAULT;
    const utils = createUtils(colors, variables);

    return {
      assets: themeSet.assets,
      colors,
      icons: themeSet.icons,
      mixins: themeSet.createMixIns(colors, variables, utils),
      utils,
      variables
    };
  }

  Object.keys(THEME_SET).forEach(function(themeSetId) {
    const themeSet = THEME_SET[themeSetId];

    Object.keys(themeSet.COLORS).forEach(function(colorKey) {
      const themeName = `theme-${themeSetId}-${colorKey}`;
      const theme = themes[themeName] = createTheme(themeSet, colorKey);
      // add the keys as `themeSetId` and `name` attributes to each theme object
      theme.themeSetId = themeSetId;
      theme.name = themeName;
    });
  });

  return themes;
}
