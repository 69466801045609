/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { escapeRegExpMetaChars } from '@rakuten/common-util/base';
import { escapeFileName, resolveRelativePath } from '@rakuten/common-util/file';
import { getValueByIgnoredCaseKey } from '@rakuten/common-util/object';
import logger from '@rakuten/services-common';
import ArchiveEntry from './ArchiveEntry';
import ArchiveWorkerManager from './ArchiveWorkerManager';
import ByteArray from './ByteArray';
import RangeBuffer from './RangeBuffer';

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[service/decompress/unzip]';

/**
 * The byte length to buffer when reading the central directory
 *
 * @type {number}
 * @default
 */
const CENTRAL_DIR_FETCH_BYTES = 2048;

/**
 * The length of a central directory's fixed file header
 *
 * @type {number}
 * @default
 */
const FIXED_CENTRAL_DIRECTORY_HEADER_LENGTH = 42;

/**
 * The length of the end-of-central-directory-record w/o the
 * fields that have variable length
 *
 * @type {number}
 * @default
 */
const END_OF_CENTRAL_DIRECTORY_LENGTH = 22;

/**
 * The signature of the end-of-central-directory record
 *
 * @type {array}
 * @default
 */
const END_OF_CENTRAL_DIR_SIGNATURE = [ 80, 75, 5, 6 ];

/**
 * The signature that separates entries in the ZIP archive's
 * central directory from each other.
 *
 * @type {array}
 */
const CENTRAL_FILE_SIGNATURE = [ 80, 75, 1, 2 ];

/**
 * @type {number}
 * @default
 */
const SIGNATURE_LENGTH = 4;

/**
 * The end-of-central-directory-record contains information about the archive
 * itself. It is located at the end of the archive starting with the 4-byte
 * signature 0x50, 0x4B, 0x05, 0x06. This record is used to determine the
 * start of the central directory.
 *
 * @type {object}
 * @default
 */
const END_OF_CENTRAL_DIRECTORY = {
  signature: [0, 4],
  diskNumber: [4, 2],
  diskNumWithCD: [6, 2],
  diskEntries: [8, 2],
  totalEntries: [10, 2],
  centralDirectorySize: [12, 4],
  centralDirectoryOffset: [16, 4],
  commentLength: [20, 2]
};

/**
 * Header information of items in the ZIP archive's central directory.
 * Each header has two values: The byte offset of this header in relation to
 * the central directory entry's start byte and the byte length of the header.
 * NOTE: The byte offset ignores the signature of the central directory entry.
 *       For example, if the central directory entry starts with
 *       [ 80, 75, 1, 2, 10, 11, ... ],
 *       a byte offset of 0 marks the 5th position in the array, that is 10.
 *
 * @type {object}
 * @default
 */
const CENTRAL_FILE_HEADERS = {
  versionMadeBy: [0, 2],
  versionNeedToExtract: [2, 2],
  bitFlag: [4, 2],
  compressionMethod: [6, 2],
  lastModFileTime: [8, 2],
  lastModFileDate: [10, 2],
  crc32: [12, 4],
  compressedSize: [16, 4],
  uncompressedSize: [20, 4],
  fileNameLength: [24, 2],
  extraFieldLength: [26, 2],
  fileCommentLength: [28, 2],
  diskNumberStart: [30, 2],
  internalFileAttributes: [32, 2],
  externalFileAttributes: [34, 4],
  localHeaderOffset: [38, 4]
};

/**
 * Names of headers that have a variable length
 *
 * @type {array}
 * @default
 */
const VARIABLE_LENGTH_HEADERS = [
  'extraFieldLength',
  'fileCommentLength',
  'fileNameLength'
];


// -----------------------------------------------------------------------------
// HELPER
// -----------------------------------------------------------------------------

/**
 * Compares the content of two signatures (byte arrays) @a and @b
 *
 * @param {Array} a Signature a
 * @param {Array} b Signature b
 * @return {boolean} True if @a and @b are equal
 */
function isEqualSignature(a, b) {
  for (let i = SIGNATURE_LENGTH - 1; i >= 0; i--) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}


/**
 * Determines the central file directory's start position within the ZIP
 * archive, i.e. the byte offset of the first signature that signals a central
 * directory entry.
 *
 * @param {Uint8Array} data TypedArray view for the ZIP archive's binary data
 * @return {number} Start position of the central directory, -1 if no valid position was found
 */
function getCentralDirStartPos(data) {
  let offset = CENTRAL_DIR_FETCH_BYTES - END_OF_CENTRAL_DIRECTORY_LENGTH;
  while (offset >= 0) {
    let signature = data.subarray(offset, offset + SIGNATURE_LENGTH);
    let isEndOfCentralDir = isEqualSignature(signature, END_OF_CENTRAL_DIR_SIGNATURE);
    if (isEndOfCentralDir) {
      const offsetRange = END_OF_CENTRAL_DIRECTORY.centralDirectoryOffset;
      const start = offset + offsetRange[0];
      const bytes = data.subarray(start, start + offsetRange[1]);
      return ByteArray.asNumber(bytes);
    }
    offset--;
  }

  return -1;
}


/**
 * Reads all entries from @data (the ZIP archive's central directory)
 * and writes the entries into @centralDir.
 *
 * @param {Uint8Array} data TypedArray view for the ZIP archive's binary data
 * @param {Array} centralDir
 * @return {Boolean}
 */
function readCentralDir(data, centralDir) {
  let offset = 0;

  while (true) {
    // check the file entry's signature
    const signature = data.subarray(offset, offset + 4);
    // Exit on invalid signature or if the end of the central directory is reached:
    if (!isEqualSignature(signature, CENTRAL_FILE_SIGNATURE)) {
      return true;
    }
    const dirItem = Object.create(null); // new central directory entry
    let extraOffset = 0; // Additional offset for the next file

    // skip signature bytes
    offset += 4;

    // Read fixed headers
    for (let header in CENTRAL_FILE_HEADERS) {
      // Get range information
      const range = CENTRAL_FILE_HEADERS[header];
      const start = offset + range[0];
      const end   = start  + range[1];

      // Get value and add it to the current item
      const value = ByteArray.asNumber(data.subarray(start, end));
      dirItem[header] = value;

      // Besides the fixed length headers, there are also some variable
      // length headers available, e.g. file name, extra field etc.
      // We need to remember the lengths of these headers to be able to
      // determine the start byte of the next directory entry.
      if (VARIABLE_LENGTH_HEADERS.includes(header)) {
        extraOffset += value;
      }
    }

    // Read variable headers
    offset += FIXED_CENTRAL_DIRECTORY_HEADER_LENGTH;

    // Read the file's name
    const rawName = data.subarray(offset, offset + dirItem.fileNameLength);
    const fileName = ByteArray.asUTF8String(rawName);
    dirItem.fileName = escapeFileName(fileName);

    // Add the item to the central directory info
    centralDir.push(dirItem);

    // Calculate start position of next file
    offset += extraOffset;
  }
}


/**
 * Compares @fileName with all items from @centralDir and returns the matching
 * file name, otherwise "".
 *
 * @param {String} fileName Name of the file to search
 * @param {Array} centralDir List of file items in the archive
 * @return {String} File name from a @centralDir item matching @fileName
 */
function getMatchingFilePath(fileName, centralDir) {
  if (!fileName || !centralDir?.length) {
    return "";
  }
  const regExpFileName = new RegExp(escapeRegExpMetaChars(escapeFileName(fileName)) + "$","ig");
  const dirItem = centralDir.find(
    (item) => regExpFileName.test(item.fileName)
  );
  return dirItem?.fileName || "";
}


// -----------------------------------------------------------------------------
// ARCHIVE CLASS
// -----------------------------------------------------------------------------

/**
 * This class provides functionality for managing ZIP archives. Each instance
 * object represents a single archive.
 *
 * It is based on CoffeeZip by Eric Heydenberk and JSUnzip by August Lilleaas.
 *
 * @see {@link https://github.com/appish/CoffeeZip|CoffeeZip}
 * @see {@link https://github.com/augustl/js-unzip|JSUnzip}
 * @see {@link http://www.pkware.com/documents/casestudies/APPNOTE.TXT|PKZIP file format specification}
 */
class Archive {

  // ---------------------------------------------------------------------------
  // INIT/DESTROY
  // ---------------------------------------------------------------------------

  constructor(buffer, maxWorker = 1, services, url) {
    //fetch service for range requests
    this.fetchService = services.fetchService;
    this.rangeBuffer = buffer instanceof RangeBuffer
    ? buffer
    : new RangeBuffer(buffer, url, this.fetchService);

    this.url = url;
    // Worker manager is responsible for handling web workers for unzipping
    this.workerManager = new ArchiveWorkerManager(maxWorker);
    this.centralDirectory = [ ];
    this.files = Object.create(null);

    // Cryptography / DRM
    this.cryptoService = services.crypto;
    this.cryptoInfo = null;
    this.services = services;

    // Wrapper function to fetch the data from the rangeBuffer as a typed Uint8Array
    this.getData = this.rangeBuffer.getTypedArray.bind(this, Uint8Array);
  }


  init() {
    return this.rangeBuffer.init()
      .then(this._findCentralDirectoryStartPos)
      .then(this._readCentralDirectory)
      .then(this._readFileList)
      .catch(
        error => {
          Promise.reject(new Error(`${TAG} Initialization of archive failed for "${this.rangeBuffer.data}"`), error);
        }
      )
  }

  /**
   * Clean-up / Destroy
   */
  destroy() {
    // Remove all references to this archive for each file
    Object.values(this.files).forEach(file =>
      file.destroy()
    );

    this.files = null;
    this.centralDirectory = null;
    this.rangeBuffer = null;
    this.cryptoService = null;
    this.cryptoInfo = null;

    if (this.workerManager) {
      this.workerManager.destroy();
      this.workerManager = null;
    }
  }


  /**
   * Releases data from memory
   *
   * @param {number} start Start index, from which to release/delete data
   * @returns {boolean} True, if data was successfully released
   */
  releaseData = start =>
    this.rangeBuffer.releaseRange(start)


  // ---------------------------------------------------------------------------
  // "PRIVATE" METHODS
  // ---------------------------------------------------------------------------

  /**
   * Determines the central file directory's start position within the ZIP archive,
   * i.e. the byte offset of the first signature that signals a central directory
   * entry.
   *
   * @return {Promise} Promise resolved with the start position of the central
   *                   directory, or -1 if no valid position was found
   */
  _findCentralDirectoryStartPos = () => {
    // start search at the end of the archive:
    const start = this.rangeBuffer.byteLength - CENTRAL_DIR_FETCH_BYTES;
    return this.getData(start, CENTRAL_DIR_FETCH_BYTES)
      .then(getCentralDirStartPos);
  }


  /**
   * Reads all entries from the ZIP archive's central directory
   * into the centralDirectory property.
   *
   * @param {Number} start Start offset
   * @return {Promise} Promise resolved with a boolean indicating the success
   */
  _readCentralDirectory = start =>
    start < 0
      ? Promise.resolve(false)
      : this.getData(start, this.rangeBuffer.byteLength - start)
          .then(data => readCentralDir(data, this.centralDirectory))

  /**
   * Converts each file available in the ZIP's central directory into
   * an ArchiveEntry instance.
   */
  _readFileList = () => {
    this.centralDirectory.forEach(item => {
      this.files[item.fileName] = new ArchiveEntry(item, this);
    });
  }


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  /**
   * Returns a map of all files in the archive. The file's name is used as
   * property key. Each entry is an instance of ArchiveEntry.
   *
   * @return {Object} Map of all files in the archive
   */
  getFiles() {
    return this.files;
  }


  /**
   * Returns the ArchiveEntry instance (i.e. the file data) of the
   * file identified by @fileRef
   *
   * @param {String} fileRef Name of the file (full path or relative to @root)
   * @param {String} [root] The root path used if @fileName is a relative reference
   * @return {ArchiveEntry} The file's ArchiveEntry instance
   */
  getFile(fileRef, root) {
    if (!fileRef || !this.files) {
      logger.warn(`getFile "${fileRef}": missing file name or "files" object not initialised`);
      return null;
    }
    if (fileRef in this.files) {
      return this.files[fileRef];
    }
    const path = this.getResolvedFilePath(fileRef, root);
    logger.info(`getFile: file reference "${fileRef}" resolved to "${path}"`);
    return this.files[path];
  }


  /**
   * Returns the file path (a valid key in this `files` map) referenced by @src
   * relative to @root.
   *
   * @param {String} src Relative path for a source referencing an entry in the `files` object
   * @param {String} [root] The root path
   * @return {String} A valid key for an object in this `files` referenced by @src
   */
  getResolvedFilePath(src = "", root) {
    if (!this.files) {
      logger.warn(`getResolvedFilePath: internal map of files is not defined`);
      return "";
    }
    const match = src.match(/^([^#?]+)[#?]/);
    if (match) {
      src = match[1];
    }
    const url = resolveRelativePath(src, root);
    return (url && url in this.files)
      ? url
      : getMatchingFilePath(url.split("/").pop(), this.centralDirectory);
  }


  /**
   * Returns the archive's worker manager that manages the unzip process of
   * files using web workers.
   *
   * @return {ArchiveWorkerManager} This archive's worker manager
   */
  getWorkerManager() {
    return this.workerManager;
  }


  // CRYPTOGRAPHY / DRM

  /**
   * Returns the archive's crypto service instance
   *
   * @return {Crypto} This archive's crypto service instance
   */
  getCryptoService() {
    return this.cryptoService;
  }


  setCryptoInfo(cryptoInfo, spine) {
    this.cryptoInfo = cryptoInfo;

    // We handle various types of encryption in here for different business
    // units. In a future release, this could be split up into various
    // services/methods
    if (cryptoInfo?.encryptionType === 'KOBO_DRM') {
      // KOBO
      const { deviceId, drmKeys, encryptionType, userId } = cryptoInfo;
      for (let key in this.files) {
        const rawKey = drmKeys[key] || getValueByIgnoredCaseKey(key, drmKeys);
        this.files[key].setCryptoInfo({
          encryptionType, userId, deviceId, rawKey
        });
      }
    } else if (cryptoInfo?.devKey && spine instanceof Array) {
      // TOLINO
      // Pass the @encryptionInfo to each of the ZIP archive's entries that
      // has an entry in the entitlement's spine
      spine.forEach(item => {
        const key = item.href;
        if (key in this.files) {
          this.files[key].setCryptoInfo(cryptoInfo);
        }
      });
    }
  }

}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default Archive;
