/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// COMMON STATE ACTION TYPES
// -----------------------------------------------------------------------------

export const APP_RESET = 'app/reset';

// -----------------------------------------------------------------------------
// WEB APP STATE ACTION TYPES
// -----------------------------------------------------------------------------

export const APP_LOCALE_CHANGED = 'app/localeChanged';
export const APP_THEME_CHANGED = 'app/themeChanged';
export const APP_URLPARAMS_ADDED = 'app/URLParamsAdded';


// -----------------------------------------------------------------------------
// MEDIA QUERY ACTION TYPES
// -----------------------------------------------------------------------------

export const MQ_BREAKPOINT_CHANGED = 'mq/breakpointChanged';
export const MQ_ORIENTATION_CHANGED = 'mq/orientationChanged';


// -----------------------------------------------------------------------------
// RX ACTION TYPES
// -----------------------------------------------------------------------------

export const RX_DELETE_FONT_FAMILY = 'rx/deleteFontFamily';
export const RX_EXTERNAL_LINKS_GRANTED = 'rx/externalLinksGranted';
export const RX_FULLTEXTSEARCH_HISTORY_ADDED = 'rx/fullTextSearchHistoryAdded';
export const RX_FULLTEXTSEARCH_HISTORY_CLEARED = 'rx/fullTextSearchHistoryCleared';
export const RX_FULLTEXTSEARCH_RESULT_ADDED = 'rx/fullTextSearchResultAdded';
export const RX_FULLTEXTSEARCH_RESULT_CLEARED = 'rx/fullTextSearchResultCleared';
export const RX_FULLTEXTSEARCH_RESULT_SET = 'rx/fullTextSearchResultSet';
export const RX_PUBLISHERS_DEFAULT = 'rx/publishersDefault';
export const RX_SETTINGS_UPDATED = 'rx/settingsUpdated';
export const RX_FONTSIZE_CHANGE = 'rx/fontSizeUpdated';


// -----------------------------------------------------------------------------
// SHOP APP ACTION TYPES
// -----------------------------------------------------------------------------

export const SHOP_LOCALE_CHANGED = 'app/localeChanged';
export const SHOP_THEME_CHANGED = 'app/themeChanged';
export const SHOP_URLPARAMS_ADDED = 'app/URLParamsAdded';
export const SHOP_PUBLICATIONS_CHANGED = 'app/publicationsChanged';
