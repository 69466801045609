/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2022-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// External/Third-party dependencies
import React from 'react';
import forEach from 'lodash/forEach';
import join from 'lodash/join';
import size from 'lodash/size';
import includes from 'lodash/includes';

// Internal dependencies
import { usePrevious } from './react-extensions';

import { locateService, getService } from '~services/locator/locator';

// -----------------------------------------------------------------------------
// SERVICE HOOK
// -----------------------------------------------------------------------------

/**
 * Locating a service is asynchonous. With this little helper it should be easier for components
 */
export default function useLocateService(serviceNames, publishError) {

  //This creates the ref to the services array
  let [services, refreshServices] = React.useState([]);

  //serialize array as identifier
  const identifier = join(serviceNames);

  //store previous identifier
  const previousIdentifier = usePrevious(identifier);

  //check if just a simple re-render
  if (size(services) > 0 && previousIdentifier === identifier) {
    return services;
  } else {
    //reset all services without creating a new array ref
    services.length = 0;
  }

  //try fetching services synchronously
  forEach(serviceNames, (serviceName, index) => {
    const service = getService(serviceName);
    if (service) {
      //fill services without calling setServices and thus prevent redrawing anything
      //we need the index to fit the same order as the incoming call
      services[index] = service;
    }
  });

  //check if we found all services
  if (includes(services, undefined) || size(services) !== size(serviceNames)) {
    //locate all services asychronously and call setServices to redraw
    locateService(serviceNames)
      .then(locatedService => refreshServices(locatedService))
      .catch(error => {
        //check if we have a error callback
        if (publishError) {
          publishError(error);
        }
      });
  }

  return services;
}
