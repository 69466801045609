/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import Archive from './unzip/Archive';


// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["decompress", ["crypto", "drm?", "featureFlag", "fetch", "localConfig", "readContent?", "session?"], createDecompressService];

// -----------------------------------------------------------------------------
// DECOMPRESS SERVICE
// -----------------------------------------------------------------------------

/**
 * Factory function to initialize the unZIP service
 *
 * @return {object} API that this service provides
 */
export function createDecompressService(crypto = null, drm, featureFlag, fetchService, localConfig, readContent, session) {

  let archives = {};

  const services = {
    crypto,
    drm,
    featureFlag,
    fetchService,
    localConfig,
    readContent,
    session
  };

  // ---------------------------------------------------------------------------
  // PRIVATE METHODS
  // ---------------------------------------------------------------------------

  function initArchive(buffer, maxWorkers = 1, url) {

    if (archives[url]) {
      return archives[url];
    }

    if (url) {
      archives[url] = new Archive(buffer, maxWorkers, services, url);
      return archives[url];
    }

    return new Archive(buffer, maxWorkers, services);

  }

  function releaseArchives() {
    archives = {};
  }

  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    initArchive,
    releaseArchives
  };

};
