// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

import COLORS_DEFAULT from "./colors-default";
import COLORS_CHARCOAL from "./colors-charcoal";
import COLORS_DARK  from "./colors-dark";
import COLORS_LIGHT from "./colors-light";
import COLORS_SEPIA from "./colors-sepia";

import VARIABLES_DEFAULT from "./variables-default";

import { createMixIns } from "./mixins";

// Assets
import './fonts.css';
import icons from '~static/assets/icons/kobo/svg-icons.svg';  // TODO: Make platform independent
import fallbackCoverAudiobook from '~static/assets/images/kobo-fallback-cover-audio.svg';
import fallbackCoverBook from '~static/assets/images/kobo-fallback-cover-book.svg';
import coverOverlayAudio from '~static/assets/images/cover-overlay-audio.png';
import coverOverlayBook from '~static/assets/images/cover-overlay-book.png';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

/**
 * Map of theme/appearance names to their color definition
 */
const COLORS = Object.freeze({
  "light":    COLORS_LIGHT,
  "sepia":    COLORS_SEPIA,
  "charcoal": COLORS_CHARCOAL,
  "dark":     COLORS_DARK
});


/**
 * Map of variables for different themes - tbd if required
 */
const VARIABLES = Object.freeze({
});


/**
 * Map of theme specific assets
 */
const assets = {
  fallbackCoverAudiobook,
  fallbackCoverBook,
  coverOverlayAudio,
  coverOverlayBook
};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  COLORS,
  COLORS_DEFAULT,
  VARIABLES,
  VARIABLES_DEFAULT,
  assets,
  createMixIns,
  icons
};
