/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * Utility functions around everything Promise related
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { timeout } from './function';


// -----------------------------------------------------------------------------
// PRIVATE METHODS
// -----------------------------------------------------------------------------

/**
 * Fallback for `requestPostAnimationFrame`
 * @see https://stackoverflow.com/questions/50895206/exact-time-of-display-requestanimationframe-usage-and-timeline/57549862#57549862
 *      https://nolanlawson.com/2018/09/25/accurately-measuring-layout-on-the-web/
 *      https://github.com/andrewiggins/afterframe
 */
const afterRequestAnimationFrame = (function() {
  // FIXME: Temporary solution for native not supporting MessageChannel
  if (!window.MessageChannel) return;

  // list of queued callbacks for `requestAnimationFrame`
  const callbackQueue = [];
  const channel = new MessageChannel();
  let isCalled = false;

  channel.port2.onmessage = () => {
    isCalled = false;
    const callbacks = callbackQueue.slice();
    callbackQueue.length = 0;
    callbacks.forEach((fn) => fn());
  };

  const postMessage = () => channel.port1.postMessage(undefined);

  return function(callback) {
    callbackQueue.push(callback);
    if (!isCalled) {
      requestAnimationFrame(postMessage);
      isCalled = true;
    }
  };
})();


// -----------------------------------------------------------------------------
// PUBLIC API
// -----------------------------------------------------------------------------

/**
 * Factory function that creates a guardian function to prevent creating
 * multiple promises while the initial promise is still not resolved/rejected,
 * i.e. it's state is pending.
 *
 * @example
 *  // Create a guardian function inside your module
 *  const guardedPromise = preventConcurrentExec();
 *
 *  // Use it like this:
 *  return guardedPromise("SOME_KEY", () => { });
 *
 *  // This will prevent creating multiple promises for the key SOME_KEY
 */
const preventConcurrentExec = () => {
  const pendingPromises = Object.create(null);

  return function (promiseKey, asyncFunction) {
    return pendingPromises[promiseKey] ||
      (
        pendingPromises[promiseKey] = asyncFunction().finally(
          () => delete pendingPromises[promiseKey]
        )
      );
  };
};


/**
 * Returns a promise with a resolved @executor function which is resolved after
 * a maximum of @wait msec.
 *
 * @param {Function} executor Function with two arguments `resolve` and `reject`
 * @param {Number} wait Max. time in msec until the returned promise is resolved
 * @param {Function} [fnTimeout=null] Function executed on timeout
 * @return {Promise}
 */
function promiseWithTimeout(executor, wait, fnTimeout = null) {
  return Promise.race([ new Promise(executor), timeout(fnTimeout, wait) ]);
}


/**
 * Returns a promise resolved immediately after the DOM's rendering update _or_
 * after @maxTimeout is reached if @maxTimeout is defined.
 *
 * @see https://github.com/WICG/request-post-animation-frame/blob/main/explainer.md
 *
 * @param {Number} [maxTimeout] Max time to wait in ms
 * @return {Promise} Promise resolved after next animation frame has finished
 */
function postAnimationPromise(maxTimeout) {
  const fnResolve  = window.requestPostAnimationFrame || afterRequestAnimationFrame;
  const promisePAF = new Promise(fnResolve);

  return maxTimeout
    ? Promise.race([ promisePAF, timeout(null, maxTimeout) ])
    : promisePAF;
}


/**
 * helper to resolve promises sequentially via array.reduce
 * input needs to be array with functions that return promises
 * eg. [() => new Promise(bla), etc ... ]
 */
function sequential(promises) {

  if (!Array.isArray(promises)) {
    throw new Error('First argument need to be an array of Promises');
  }

  let count = 0;
  let results = [];

  const iterateeFunc = (previousPromise, currentPromise) => {
    return previousPromise
      .then(function (result) {
        if (count++ !== 0) results.push(result);
        return currentPromise(result, results, count);
      })
  }

  return promises
    // this call allows the last promises's resolved result to be obtained cleanly
    .concat(() => Promise.resolve())
    // reduce() concatenates the promises. E.g. p1.then(p2).then(p3)
    .reduce(iterateeFunc, Promise.resolve(false))
    .then(() => results)
}

// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  postAnimationPromise,
  preventConcurrentExec,
  promiseWithTimeout,
  sequential
};
