/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import Validator from 'jsonschema';

// Internal dependencies
import logger from '@rakuten/services-common';

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[schema/validate]';


// -----------------------------------------------------------------------------
// VALIDATION
// -----------------------------------------------------------------------------

export function validateSchema(data, schema) {
    // https://github.com/ajv-validator/ajv/issues/1945
    if ('platform'.isNative?.()){
      logger.warn(`${TAG} Validating data not supported on react native`);
      return Promise.resolve();
    }

    const validator = new Validator.Validator();
    const results = validator.validate(data, schema);

    if (results.valid !== true) {
      logger.warn(`${TAG} Validating data against schema "${schema.id}" failed -> See validation information below.`);
      logger.groupCollapsed(`${TAG} Schema validation information`)
      logger.info('Errors:', results.errors);
      logger.info('Schema:', schema);
      logger.info('Data:', data);
      logger.groupEnd();
    }

    return results.valid;
}
