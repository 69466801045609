/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * Common utility functions not specifically targeted at one domain.
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

/**
 * Returns @value clamped to the range @minValue .. @maxValue
 *
 * @param {Number} value Numeric value
 * @param {Number} minValue Min allowed value
 * @param {Number} maxValue Max allowed value
 * @return {Number} @value clamped to the range @minValue .. @maxValue
 */
function clamp(value, minValue, maxValue) {
  return Math.max(minValue, Math.min(maxValue, value));
}


/**
 * Replaces meta characters in regexp strings
 *
 * @param {string} str A string
 * @return The cleaned-up string
 */
function escapeRegExpMetaChars(str) {
  return str.replace(/[-*+?.()[\]{}\\^$|#]/g, "\\$&");
}


/**
 * Standard state reducer that can be used, amongst other things, as
 * function to pass to useReducer() for React component state management.
 *
 * @param {object} state Current (state) values
 * @param {object} values New/Additional (state) values
 * @return {object} The merged values
 */
function stateReducer(state, values) {
  if (values && typeof values === "object") {
    for (let key in values) {
      if (state[key] !== values[key]) {
        return { ...state, ...values };
      }
    }
  }

  return state;
}



// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  clamp,
  escapeRegExpMetaChars,
  stateReducer
};
