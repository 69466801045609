/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { LOCATOR_ERROR_CODES, DEFAULT_ERROR_KEY } from "./service-locator.constants";

// -----------------------------------------------------------------------------
// ERROR EXPORTS
// -----------------------------------------------------------------------------

/**
 * Custom error for handling service locator errors
 */
export function LocatorError(key, detail = "unknown") {
  const errorData = LOCATOR_ERROR_CODES[key] || LOCATOR_ERROR_CODES[DEFAULT_ERROR_KEY];
  const instance = new Error(`${errorData.message} for ${detail}`);
  instance.name = errorData.name;
  instance.statusCode = key;
  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, LocatorError);
  }
  return instance;
}
