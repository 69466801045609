/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 *
 * Implementation of Secure Hash Algorithm (SHA256)
 * Original code by Angel Marin, Paul Johnston
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { encodeUTF8 } from '@rakuten/common-util/string';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

const CHAR_SIZE = 8;


// -----------------------------------------------------------------------------
// PRIVATE MODULE METHODS
// -----------------------------------------------------------------------------

const rotate = (x, n)    => (x >>> n) | (x << (32 - n));
const ch     = (x, y, z) => ((x & y) ^ ((~x) & z));
const maj    = (x, y, z) => ((x & y) ^ (x & z) ^ (y & z));
const sigma0 = (x)       => (rotate(x,  2) ^ rotate(x, 13) ^ rotate(x, 22));
const sigma1 = (x)       => (rotate(x,  6) ^ rotate(x, 11) ^ rotate(x, 25));
const gamma0 = (x)       => (rotate(x,  7) ^ rotate(x, 18) ^ (x >>>  3));
const gamma1 = (x)       => (rotate(x, 17) ^ rotate(x, 19) ^ (x >>> 10));


function safeAdd(x, y) {
  const lowWord  = (x & 0xFFFF) + (y & 0xFFFF);
  const highWord = (x >> 16) + (y >> 16) + (lowWord >> 16);
  return (highWord << 16) | (lowWord & 0xFFFF);
}


function getPackedBinArray(str) {
  const maxIndex = str.length * CHAR_SIZE;
  const mask = (1 << CHAR_SIZE) - 1;
  const result = new Array(maxIndex >> 5);

  for (let i = 0; i < maxIndex; i += CHAR_SIZE) {
    result[i >> 5] |= (str.charCodeAt(i / CHAR_SIZE) & mask) << (24 - i % 32);
  }

  return result;
}


// -----------------------------------------------------------------------------
// PUBLIC API
// -----------------------------------------------------------------------------

/**
 * Returns an SHA-256 message digest of the given @str
 *
 * @param {string} str Message to create hash for
 * @return {array} SHA-256 message digest of @str
 */
function SHA256(str) {
  str = encodeUTF8(str);

  const m = getPackedBinArray(str);
  const size = str.length * CHAR_SIZE;

  const K = [
    0x428A2F98, 0x71374491, 0xB5C0FBCF, 0xE9B5DBA5, 0x3956C25B, 0x59F111F1, 0x923F82A4, 0xAB1C5ED5,
    0xD807AA98, 0x12835B01, 0x243185BE, 0x550C7DC3, 0x72BE5D74, 0x80DEB1FE, 0x9BDC06A7, 0xC19BF174,
    0xE49B69C1, 0xEFBE4786, 0x0FC19DC6, 0x240CA1CC, 0x2DE92C6F, 0x4A7484AA, 0x5CB0A9DC, 0x76F988DA,
    0x983E5152, 0xA831C66D, 0xB00327C8, 0xBF597FC7, 0xC6E00BF3, 0xD5A79147, 0x06CA6351, 0x14292967,
    0x27B70A85, 0x2E1B2138, 0x4D2C6DFC, 0x53380D13, 0x650A7354, 0x766A0ABB, 0x81C2C92E, 0x92722C85,
    0xA2BFE8A1, 0xA81A664B, 0xC24B8B70, 0xC76C51A3, 0xD192E819, 0xD6990624, 0xF40E3585, 0x106AA070,
    0x19A4C116, 0x1E376C08, 0x2748774C, 0x34B0BCB5, 0x391C0CB3, 0x4ED8AA4A, 0x5B9CCA4F, 0x682E6FF3,
    0x748F82EE, 0x78A5636F, 0x84C87814, 0x8CC70208, 0x90BEFFFA, 0xA4506CEB, 0xBEF9A3F7, 0xC67178F2
  ];

  const HASH = [
    0x6A09E667, 0xBB67AE85, 0x3C6EF372, 0xA54FF53A, 0x510E527F, 0x9B05688C, 0x1F83D9AB, 0x5BE0CD19
  ];

  const W = new Array(64);

  m[size >> 5] |= 0x80 << (24 - size % 32);
  m[((size + 64 >> 9) << 4) + 15] = size;

  for (let i = 0, length = m.length; i < length; i += 16) {
    let a = HASH[0];
    let b = HASH[1];
    let c = HASH[2];
    let d = HASH[3];
    let e = HASH[4];
    let f = HASH[5];
    let g = HASH[6];
    let h = HASH[7];

    for (var j = 0; j < 64; j++) {
      W[j] = j < 16
        ? m[j + i]
        : safeAdd(safeAdd(safeAdd(gamma1(W[j - 2]), W[j - 7]), gamma0(W[j - 15])), W[j - 16]);

      const T1 = safeAdd(safeAdd(safeAdd(safeAdd(h, sigma1(e)), ch(e, f, g)), K[j]), W[j]);
      const T2 = safeAdd(sigma0(a), maj(a, b, c));

      h = g;
      g = f;
      f = e;
      e = safeAdd(d, T1);
      d = c;
      c = b;
      b = a;
      a = safeAdd(T1, T2);
    }

    HASH[0] = safeAdd(a, HASH[0]);
    HASH[1] = safeAdd(b, HASH[1]);
    HASH[2] = safeAdd(c, HASH[2]);
    HASH[3] = safeAdd(d, HASH[3]);
    HASH[4] = safeAdd(e, HASH[4]);
    HASH[5] = safeAdd(f, HASH[5]);
    HASH[6] = safeAdd(g, HASH[6]);
    HASH[7] = safeAdd(h, HASH[7]);
  }

  return HASH;
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default SHA256;
