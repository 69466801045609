/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { TrackingError } from "./tracking.error";
import { TAG } from "./tracking.constants";


// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = [
  "tracking",
  [
    "logger",
    "localConfig",
    "session",
    "fetch",
    "featureFlag"
  ], createTrackingService ];



// -----------------------------------------------------------------------------
// Kobo Session SERVICE
// -----------------------------------------------------------------------------

let progress = 0;

function formatDate() {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

/**
 * Factory function which creates a service with given
 * dependencies
 *
 * Inizialize tracking service
 */
export function createTrackingService(logger, localConfig, sessionService, fetchService, featureFlagService) {
  // App configuration
  const appConfig = localConfig.getLocalConfig();
  const path = window.location.pathname;
  const productId = path.substring(path.lastIndexOf('/') + 1);
  const analyticsTrackingUrl = `${appConfig.READINGSERVICES.BASE_URL}/AnalyticsTracking`;
  let readContent;
  let startFile;
  let visibilityChangeHandler;

  function isFeatureDisabled() {
    const disabledFeatures = featureFlagService.getDisabledFeatures();
    return disabledFeatures.indexOf(featureFlagService.FEATURES.ANALYTICS_TRACKING) > -1;
  }


  function announceDisabledFeature(method) {
    logger.info(`${TAG} Method "${method}()" not executed, because feature "${featureFlagService.FEATURES.ANALYTICS_TRACKING}" is disabled`);
  }


  function setUp(content, firstPage) {
    if (isFeatureDisabled()) {
      announceDisabledFeature('setUp');
      return Promise.resolve();
    }

    if (content) {
      readContent = content;
      startFile= firstPage;
    } else {
      if (!productId) {
        return Promise.reject(new TrackingError("NO_PRODUCT_ID"));
      }

      return sessionService.fetchSession()
        .then(sessionId => {

          if (!sessionId) {
            return Promise.reject(new TrackingError("NO_SESSION"));
          }
          return fetchService.fetch({
            url: `${appConfig.READINGSERVICES.BASE_URL}/ReadContent/${productId}`,
            options: {
              credentials: 'include'
            },
            timout: 10000
          }).then(response => {
            if (response?.result === "Success") {
              readContent = response;
            }

            if (response?.result === 'Error') {
              return Promise.reject(new TrackingError("FAULTY_PAYLOAD", { detail: { response } }));
            }

            return Promise.reject(new TrackingError("FAILED_REQUEST", { detail: { response } }));
          });
        }).catch(error => {
          if (error instanceof TrackingError) {
            return Promise.reject(error);
          }
          return Promise.reject(new TrackingError("FAILED_REQUEST"), { detail: { parent: error } });
        });
    }
    progress = Math.round(readContent?.bookmarkInfo?.progressPercent);
  }


  function handleLeaveContent(productId, startTime) {
    if(document.visibilityState !== "hidden"){
      return
    }

    if (isFeatureDisabled()) {
      announceDisabledFeature('handleLeaveContent');
      return;
    }

    const bookmark = typeof readContent?.bookmarkInfo?.bookmark === "object" ? readContent?.bookmarkInfo?.bookmark : ((typeof readContent?.bookmarkInfo?.bookmark === "string" && readContent?.bookmarkInfo?.bookmark !== "") ? JSON.parse(readContent?.bookmarkInfo?.bookmark) : null);
    const endTime = performance.now();
    const timeElapsed = Math.round((endTime - startTime) / 1000);
    const pagesTurned = Math.round(timeElapsed / 60);
    const data = JSON.stringify({
      "eventType": "LeaveContent",
      "timeStamp": formatDate(),
      "attributes": {
        "startFile": bookmark ? bookmark.ContentSource : startFile,
        "startSpan": bookmark ? bookmark.Value : 'kobo.1.1',
        "volumeid": productId
      },
      "metrics": {
        "progress": progress,
        "secondsRead": timeElapsed,
        "pagesTurned": pagesTurned
      }
    });


    // Use sendBeacon for sending data instead of fetch,
    // this ensures that the data is sent reliably
    // even when the page is about to be unloaded
    const blob = new Blob([data], { type: 'application/json' });
    navigator.sendBeacon(analyticsTrackingUrl, blob);
    // The App injects a `injectCloseContent` everytime the visibility changes to `visible`.
    // The event listener has to be removed every time after it's being called.
    window.removeEventListener('visibilitychange', visibilityChangeHandler)
  }

  function injectCloseContent(productId) {
    if (isFeatureDisabled()) {
      announceDisabledFeature('injectCloseContent');
      return;
    }

    // `visibilitychange` is a more reliable event than `beforeunload`.
    // This works not only on browsers but also on mobile web.
    visibilityChangeHandler = createVisibilityChangeHandler(productId)
    window.addEventListener( 'visibilitychange', visibilityChangeHandler);

    // assign handleLeaveContent to global scope
    // so it will be accessible from outside
    if (typeof window.handleLeaveContent !== 'function') {
      window.handleLeaveContent = handleLeaveContent;
    }
  };


  function handleOpenContent() {
    if (isFeatureDisabled()) {
      announceDisabledFeature('handleOpenContent');
      return Promise.resolve();
    }

    if (!productId) {
      return Promise.reject(new TrackingError("NO_PRODUCT_ID"));
    }
    if (readContent) {
      injectCloseContent(productId);
      const bookmark = typeof readContent?.bookmarkInfo?.bookmark === "object" ? readContent?.bookmarkInfo?.bookmark : ((typeof readContent?.bookmarkInfo?.bookmark === "string" && readContent?.bookmarkInfo?.bookmark !== "") ? JSON.parse(readContent?.bookmarkInfo?.bookmark) : null);

      return fetchService.fetch({
        data: JSON.stringify({
          "eventType": "OpenContent",
          "timeStamp": formatDate(),
          "attributes": {
            "startFile": bookmark ? bookmark.ContentSource : startFile,
            "startSpan": bookmark ? bookmark.Value : 'kobo.1.1',
            "volumeid": productId
          },
          "metrics": {
            "progress": Math.round(readContent?.bookmarkInfo?.progressPercent)
          }
        }),
        options: {
          credentials: 'include',
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-kobo-platformid': '00000000-0000-0000-0000-000000007002'
          },
          responseType: 'json',
          keepalive: true
        },
        url: analyticsTrackingUrl,
        timout: 10000
      }).catch(error => {
        if (error instanceof TrackingError) {
          return Promise.reject(error);
        }
        return Promise.reject(new TrackingError("FAILED_REQUEST"), { detail: { parent: error } });
      });
    }
  }


  function updateProgress(value) {
    if (isFeatureDisabled()) {
      announceDisabledFeature('updateProgress');
      return;
    }
    progress = Math.round(value);
  }

  /**
   * Creates a visibility change handler for a specific product.
   * Used for removing the `visibilitychange` event handler.
   *
   * @param {string} productId
   * @returns { Funcition }
   */
  function createVisibilityChangeHandler(productId){
    return function(){
      const startTime = performance.now();
      handleLeaveContent(productId, startTime)
    }
  }


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    setUp,
    handleOpenContent,
    updateProgress
  };
}
