
export default function flattenStyle(styles) {
  if (!styles) {
    return undefined;
  }
  if (!Array.isArray(styles)) {
    return styles;
  }
  
  const flattenedStyle = {};
  
  styles.forEach((style) =>
    Object.assign(flattenedStyle, flattenStyle(style))
  );
  
  return flattenedStyle;
}
