/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * Hooks that - in some way - extend the functionaly of React, for example,
 * adds solutions for functional components that are available for
 * class based components.
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import {
  useEffect,
  useRef
} from 'react'


// -----------------------------------------------------------------------------
// HOOKS
// -----------------------------------------------------------------------------

/**
 * This hook mimics React's class component method `didComponentUpdate` for
 * functional components. It is not executed on the first render like
 * `useEffect`, but only if any of the {@param dependencies} is updated after
 * the initial render.
 *
 * see https://stackoverflow.com/a/59468973
 *
 * @example
 * useComponentDidUpdate(() => { ... }, [ myPropA ]);
 *
 * @param {function} effect A function to execute if any of the
 *                          {@param dependencies} is updated
 * @param {Array} dependencies List of values to check for updates
 */
const useComponentDidUpdate = (effect, dependencies) => {
  const hasMounted = useRef(false);

  useEffect(
    () => {
      if (!hasMounted.current) {
        hasMounted.current = true;
        return;
      }
      effect();
    },
    dependencies
  );
};


/**
 * This hook allows to store the previous value of a variable for later
 * retrieval. These can be, for example, state variables or props.
 *
 * see https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * @example
 * const [ myVar, setMyVar ] = useState(0);
 * const previousMyVar = usePrevious(myVar);
 *
 * @param {*} value The value to store
 * @returns {*} The previous value
 */
const usePrevious = value =>  {
  const ref = useRef();

  useEffect(
    () => {
      ref.current = value;
    },
    [ value ]
  );

  return ref.current;
};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  useComponentDidUpdate,
  usePrevious
};
