/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2022-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External dependencies
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import map from 'lodash/map';

// Internal dependencies
import {
  preventConcurrentExec,
  sequential
} from '@rakuten/common-util/promise';
import { TAG } from './service-locator.constants';
import { LocatorError } from './service-locator.error';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

/**
 * List of all currently registered services that can be "located"; includes each service's
 * dependencies and a factory function that can be called to initialize the
 * service.
 */
const SERVICES = {};

/**
 * List of all initialized services
 */
const initialzedServices = {};

/**
 * Prevent multiple calls to functions that create the same promise
 */
const guardedPromise = preventConcurrentExec();

// -----------------------------------------------------------------------------
// SERVICE LOCATOR
// -----------------------------------------------------------------------------

/**
 * Locates a service asynchronously
 *
 * @example
 *  import { locateService } from '~services/service-locator';
 *
 *  locateService('localConfig').then(localConfig=>{});
 *  or
 *  const localConfig = await locateService('localConfig');
 *  or
 *  locateService(['localConfig', 'fetch', 'oAuth']).then(([localConfig, fetch, oAuth]) => {})
 *
 */
function locate(serviceName) {
  //check if multiple services are to be located
  if (isArray(serviceName)) {
    return sequential(map(serviceName, name => () => locate(name)));
  }

  return guardedPromise(`${TAG}:locate:${serviceName}`, () => {

    const isOptional = serviceName.endsWith("?");

    if (isOptional) {
      //remove question mark
      serviceName = serviceName.split("?")[0];
    }

    //check if service is already loaded
    if (initialzedServices[serviceName]) {
      return Promise.resolve(initialzedServices[serviceName]);
    }

    console.info(`${TAG} First time requesting service "${serviceName}" -> Initalizing`);
    return initializeService(serviceName)
      .catch(err => isOptional ? Promise.resolve(null) : console.error(err));

  });
}

function initializeService(serviceName) {

  //after loading the module with import the module registers itself by calling
  //registerService and thus the service configuration and factory function
  //is accessible via SERVICES[serviceName]
  const serviceConfig = SERVICES[serviceName];

  if (!serviceConfig) {
    return Promise.reject(new LocatorError("MISSING_CONFIG", serviceName));
  }

  //locate all dependencies for given service
  return sequential(serviceConfig.dependencies.map(dependency => () => locate(dependency)))
    .then(dependencies => {
      //create the service by spreading the dependencies into the factory
      return Promise.resolve(serviceConfig.factory(...dependencies))
        .then(factoryResult => {
          initialzedServices[serviceName] = factoryResult;
          console.info(`${TAG} Initalizing service "${serviceName}" -> Done`);
          return initialzedServices[serviceName];
        })
    });
}

/**
 * Registers a service with the locator
 *
 * The locator is a singleton to prevent multiple locators with multiple
 * registered services.
 *
 * @param {String} name The name of the service
 * @param {Function} factory The function to create the service
 * @param {Array} dependencies Array of depedencies which need to be created for this service
 */
export function registerService(name, dependencies, factory) {
  if (SERVICES[name]) {
    // Service has already been registered
    return;
  }

  console.info(`${TAG} service "${name}" successfully Registered`);
  SERVICES[name] = {
    dependencies,
    factory
  };
}

/**
 * Asynchronous method to locate a service
 *
 * @param {String} serviceName The name of the service to get
 * @return {Promise<object>} The located service
 */
export function locateService(serviceName) {
  return locate(serviceName);
}

export function registerServices(services) {
  forEach(services, service => {
    if (service.config) {
      registerService(...service.config);
    }
  });
}

/**
 * Locates a service synchronously.
 *
 * NOTE: The service has to be initialized before it can be accessed
 *
 * @param {string} serviceName The name of the service to get
 * @return {object|null} The service, if initialized
 */
export function getService(serviceName) {

  //Allow only single services to be located synchronously
  if (!isString(serviceName)) {
    throw new LocatorError("NON_VALID_IDENTIFIER", String(serviceName))
  }

  if (!SERVICES[serviceName]) {
    throw new LocatorError("UNKOWN_SERVICE", serviceName)
  }

  if (initialzedServices[serviceName]) {
    return initialzedServices[serviceName];
  }

  return null;
}
