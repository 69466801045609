/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2022-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External dependencies
import QueryString from 'query-string';


// -----------------------------------------------------------------------------
// CONFIGURTATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[service/queryString]';

// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["queryString", ["localConfig", "logger", "legacyHelper?"], createQueryStringService];

// -----------------------------------------------------------------------------
// FACTORY QUERY STRING
// -----------------------------------------------------------------------------

/**
 * Factory function which creates a service with given
 * dependencies
 */
export function createQueryStringService(localConfig, logger, legacyHelper) {

  const config = localConfig.getLocalConfig();
  let rawURLParams;

  /**
   * parse the current query string via window.location
   */
  function parseQueryString() {
    // Parse the URL query string
    rawURLParams = QueryString.parse(location.search, {
      parseBooleans: true,
      parseNumbers: true
    });

    if (Object.keys(rawURLParams).length > 0) {
      logger.info(`${TAG} Provided URL parameters: ${Object.keys(rawURLParams)}`);

      // Convert legacy parameter names to new names
      if(legacyHelper){
        rawURLParams = legacyHelper.transformLegacyURLParams(rawURLParams, config.URL.PARAMS.LEGACY)
      }

      // Whitelist URL params
      Object.keys(rawURLParams).forEach(paramName => {
        // Remove any non white-listed URL parameters
        if (config.URL.PARAMS.VALID.indexOf(paramName) < 0) {
          logger.warn(`${TAG} URL parameter ${paramName} not allowed -> Removing`);
          delete rawURLParams[paramName];
        }

        // Handle multiple purchase URLs
        if (['purchase_urls'].indexOf(rawURLParams) > -1) {
          rawURLParams[paramName] = JSON.parse(rawURLParams[paramName]);
        }
      });
      logger.info(`${TAG} Whitelisted URL parameters: ${Object.keys(rawURLParams)}`);

      // Re-create the URL with only whitelisted URL paramers
      const newURLParams = [ ];
      Object.keys(rawURLParams).sort().forEach(paramName => {
        newURLParams.push(`${paramName}=${window.encodeURIComponent(rawURLParams[paramName])}`);
      });
      let url = `${window.location.origin}${window.location.pathname}?${newURLParams.join('&')}${window.location.hash}`;
      window.history.replaceState(rawURLParams, config.APPLICATION.NAME, url);
    }
  }


  /**
   * Returns a map of all (whitelisted) key/value pairs that were provided
   * in the URL.
   *
   * @param {bool} [parsed=false] Whether to JSON parse the value of the key
   * @return {object} A map with all (whitelisted) key/value pairs provided in the URL
   */
  function getAllQueryParameters(parsed = false) {
    const params = { };
    Object.keys(rawURLParams).forEach(key => {
      params[key] = getQueryParameter(key, parsed);
    });

    return params;
  }


  /**
   * Returns a value that is identified by the provided @key from
   * the rawURLParams list.
   *
   * @param {string} key The key that identifies the parameter
   * @param {bool} [parsed=false] Whether to JSON parse the value of the key
   * @return {string|*} The value that is represented by the key
   *
   */
  function getQueryParameter(key, parsed = false) {
    return parsed === true && rawURLParams[key]
      ? JSON.parse(rawURLParams[key])
      : rawURLParams[key];
  }


  /**
   * Deletes a key/value pair from the list of raw URL parameters
   *
   * @param {string} key The key that identifies the parameter to be deleted
   */
  function removeQueryParameter(key) {
    delete rawURLParams[key];
  }


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    getAllQueryParameters,
    getQueryParameter,
    removeQueryParameter,
    parseQueryString
  };
}
