/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// Internal dependencies
import logger from '@rakuten/services-common';

//Empty service config for now
export const config = ["i18n", [], ()=>{}];

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[service/i18n]';

/**
 * Whether or not i18next has been initialized
 * @type {boolean}
 */
let isInitialized = false;

/**
 * List of supported locales
 * @type {array}
 */
let supportedLocales = [ ];


// -----------------------------------------------------------------------------
// PRIVATE MODULE METHODS
// -----------------------------------------------------------------------------

/**
 * Initializes the i18next/react-i18next ecosystem with the provided config.
 *
 * The list of supported locales can either be provided in the i18next
 * config (see supportedLngs in the i18next documentation) or by passing a list
 * for supportedLocalesIntern; supportedLngs has higher precedence.
 *
 * @example
 *  initI18n({ ... });
 *
 * @param {object} config The i18next/react-i18next configuration
 * @returns {Promise<Boolean>} The i18next promise to initialize i18next
 */
const initI18n = config => {
  if (isInitialized === true) {
    logger.warn(`${TAG} I18n has already been initialized -> skipping`);
    return Promise.resolve();
  }

  logger.info(`${TAG} Initializing i18n`);
  if (!config.supportedLngs) {
    if (!supportedLocales || supportedLocales.length === 0) {
      logger.error(`${TAG} No supported locales have been configured. You need to call setSupportedLocales first.`);
      return Promise.reject();
    } else {
      config.supportedLngs = supportedLocales;
    }
  }

  // Prevent multiple initializations of i18next
  i18next.on('initialized', () => {
    logger.info(`${TAG} I18n initialized`);
    isInitialized = true;
  });

  supportedLocales = config.supportedLngs;
  return i18next
    .use(Backend)
    .use(initReactI18next)
    .init(config);
};


/**
 * Checks if a locale is supported by validating that the provided locale
 * identifier is one of the supported locale identifiers
 *
 * @param {string} locale A locale identifier to validate
 * @returns {Boolean} Whether the locale is supported or no
 */
const isValidLocale = locale =>
  supportedLocales.indexOf(locale) > -1;


/**
 * Gets the closest locale in terms of supported language for the provided
 * locale.
 *
 * Using a small function like this make it unnecessary to define all supported
 * and fallback languages in the i18next config.
 *
 * @param {string} locale The locale ID to get the closest supported locale for
 * @returns {string}
 */
const getClosestLocale = locale => {
  const foundByFullCode = locale?.length >= 5 &&
    supportedLocales.find( l => l.startsWith( locale ) );

  const foundByShortCode = !foundByFullCode &&
    supportedLocales.find( l => l.startsWith( locale.slice( 0, 2 ) ) );

  return foundByFullCode || foundByShortCode || null;
};


/**
 * Changes the locale to the one identified by the provided locale identifier.
 *
 * @param {string} locale The locale identifier that identifies the locale to use
 */
const setLocale = locale => {
  if (!isValidLocale(locale)) {
    logger.error(`${TAG} Locale ${locale} is not one of the supported locales ${supportedLocales}`);
    return;
  }

  logger.info(`${TAG} Setting locale to ${locale}`);
  i18next.changeLanguage(locale);
};


/**
 * Sets the list of locale identifiers that are supported by the instance
 * of this service.
 *
 * @param {Array} locales List of locale identifiers that are supported
 */
const setSupportedLocales = locales => {
  supportedLocales = locales;
};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default {
  getClosestLocale,
  initI18n,
  isValidLocale,
  setLocale,
  setSupportedLocales
};

// Re-export everything from react-i18next. This way, apps, other services etc.
// only need to import this service, which makes it easier to maintain/update
// this service and its dependencies.
export * from 'react-i18next';
