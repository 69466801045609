/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

export const TAG = '[tolino] [service/read-content-kobo]';

export const DEMO_BOOK = 'demo_book';

export const READ_CONTENT_DEMO_DATA = {
  "title": "Im Sturm",
  "coverImageUrl": "https://kbimages1-a.akamaihd.net/bb5055c3-4486-4eda-80b9-d595cf531288/180/180/False/im-sturm.jpg",
  "isbn": "9783641089917",
  "contentDownloadInfo": {
    "downloadUrl": `${window.location.origin}/dombeyandson.epub`,
    "productFormat": "EPUB3",
    "drmType": "KDRM"
  },
  "drmKeys": null,
  "bookmarkInfo": {},
  "ownershipStatus": "Owned",
  "privacyPermissionsInfo": {
    "privacyPermissions": [],
    "questionKeys": []
  },
  "result": "Success"
};

export const READ_CONTENT_ERROR_CODES = Object.freeze({

  MISSING_ID: {
    message: "No id given to load publication",
    name: "MissingId"
  },
  NOT_AUTHENTICATED: {
    message: "User is not authenticated",
    name: "NotAuthenticated"
  },
  NOT_AVAILABLE: {
    message: "Book is not available",
    name: "NotAvailable"
  },
  NOT_OWNED: {
    message: "Book is not owned by user",
    name: "NotOwned"
  },
  FETCH_ERROR: {
    message: "Check failed. Fetch failed or server not responded with valid json."
  },
  ERROR: {
    message: "Unexpected error",
    name: "Error"
  }
});
