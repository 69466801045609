/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

/**
 * String.fromCharCode limits the length of the array parameter to 65536,
 * thus the resulting string has to be concatenated from a series of chunks
 *
 * @type {number}
 * @default
 */
const CHUNK_SIZE = 65536;

/**
 * Mapping utilised for Base64 conversion from byte to character
 *
 * @type {string}
 * @default
 */
const CHAR_MAP = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

/**
 * TextDecoder instance used by method {@link module:ByteArray.asUTF8String}
 *
 * @type {TextDecoder|null}
 * @default
 */
const utf8Decoder = typeof TextDecoder === 'function'
  ? new TextDecoder('utf-8')
  : null;

/**
 * TextDecoder instance used by method {@link module:ByteArray.asUTF16String}
 *
 * @type {TextDecoder|null}
 */
const utf16Decoder = typeof TextDecoder === 'function'
  ? new TextDecoder('utf-16')
  : null;

/**
 * Indicates if "String.fromCharCode" accepts ArrayBuffer as a valid
 * argument type
 *
 * @type {boolean}
 */
let FROMCHARCODE_ACCEPTS_ARRAYBUFFER;
try {
  String.fromCharCode.apply(null, new Uint8Array(new ArrayBuffer(1)));
  FROMCHARCODE_ACCEPTS_ARRAYBUFFER = true;
} catch (e) {
  FROMCHARCODE_ACCEPTS_ARRAYBUFFER = false;
}


// -----------------------------------------------------------------------------
// HELPER
// -----------------------------------------------------------------------------

/**
 * Checks for existence of a byte order mark at the beginning
 * of bytes. The byte order mark has the signature EF BB BF.
 *
 * @param {Array|ArrayView} bytes The byte stream to check for an optional byte order mark
 * @return Boolean True, if byte order mark is set
 */
function hasByteOrderMark(bytes) {
  return (bytes && bytes[0] === 0xef && bytes[1] === 0xbb && bytes[2] === 0xbf);
};


/**
 * Converts an Array or ArrayBuffer of byte values
 * to a string using method String.fromCharCode
 *
 * @param {Array|ArrayView} byteArray The byte array to convert
 * @param {number} [start=0] Optional start index for the array
 * @param {number} [length] Optional number of characters to be converted, if not specified the entire array is converted
 * @return {string} The converted string
 */
function arrayToString(byteArray, start = 0, length) {
  // String.fromCharCode limits the length of the array parameter to 65536,
  // thus the resulting string has to be concatenated from a series of chunks:
  let outStr = "";
  // byteArray's prototype contains method "subarray" if it's an ArrayView,
  // otherwise the Array-slice method is used:
  const getSubArray = byteArray && byteArray.subarray || Array.prototype.slice;
  let endOfChunk, chunk;

  if (length === undefined) {
    length = byteArray && byteArray.length || 0;
  }
  for (let i = start; i < length; i += CHUNK_SIZE) {
    endOfChunk = Math.min(i + CHUNK_SIZE, length);
    chunk = getSubArray.call(byteArray, i, endOfChunk);
    outStr += String.fromCharCode.apply(null, chunk);
  }
  return outStr;
};


// ---------------------------------------------------------------------------
// PUBLIC API
// ---------------------------------------------------------------------------

/**
 * Returns an indicator for a UTF-16 byte order mark.
 * In UTF-16, a BOM (U+FEFF) may be placed as the first character of a file or
 * character stream to indicate the endianness (byte order) of all the 16-bit
 * code units of the file or stream. The characters use little-endian order,
 * if the sequence of bytes is 0xFF followed by 0xFE. If the characters are
 * represented in big-endian byte order, the BOM appears as 0xFE followed by
 * 0xFF.
 *
 * @param {Array|ArrayView} bytes The byte stream to check for an optional byte order mark
 * @return {boolean} True, if a UTF-16 Byte Order Mark is set
 */
const hasUTF16ByteOrderMark = function hasUTF16ByteOrderMark(bytes) {
  return (bytes && (bytes[0] === 0xff && bytes[1] === 0xfe || bytes[0] === 0xfe && bytes[1] === 0xff));
};


/**
 * Converts an array of bytes into a number.
 * The byte-order is interpreted in little endian (Intel x86) mode,
 * so the first byte is the least significant value
 *
 * @param {Array|ArrayView} bytes The byte array to convert
 * @return {number} The converted number
 */
const asNumber = function asNumber(bytes) {
  let number = 0;
  for (let i = bytes.length - 1; i >= 0; i--) {
    let thisByte = bytes[i];
    number = (number << 8) + (thisByte < 0 ? thisByte + 256 : thisByte);
  }
  return number;
};


/**
 * Encodes an array of bytes as a Base64-string
 *
 * @param {Array|ArrayView} bytes The byte array to convert
 * @return {string} The converted Base64 string
 */
const asBase64 = function asBase64(bytes) {
  if (window.btoa) {
    // Calling window.btoa on a Unicode string may cause some browsers to
    // throw a Character Out Of Range exception. This pattern will avoid this
    // exception: window.btoa(unescape(encodeURIComponent(str)));
    // see https://developer.mozilla.org/en-US/docs/Web/API/Window.btoa
    return btoa(arrayToString(bytes));
  }

  let i = hasByteOrderMark(bytes) ? 3 : 0;
  const out = [];
  const byteLength = bytes ? bytes.length : 0;
  const byteRemainder = byteLength % 3;
  const length3 = byteLength - byteRemainder;
  let a, b, c, d, chunk;

  // main loop to process byte triplets
  for (; i < length3; i += 3) {
    // combine 3 bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];
    // extract 6-bit segments from the triplet
    a = (chunk & 0xFC0000) >> 18;
    b = (chunk & 0x3F000) >> 12;
    c = (chunk & 0xFC0) >> 6;
    d = chunk & 0x3F;
    // convert the binary segments to the their ASCII encoding
    out.push(CHAR_MAP[a], CHAR_MAP[b], CHAR_MAP[c], CHAR_MAP[d]);
  }

  // process remaining bytes and padding
  if (byteRemainder > 0) {
    if (byteRemainder === 1) {
      chunk = bytes[length3];
      a = (chunk & 0xFC) >> 2;
      b = (chunk & 0x03) << 4;
      c = 64;
    }
    else { // byteRemainder === 2
      chunk = (bytes[length3] << 8) | bytes[length3 + 1];
      a = (chunk & 0x3F00) >> 8;
      b = (chunk & 0x3F0) >> 4;
      c = (chunk & 0x0F) << 2;
    }
    out.push(CHAR_MAP[a], CHAR_MAP[b], CHAR_MAP[c], CHAR_MAP[64]);
  }
  return out.join("");
};


/**
 * Converts an array of bytes into a UTF-8 string.
 *
 * @function
 * @param {Array|ArrayView} bytes The byte array to convert
 * @return {string} The converted UTF-8 string
 */
const asUTF8String = utf8Decoder
  ? (bytes) => { return utf8Decoder.decode(bytes); }
  : (bytes) => {
    let byte1, codepoint;
    let i = hasByteOrderMark(bytes) ? 3 : 0;
    const length = bytes && bytes.length || 0;
    let outLen = 0;
    // outBuffer used as temporary store for UTF-8 conversion. For UTF-8
    // a Uint16Array is required if ArrayBuffer is a valid type for string
    // method fromCharCode:
    const outBuffer = FROMCHARCODE_ACCEPTS_ARRAYBUFFER ? new Uint16Array(new ArrayBuffer(2 * length))
      : new Array(length);

    for (; i < length; i++) {
      byte1 = bytes[i];
      if (byte1 < 0x80) {
        outBuffer[outLen++] = byte1;
      }
      else if (byte1 >= 0xC2 && byte1 < 0xE0) {
        outBuffer[outLen++] = ((byte1 & 0x1F) << 6) + (bytes[++i] & 0x3F);
      }
      else if (byte1 >= 0xE0 && byte1 < 0xF0) {
        outBuffer[outLen++] = ((byte1 & 0xFF) << 12) + ((bytes[++i] & 0x3F) << 6) + (bytes[++i] & 0x3F);
      }
      else if (byte1 >= 0xF0 && byte1 < 0xF5) {
        codepoint = (((byte1 & 0x07) << 18) + ((bytes[++i] & 0x3F) << 12) + ((bytes[++i] & 0x3F) << 6) + (bytes[++i] & 0x3F)) - 0x10000;
        outBuffer[outLen++] = (codepoint >> 10) + 0xD800;
        outBuffer[outLen++] = (codepoint & 0x3FF) + 0xDC00;
      }
    }
    return arrayToString(outBuffer, 0, outLen);
  };


/**
 * Converts an array of bytes into a UTF-16 string,
 * interpreting two consecutive bytes as a single character
 *
 * @function
 * @param {Array|ArrayView} bytes The byte array to convert
 * @return {string} The converted UTF-16 string
 */
const asUTF16String = utf16Decoder
  ? (bytes) => { return utf16Decoder.decode(bytes); }
  : (bytes) => {
    let i = 0;
    let offset0 = 1;
    let offset1 = 0;

    if (bytes[0] === 0xfe && bytes[1] === 0xff) {
      i = 2;
      offset0 = 0;
      offset1 = 1;
    }
    else if (bytes[0] === 0xff && bytes[1] === 0xfe) {
      i = 2;
    }

    let outLen = 0;
    const length = bytes.length;
    const outBuffer = FROMCHARCODE_ACCEPTS_ARRAYBUFFER ? new Uint16Array(new ArrayBuffer(length))
      : new Array(Math.ceil(length / 2));

    for (; i < length; i += 2) {
      outBuffer[outLen++] = (bytes[i + offset0] << 8) + bytes[i + offset1];
    }
    return arrayToString(outBuffer, 0, outLen);
  };


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default {
  asBase64,
  asNumber,
  asUTF8String,
  asUTF16String,
  hasUTF16ByteOrderMark
};
