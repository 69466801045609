/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External imports
import { toUint8Array } from 'js-base64'

// Internal dependencies
import { preventConcurrentExec } from "~common/util/promise";
import { TAG } from "./drm.constants";
import { DrmError } from "./drm.error";

// Side effect import
import "./bindgen";

// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["drm", ["localConfig", "fetch", "session"], createDrmService];

// -----------------------------------------------------------------------------
// Kobo DRM SERVICE
// -----------------------------------------------------------------------------

/**
 * Factory function which creates a service with given
 * dependencies
 */
export function createDrmService(localConfig, fetchService, sessionService) {

  // App configuration
  const appConfig = localConfig.getLocalConfig();

  // Prevent multiple calls to functions that create the same promise
  const guardedPromise = preventConcurrentExec();

  /* current wasm module */
  let wasmMap = {};

  function fetchWASM(productId) {

    return guardedPromise(`${TAG}:updateBookmark`, () => {

      if (!productId) {
        return Promise.reject(new DrmError("NO_PRODUCT_ID"));
      }

      return sessionService.fetchSession()
        .then(sessionId => {

          if (!sessionId) {
            return Promise.reject(new DrmError("NO_SESSION"));
          }

          //check if wasm object is available for given sessionId
          if (wasmMap[sessionId]) {
            return Promise.resolve(wasmMap[sessionId]);
          }

          //create new map to invalidate previous wasm objects
          wasmMap = {};

          //fetch wasm object from server
          return fetchService.fetch({
            url: `${appConfig.READINGSERVICES.BASE_URL}/ReadContent/${productId}/gjp`,
            options: {
              credentials: 'include'
            },
            timout: 10000
          }).then(response => {
            if (response?.result === "Success") {
              const buffer = toUint8Array(response.gjp);
              return window.wasm_bindgen(buffer)
                .then(() => {
                  //store wasm object for given session id
                  wasmMap[sessionId] = window.wasm_bindgen;
                  return window.wasm_bindgen;
                });
            }

            if (response.result === 'Error') {
              return Promise.reject(new DrmError("FAULTY_PAYLOAD", { detail: { response } }));
            }

            //NotAvailable
            return Promise.reject(new DrmError("FAILED_REQUEST", { detail: { response } }));
          });
        }).catch(error => {

          //pass through for BookmarkErrors
          if (error instanceof DrmError) {
            return Promise.reject(error);
          }
          return Promise.reject(new DrmError("NO_SESSION"), { detail: { parent: error } });
        });
    });
  }

  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    fetchWASM
  };
}
