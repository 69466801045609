/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */


// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import CustomError from '@rakuten/common-error/CustomError';


// -----------------------------------------------------------------------------
// FETCH SERVICE ERROR
// -----------------------------------------------------------------------------

class FetchServiceError extends CustomError {
  constructor(message, options) {
    options = Object.assign({ }, {
      name: 'FetchServiceError',
      tag: '[error/fetchServiceError]'
    }, options);

    super(message, options);
  }
};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default FetchServiceError;
