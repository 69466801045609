/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { preventConcurrentExec } from "~common/util/promise";
import { TAG } from "./read-content.constants";
import { ReadContentError } from "./read-content.error";

// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["readContent", ["localConfig", "session", "fetch"], createReadContentService];


// -----------------------------------------------------------------------------
// Kobo Session SERVICE
// -----------------------------------------------------------------------------

/**
 * Factory function which creates a service with given
 * dependencies
 */
export function createReadContentService(localConfig, sessionService, fetchService) {

  // App configuration
  const appConfig = localConfig.getLocalConfig();

  // Prevent multiple calls to functions that create the same promise
  const guardedPromise = preventConcurrentExec();

  let readContentMap = {};

  let currentProductId;

  /**
   * fetches data from readt-content service api
   */
  function fetchReadContent(productId) {

    if (!productId) {
      productId = currentProductId;
    }

    return guardedPromise(`${TAG}:fetchReadContent`, () => {

      if (!productId) {
        return Promise.reject(new ReadContentError("MISSING_ID"));
      }

      //check if already downloaded for given productId
      if (readContentMap[productId]) {
        return Promise.resolve(readContentMap[productId]);
      }

      //only one readContent should be cached
      readContentMap = {};

      return sessionService.fetchSession()
        .then(() => {
          return fetchService.fetch({
            url: `${appConfig.READINGSERVICES.BASE_URL}/ReadContent/${productId}`,
            options: {
              credentials: 'include',
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              responseType: 'json'
            },
            timout: 10000
          })
            .then(response => {
              if (response?.result === "Success") {

                //remember current successful productId
                currentProductId = productId;

                //remember current answer
                readContentMap[productId] = { ...response, productId }

                return readContentMap[productId];
              }
              throw new ReadContentError("FETCH_ERROR", { detail: { response } });
            })
            .catch((error) => {
              console.log(error)
              //pass through for ReadContentError errors
              if (error instanceof ReadContentError) {
                throw error;
              }

              throw new ReadContentError("FETCH_ERROR", { detail: { parent: error } });

            });
        });
    });
  }

  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    fetchReadContent,
    getCurrentProductId: () => currentProductId
  };
}
