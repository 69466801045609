/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// RANGE BUFFER CLASS
// -----------------------------------------------------------------------------

/**
 * Class `RangeBuffer` features transparent support of range requests for fetching
 * resources into a set of arraybuffers.
 * Falls back to a single range for the entire resource if byte ranges are not
 * enabled on the server.
 *
 * @ToDo: Dynamic extension of fetched ranges when requesting overlapping boundaries
 */
class RangeBuffer {

  // ---------------------------------------------------------------------------
  // INIT/DESTROY
  // ---------------------------------------------------------------------------

  constructor(data, url, fetchService) {
    // list of bytes ranges identified by boundary attributes `start` and `end`
    this.byteRanges = [ ];
    this.fetch = fetchService;
    this.url = url;
    // Total size of resource either requested via URL or provided as ArrayBuffer
    // in @data
    this.byteLength = null;
    this.data = data;
  }


  /**
   * Initialises the RangeBuffer instance with @data which can be either a URL
   * or the already fetched data as ArrayBuffer.
   *
   * @return {Promise} Promise resolved with the size of the resource specified by @data
   */
  init() {
    if (this.data instanceof ArrayBuffer && this.data.byteLength > 0) {
      return Promise.resolve(this.initWithArrayBuffer(this.data));
    }

    this.byteRanges.length = 0;
    return this.fetch.fetchSize(this.url)
      .then(bytes => {
        this.byteLength = bytes
        return bytes;
      })
      .then(() => this.fetch.fetchByteRange(this.url, 1, 1))  // does the range request throw an error?
      .then(() => this.byteLength)  // return the size of the specified resource
      .catch(error => {
        if (error.message === this.fetch.ERR_NOT_SUPPORTED) {
          return this.fetch.fetchByteRange(this.url)
            .then(response => this.initWithArrayBuffer(response.buffer));
        }
      });
  }


  /**
   * Initialises the RangeBuffer instance with ArrayBuffer @buffer.
   *
   * @param {ArrayBuffer} buffer The data buffer which is already completely fetched
   * @return {Number} The byteLength of the @buffer resource
   */
  initWithArrayBuffer(buffer) {
    this._addByteRange(0, buffer.byteLength - 1, buffer);
    return this.byteLength = buffer.byteLength;
  }



  // ---------------------------------------------------------------------------
  // "PRIVATE" METHODS
  // ---------------------------------------------------------------------------

  /**
   * Adds a new byte range object for range @start .. @end with data @buffer.
   * @TODO: support for intersecting ranges (-> extend or split existing ranges)
   *
   * @param {Number} start Start offset of the byte range
   * @param {Number} end End offset of the byte range
   * @param {ArrayBuffer} buffer
   * @return {Object} The byte range object created for @start, @end, and @buffer
   */
  _addByteRange(start, end, buffer) {
    const length = this.byteRanges.push({ start, end, buffer });
    return this.byteRanges[length - 1 ];
  }


  /**
   * Returns the byte range object that includes the range @start .. @end
   * @TODO: support for intersecting ranges (-> extend existing ranges)
   *
   * @param {Number} start Start offset of the byte range
   * @param {Number} end End offset of the byte range
   * @return {Object} Byte range object that includes the range @start .. @end, or null if not available
   */
  _findByteRangeItem(start, end) {
    return this.byteRanges.find(item =>
      item.start <= start && end <= item.end
    );
  }


  // ---------------------------------------------------------------------------
  // PUBLIC METHODS
  // ---------------------------------------------------------------------------

  /**
   * Fetches the bytes for range @start .. @end and adds the returned data to the
   * internal list of byte ranges.
   *
   * @param {Number} start Start offset of the requested range
   * @param {Number} end End offset of the requested range
   * @return {Promise} Promise resolved with the byte range object for @start
   *                   and @end, or null if the request failed
   */
  fetchRange(start, end) {
    return this.fetch.fetchByteRange(this.url, start, end).then(
      response => response.status === 206
        ? this._addByteRange(start, end, response.buffer)
        : (
          response.status === 200
            ? this._addByteRange(0, response.buffer.byteLength - 1, response.buffer)
            : null
        )
    );
  }


  /**
   * Releases the byte range exactly matching boundaries @start .. @end.
   * If @end is omitted, only @start is applied for comparison.
   *
   * @param {Number} start Start offset of the range to release
   * @param {Number} [end] End offset of the range to release
   * @return {Boolean} True if a range with boundaries @start .. @end was released
   */
  releaseRange(start, end) {
    const index = this.byteRanges.findIndex(item =>
      item.start === start && (end === undefined || end === item.end)
    );

    if (index === -1) {
      return false;
    }

    this.byteRanges.splice(index, 1);
    return true;
  }


  /**
   * @param {Function} TypedArray Typed array constructor function, e.g. Uint8Array
   * @param {Number} [byteOffset=0] Start of the memory range that will be exposed by @TypedArray
   * @param {Number} [length] Length in units according to @TypedArray
   * @param {Boolean} [copyBuffer] Indicator to return a typed array based
   *                               on a copy of the binary buffer
   * @return {Promise} Promise resolved with an @TypedArray instance
   */
  getTypedArray = (TypedArray, byteOffset = 0, length, copyBuffer) => {
    const endOffset = length !== undefined
      ? byteOffset + length * TypedArray.BYTES_PER_ELEMENT - 1
      : this.byteLength - byteOffset - 1;
    const rangeItem = this._findByteRangeItem(byteOffset, endOffset);
    const createTypedArray = (item) => copyBuffer
      // `buffer.slice` creates a copy of the buffer, i.e. the buffer is _not_
      // shared with any other TypedArray instance.
      ? new TypedArray(item.buffer.slice(byteOffset - item.start, byteOffset - item.start + length))
      : new TypedArray(item.buffer, byteOffset - item.start, length);

    if (rangeItem) {
      return Promise.resolve(createTypedArray(rangeItem));
    }

    return this.fetchRange(byteOffset, endOffset).then(
      rangeItem => rangeItem
        ? createTypedArray(rangeItem)
        : Promise.reject(new Error(`$[TAG] Error fetching byte range ${byteOffset}-${endOffset} for URL "${this.url}`))
    );
  }

}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default RangeBuffer;
