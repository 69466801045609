/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import CustomError from '@rakuten/common-error/CustomError';

// Internal dependencies
import { SESSION_ERROR_CODES, TAG } from "./session.constants";

// -----------------------------------------------------------------------------
// ERROR EXPORTS
// -----------------------------------------------------------------------------

export class SessionError extends CustomError {

  constructor(message, options) {
    options = Object.assign({
      type: 'SessionError',
      tag: TAG
    }, options);
    super(SESSION_ERROR_CODES[message] || SESSION_ERROR_CODES["DEFAULT_ERROR_KEY"], options);
  }
};
