/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * * Utility functions around everything related to JavaScript functions
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

/**
 * Allows execution of the provided @func in the specified interval @wait.
 *
 * @param {function} func The function to debounce
 * @param {integer} wait Minimum time (in ms) to wait until @func is called
 */
const debounce = function debounce(func, wait) {
  if (!func) {
    return;
  }
  // key for the debouncedFn map is the function name or its string representation (for anonymous functions)
  var key = func.name || func.toString();
  var context = this;
  var args = arguments;

  if (debounce.debouncedFn[key]) {
    clearTimeout(debounce.debouncedFn[key]);
  }

  debounce.debouncedFn[key] = setTimeout(function () {
    debounce.debouncedFn[key] = null;
    func.apply(context, args);
  }, wait);
};
debounce.debouncedFn = {};


/**
 * Returns debounced function @func being executed in min. @wait ms.
 * The resulting debounced function returns a promise being resolved with the
 * result of evaluated @func.
 *
 * @param {function} func The function to debounce
 * @param {integer} wait Minimum time (in ms) to wait until @func is called
 * @return {function} By @wait ms debounced function @func; returning a promise
 */
const getDebouncedFunction = function getDebouncedFunction(func, wait) {
  var timeout;

  return function () {
    var context = this;
    var args = arguments;

    return new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        timeout = null;
        return resolve(func.apply(context, args));
      }, wait);
    });
  };
};


/**
 * Creates a memoized function of the provided @func, caching the returned
 * result for given arguments.
 *
 * Requirement: @func must be deterministic, i.e. return the same result for a
 * defined combination of parameters.
 *
 * @param {function} func The function to memoize
 * @param {object} [options={ }] Optimization options with attributes `noRefs`
 *                 and  `numArgs`, `noRefs` indicates that @func will not
 *                 receive any referential arguments `numArgs` is the number of
 *                 arguments for @func
 * @return {function} Memoized @func
 */
const memoize = function memoize(func, options = { }) {
  const context = this;
  const cache = { };
  const refMap = new WeakMap();
  let refCounter = 0;

  const __createRef = () => '¶' + refCounter++;

  function __resolveRef(arg) {
    if (typeof arg !== 'object' && typeof arg !== 'function' || !arg) {
      return arg;
    }

    return refMap.get(arg) || refMap.set(arg, __createRef()).get(arg);
  }

  const getKey = options.noRefs
    ? ( options.numArgs === 1
        ? args => args[0]
        : args => Array.prototype.slice.call(args)
      )
    : ( options.numArgs === 1
        ? args => __resolveRef(args[0])
        : function(args) {
          const keyList = new Array(args.length);
          for (let i = 0, ii = args.length; i < ii; i++) {
            keyList[i] = __resolveRef(args[i]);
          }
          return keyList;
        }
      );

  return function() {
    const key = getKey(arguments);
    return key in cache
      ? cache[key]
      : (
        cache[key] = func.apply(context, arguments)
      );
  }
};


/**
 * Returns the memoized function of @func, caching the returned result for the
 * previous call if the given arguments are the same (shallow comparison).
 * Requirement: @func must have at least one parameter and it must be deterministic,
 * i.e. return the same result for a defined combination of parameters.
 * 
 * @param {Function} func Function to be memoized
 * @return {Function} @func with memoization
 */
function memoizeLastCall(func) {
  const context = this;
  let lastArgs  = [];
  let lastResult;

  return function() {
    for (let i = 0, numArgs = arguments.length; i < numArgs; i++) {
      if (arguments[i] !== lastArgs[i]) {
        lastArgs = arguments;
        return lastResult = func.apply(context, arguments);
      }
    }
    return lastResult;
  };
}


/**
 * Helper function to let components register callbacks for services.
 *
 * @return {object} Functions to register/remove and execute
 */
const registerCallbacks = function registerCallbacks() {
  const callbacks = { };

  return {
    add: (key, fnc) => callbacks[key] = fnc,
    remove: key => delete callbacks[key],

    // Executes all registered callbacks
    execute: value => Object.values(callbacks)
      .forEach(callback => callback(value))
  }
};


/**
 * Promise-based variant for "setTimeout", returning a promise resolved with the
 * result of @func's execution after waiting @wait ms.
 *
 * @param {Function} func The function to execute
 * @param {Integer} wait Time (in ms) to wait until @func is called
 * @return {Promise<*>} Promise resolved with the result of the execution of @func
 */
const timeout = function timeout(func, wait) {
  if (typeof func !== "function") {
    func = Function.prototype;
  }
  return new Promise((resolve) => {
    setTimeout(() => resolve(func()), wait);
  });
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  debounce,
  getDebouncedFunction,
  memoize,
  memoizeLastCall,
  registerCallbacks,
  timeout
};
