/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/third-party dependencies
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore
} from 'redux';
import {
  persistReducer,
  persistStore
} from 'redux-persist'
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

// Internal dependencies
import { locateService } from '~services/locator/locator';

// Reducers
import appReducer from './reducers/kobowebrx.reducer';
import mqReducer from './reducers/mediaQuery.reducer';
import rxReduxer from './reducers/rx.reducer';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[redux/store]';

// Used to store a reference to the Redux store for the app state
let _store;

// Used to keep a reference to the Redux store's persistor
let _persistor;


// -----------------------------------------------------------------------------
// PERSIST CONFIG
// -----------------------------------------------------------------------------

// Persistor configuration(s)
function getRXPersistConfig(appConfig) {
  return {
    key: appConfig.APPSTATE.STORAGES.RX.NAME,
    keyPrefix: appConfig.APPSTATE.KEYPREFIX,
    version: appConfig.APPSTATE.STORAGES.RX.VERSION
  };
}


// -----------------------------------------------------------------------------
// MISC.
// -----------------------------------------------------------------------------

/**
 * Gathers the required reducers for the specified platform.
 *
 * @param {object} localConfig The application's configuration
 * @param {object} indexedDBService The IndexedDB service used to store data
 * @return {object} A map with all the required reducers
 */
function assembleRootReducer(localConfig, indexedDBService) {
  const rootReducer = { };

  rootReducer.app = appReducer
  rootReducer.mediaQuery = mqReducer;
  rootReducer.rx = persistReducer(
    {
      ...getRXPersistConfig(localConfig),
      storage: indexedDBService,
      whitelist: [ 'externalLinksGranted', 'settings' ]
    }, rxReduxer);

  return rootReducer;
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export function configureStore(logger, localConfig, indexedDB) {
  logger.info(`${TAG} Configuring Redux store`);

  let store;
  let persistor;
  let reduxLogger;
  let middlewares;

  // Create root reducer and apply middlewares
  const reducers = assembleRootReducer(localConfig, indexedDB);
  const rootReducer = combineReducers(reducers);

  if (localConfig.BUNDLER.BUILD_MODE === localConfig.BUNDLER.BUILD_MODES.DEVELOPMENT) {
    reduxLogger = createLogger({
      collapsed: true,
      duration: true,
      diff: true
    });
    middlewares = [ thunk, reduxLogger ];

    // Make it possible to use the Redux Dev Tools in development
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(rootReducer, composeEnhancers(
      applyMiddleware(...middlewares)
    ));
    persistor = persistStore(store);
  } else {
    middlewares = [ thunk ];
    store = createStore(rootReducer, applyMiddleware(...middlewares));
    persistor = persistStore(store);
  }

  _store = store;
  _persistor = persistor;

  logger.info(`${TAG} Redux store configured with the following reducers: ${Object.keys(reducers)}`);

  return {
    store,
    persistor
  };
}


/**
 * Helper to get the persistor. Only available after the store has been
 * configured (via configureStore)
 *
 * @return {object} The persistor for the store (redux-persist)
 */
export function getPersistor() {
  if (!_persistor) {
    locateService('logger')
      .then(logger => logger.error(`${TAG} No Redux store configured. Run configureStore() first`));
    return;
  }

  return _persistor;
}


/**
 * Helper to get the Redux store for the app state. Only available after the
 * store has been configured (via configureStore).
 *
 * @return {object} The Redux store for the app state
 */
export function getStore() {
  if (!_store) {
    locateService('logger')
      .then(logger => logger.error(`${TAG} No Redux store configured. Run configureStore() first`));
    return;
  }

  return _store;
}
