/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// SCHEMA DEFINITION
// -----------------------------------------------------------------------------

export default {
  id: 'schema/reducer/rx',
  type: 'object',
  properties: {
    fullTextSearch: {
      type: 'object',
      properties: {
        history: {
          type: 'array',
          uniqueItems: true
        },
        results: {
          type: 'array'
        }
      }
    },

    settings: {
      type: 'object',
      properties: {
        fontFamily: {
          type: 'string'
        },
        fontSize: {
          type: 'integer',
          minimum: 0,
          maximum: 20
        },
        fontWeight: {
          type: 'integer',
          minimum: 0,
          maximum: 1000
        },
        letterSpacing: {
          enum: [ 'default', 'narrow', 'medium', 'wide' ]
        },
        lineSpacing: {
          enum: [ 'default', 'small', 'medium', 'large' ]
        },
        pageSpread: {
          type: 'integer',
          minimum: 0
        },
        pageMargins: {
          enum: [ 'small', 'medium', 'large' ]
        },
        readingMode: {
          enum: [ 'paged', 'scroll' ]
        },
        textAlign: {
          enum: [ 'default', 'left', 'center', 'just' ]
        },
        theme: {
          enum: [
            'theme-kobo-charcoal',
            'theme-kobo-dark',
            'theme-kobo-light',
            'theme-kobo-sepia',
            'theme-tolino-charcoal',
            'theme-tolino-dark',
            'theme-tolino-light',
            'theme-tolino-sepia'
          ]
        },
        wordSpacing: {
          enum: [ 'default', 'narrow', 'medium', 'wide' ]
        },

        isPublishersDefault: {
          type: 'boolean'
        }
      },
      required: [
        'fontFamily',
        'fontSize',
        'fontWeight',
        'letterSpacing',
        'lineSpacing',
        'pageSpread',
        'pageMargins',
        'readingMode',
        'textAlign',
        'theme',
        'wordSpacing',
        'isPublishersDefault'
      ],
      additionalProperties: false
    },
    userSettings: {
      type: 'object'
    }
  },
  required: [
    'fullTextSearch',
    'settings'
  ]
};
