import color from "./color-palette";
import KOBO_COLORS_LIGHT from "./../kobo/colors-light";

/**
 * Kobo Japan theme matches Kobo theme (excl. primary colors)
 */
const COLORS_LIGHT = {
  ...KOBO_COLORS_LIGHT,

  primary:             color.koboJPRed,
  primaryDisabled:     color.koboJPRed38,
  primaryHover:        color.koboJPRed04,
  primarySelected:     color.koboJPRed08,
  primaryPressed:      color.koboJPRed16,

  // Exceptions
  tabBackgroundActiveHover:  color.koboJPRed04         // color of light/primaryHover
};

export default COLORS_LIGHT;
