
function clamp(min, max, value) {
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  return isNaN(value)
    ? min
    : Math.max(min, Math.min(value, max));
}

const clampRGB = clamp.bind(null, 0, 255);
const clamp100 = clamp.bind(null, 0, 100);


function parseAlpha(value) {
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  if (value > 1 && value <= 100) {
    return value / 100;
  }
  return clamp(0, 1, value);
}


const COLOR_TYPE = {
  HEX: "hex",
  HSL: "hsl",
  RGB: "rgb"
};


/**
 * Map of functions to set separate color components
 * @type {Object}
 */
const COLOR_COMPONENT = {
  "a": parseAlpha, // alpha value 0 .. 1
  "b": clampRGB,   // blue (RGB), 0 .. 255
  "h": (value) => Math.abs(Number(value)) > 360 ? Math.abs(Number(value) % 360) : clamp(0, 360, value), // hue (HSL), 0 .. 360 (degree)
  "g": clampRGB,   // green (RGB), 0 .. 255
  "l": clamp100,   // lightness (HSL), 0 .. 100 (percent)
  "r": clampRGB,   // red (RGB), 0 .. 255
  "s": clamp100    // saturation (HSL), 0 .. 100 (percent)
};


const NUMBER  = "[-+]?\\d*\\.?\\d+";
const PERCENT = NUMBER + "%";

function getColorRegExp(prefix, argList) {
  return new RegExp(prefix + "\\(\\s*(" + argList.join(")\\s*,\\s*(") + ")\\s*\\)");
}


/**
 * Map of regular expressions to match color strings.
 * Each color argument is matched to the corresponding matching group index,
 * e.g.
 * 
 * @type {Object}
 */
const REGEX_COLOR = {
  HEX3: /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
  HEX6: /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i,
  HEX8: /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i,
  HSL:  getColorRegExp("hsl", [NUMBER, PERCENT, PERCENT]),
  HSLA: getColorRegExp("hsla", [NUMBER, PERCENT, PERCENT, NUMBER]),
  RGB:  getColorRegExp("rgb", [NUMBER, NUMBER, NUMBER]),
  RGBA: getColorRegExp("rgba", [NUMBER, NUMBER, NUMBER, NUMBER])
};


function parseRGBColor(strColor, result = {}) {
  // matches color strings in RGB representation, e.g. "#rgb(0,171,102)"
  const match = REGEX_COLOR.RGB.exec(strColor);
  if (!match) {
    return null;
  }
  result.r    = parseFloat(match[1], 10);
  result.g    = parseFloat(match[2], 10);
  result.b    = parseFloat(match[3], 10);
  result.type = COLOR_TYPE.RGB;
  return result;
}

function parseRGBAColor(strColor, result = {}) {
  // matches color strings in RGBA representation, e.g. "#rgba(0,171,102,.5)"
  const match = REGEX_COLOR.RGBA.exec(strColor);
  if (!match) {
    return null;
  }
  result.r    = parseFloat(match[1], 10);
  result.g    = parseFloat(match[2], 10);
  result.b    = parseFloat(match[3], 10);
  result.a    = parseFloat(match[4], 10);
  result.type = COLOR_TYPE.RGB;
  return result;
}

function parseHexColorWithAlpha(strColor, result = {}) {
  // matches color strings in HEX representation with alpha channel, e.g. "#00ab6680"
  const match = REGEX_COLOR.HEX8.exec(strColor);
  if (!match) {
    return null;
  }
  result.r    = parseInt(match[1], 16);
  result.g    = parseInt(match[2], 16);
  result.b    = parseInt(match[3], 16);
  result.a    = parseInt(match[4], 16) / 255;
  result.type = COLOR_TYPE.HEX;
  return result;
}

function parseHexColor(strColor, result = {}) {
  // matches color strings in HEX representation, e.g. "#00ab66"
  const match = REGEX_COLOR.HEX6.exec(strColor);
  if (!match) {
    return null;
  }
  result.r    = parseInt(match[1], 16);
  result.g    = parseInt(match[2], 16);
  result.b    = parseInt(match[3], 16);
  result.type = COLOR_TYPE.HEX;
  return result;
}

function parseHexShorthandColor(strColor, result = {}) {
  // matches color strings in HEX shorthand representation, e.g. "#0a6"
  const match = REGEX_COLOR.HEX3.exec(strColor);
  if (!match) {
    return null;
  }
  result.r    = parseInt(match[1] + match[1], 16);
  result.g    = parseInt(match[2] + match[2], 16);
  result.b    = parseInt(match[3] + match[3], 16);
  result.type = COLOR_TYPE.HEX;
  return result;
}

function parseHSLColor(strColor, result = {}) {
  // matches color strings in HSL representation, e.g. "#hsl(156,100%,34%)"
  const match = REGEX_COLOR.HSL.exec(strColor);
  if (!match) {
    return null;
  }
  result.h    = parseFloat(match[1], 10);
  result.s    = parseFloat(match[2], 10);
  result.l    = parseFloat(match[3], 10);
  result.type = COLOR_TYPE.HSL;
  return result;
}

function parseHSLAColor(strColor, result = {}) {
  // matches color strings in HSLA representation, e.g. "#hsl(156,100%,34%,.5)"
  const match = REGEX_COLOR.HSLA.exec(strColor);
  if (!match) {
    return null;
  }
  result.h    = parseFloat(match[1], 10);
  result.s    = parseFloat(match[2], 10);
  result.l    = parseFloat(match[3], 10);
  result.a    = parseFloat(match[4], 10);
  result.type = COLOR_TYPE.HSL;
  return result;
}


/**
 * List of functions to parse color strings, e.g. "rgba(10,20,30)"
 * The order is important because a colour definition can be part of another
 * definition such as "#fff" and "#ffffff".
 * @type {Array}
 */
const FN_PARSE_COLOR = [
  parseRGBColor,
  parseRGBAColor,
  parseHexColorWithAlpha,
  parseHexColor,
  parseHexShorthandColor,
  parseHSLColor,
  parseHSLAColor
];


/**
 * Parses @strColor and returns the parsed component values if there is a match.
 * Hexcode definitions are returned as rgb values, e.g. "#f90" -> { r: 255, g: 153, b: 0 }
 * 
 * @param {String} strColor Color definition
 * @return {Object} Parsed component values
 */
export function parseColor(strColor) {
  for (let i = 0; i < FN_PARSE_COLOR.length; i++) {
    const color = FN_PARSE_COLOR[i](strColor);
    if (color) {
      return color;
    }
  }
}


function rgbToHsl(color, result = {}) {
  const r = color.r / 255;
  const g = color.g / 255;
  const b = color.b / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  }
  else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    h = max === r
      ? (g - b) / d + (g < b ? 6 : 0)
      : ( max === g ? (b - r) / d + 2 : (r - g) / d + 4 );
    h = h / 6;
  }
  result.h = h * 360;
  result.s = s * 100;
  result.l = l * 100;
  result.a = color.a;
  result.type = COLOR_TYPE.HSL;
  return result;
}


function hueToRGBValue(p, q, t) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1/6) return p + (q - p) * 6 * t;
  if (t < 1/2) return q;
  if (t < 2/3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}


function hslToRgb(color, result = {}) {
  const h = color.h / 360;
  const s = color.s / 100;
  const l = color.l / 100;
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  }
  else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hueToRGBValue(p, q, h + 1/3);
    g = hueToRGBValue(p, q, h);
    b = hueToRGBValue(p, q, h - 1/3);
  }
  result.r = Math.round(r * 255);
  result.g = Math.round(g * 255);
  result.b = Math.round(b * 255);
  result.a = color.a;
  result.type = COLOR_TYPE.RGB;
  return result;
}


function rgbToHex(color) {
  const hex = color.r.toString(16).padStart(2, "0")
    + color.g.toString(16).padStart(2, "0")
    + color.b.toString(16).padStart(2, "0");
  
  return color.a >= 0 && color.a <= 1
    ? hex + Math.round(color.a * 255).toString(16)
    : hex;
}


function asRGBString(color) {
  const rgb = `${Math.round(color.r || 0)}, ${Math.round(color.g || 0)}, ${Math.round(color.b || 0)}`;
  return color.a >= 0 && color.a <= 1
    ? `rgba(${rgb}, ${color.a})`
    : `rgb(${rgb})`;
}


function asHSLString(color) {
  const hue = color.h !== undefined ? Math.round(color.h % 360) : 0;
  const sat = color.s !== undefined ? Math.round(color.s * 10) / 10 : 100;
  const lgt = color.l !== undefined ? Math.round(color.l * 10) / 10 :  50;
  const hsl = `${hue}, ${sat}%, ${lgt}%`;
  
  return color.a >= 0 && color.a <= 1
    ? `hsla(${hsl}, ${color.a})`
    : `hsl(${hsl})`;
}


function isHexOrRGB(color) {
  return color.type === COLOR_TYPE.RGB || color.type === COLOR_TYPE.HEX;
}

function isEqualColor(color1, color2) {
  if (color1.type === COLOR_TYPE.HSL && color2.type === COLOR_TYPE.HSL) {
    return color1.h === color2.h
        && color1.s === color2.s
        && color1.l === color2.l
        && (color1.a || 1) === (color2.a || 1);
  }
  if (!isHexOrRGB(color1)) {
    color1 = hslToRgb(color1);
  }
  if (!isHexOrRGB(color2)) {
    color2 = hslToRgb(color2);
  }
  return color1.r === color2.r
      && color1.g === color2.g
      && color1.b === color2.b
      && (color1.a || 1) === (color2.a || 1);
}


/**
 * Composes @colorA with @colorB using the Porter-Duff-Algorithm for alpha
 * compositing "a over b".
 * @see https://en.wikipedia.org/wiki/Alpha_compositing
 *
 * @param {Object} colorA Overlay color object
 * @param {Object} colorB Background color object
 * @return {Object} Resulting color from composing @colorA over @colorB
 */
function composeAlpha(colorA, colorB) {
  if (colorA.type === COLOR_TYPE.HSL) {
    colorA = hslToRgb(colorA);
  }
  if (colorB.type === COLOR_TYPE.HSL) {
    colorB = hslToRgb(colorB);
  }
  
  const alphaA = colorA.a ?? 1;
  const alphaB = (1 - alphaA) * (colorB.a ?? 1);
  const alphaC = clamp(0, 1, alphaA + alphaB);
  const r = (alphaA * colorA.r + alphaB * colorB.r) / alphaC;
  const g = (alphaA * colorA.g + alphaB * colorB.g) / alphaC;
  const b = (alphaA * colorA.b + alphaB * colorB.b) / alphaC;
  
  return new Color(r, g, b, alphaC);
}

/**
 * Color constructor function, to be used with `new` keyword.
 * The color components can be passed as single string argument, such as "#aabbcc",
 * "rgba(170, 187, 204, 1.0)", "hsl(210, 25%, 73%)",
 * or as three separate numeric RGB arguments, or as a color object with component
 * attributes `r`, `g`, `b`, `h`, `s`, `l`, and `a`.
 * 
 * @param {String|Number|Object} colorOrRed
 * @param {String|Number} [g] Optional green color component if @colorOrRed is numeric
 * @param {String|Number} [b] Optional blue color component if @colorOrRed is numeric
 * @param {String|Number} [a] Optional alpha value if @colorOrRed is numeric
 */ 
export default function Color(colorOrRed, g, b, a) {
  if (typeof colorOrRed === "string") {
    for (let i = 0; i < FN_PARSE_COLOR.length; i++) {
      if (FN_PARSE_COLOR[i](colorOrRed, this)) {
        return;
      }
    }
  }
  
  if (typeof colorOrRed === "object") {
    for (let key in colorOrRed) {
      const fnComp = COLOR_COMPONENT[key];
      if (fnComp) {
        this[key] = fnComp(colorOrRed[key]);
      }
    }
    if ("r" in colorOrRed || "g" in colorOrRed || "b" in colorOrRed) {
      this.type = COLOR_TYPE.RGB;
    }
    else if ("h" in colorOrRed || "s" in colorOrRed || "l" in colorOrRed) {
      this.type = COLOR_TYPE.HSL;
    }
  }
  else if (!isNaN(colorOrRed)) {
    this.r = clampRGB(colorOrRed);
    this.g = clampRGB(g);
    this.b = clampRGB(b);
    if (a !== undefined) {
      this.a = parseAlpha(a);
    }
    this.type = COLOR_TYPE.RGB;
  }
}


Color.prototype = {
  composeAlpha: function(color) {
    if (typeof color === "string") {
      color = parseColor(color);
    }
    return composeAlpha(color, this);
  },
  
  isEqual: function(color, refColor) {
    if (typeof color === "string") {
      color = parseColor(color);
    }
    if (typeof refColor === "string") {
      refColor = parseColor(refColor);
    }
    if (!refColor) {
      refColor = this;
    }
    return isEqualColor(color, refColor);
  },
  
  toHSL: function() {
    if (isHexOrRGB(this)) {
      rgbToHsl(this, this);
    }
    return this;
  },
  
  toRGB: function() {
    if (this.type === COLOR_TYPE.HSL) {
      hslToRgb(this, this);
    }
    return this;
  },
  
  toHex: function() {
    const color = this.type === COLOR_TYPE.HSL ? hslToRgb(this) : this;
    return rgbToHex(color);
  },
  
  toRGBString: function() {
    const color = this.type === COLOR_TYPE.HSL ? hslToRgb(this) : this;
    return asRGBString(color);
  },
  
  toString: function(type = this.type) {
    if (type === COLOR_TYPE.HEX) {
      return this.toHex();
    }
    if (type === COLOR_TYPE.HSL) {
      const color = this.type !== COLOR_TYPE.HSL ? rgbToHsl(this) : this;
      return asHSLString(color);
    }
    return this.toRGBString();
  },
  
  lightness: function(l) {
    if (this.type !== COLOR_TYPE.HSL) {
      rgbToHsl(this, this);
    }
    this.l = clamp100(l);
    return this;
  },
  
  saturation: function(s) {
    if (this.type !== COLOR_TYPE.HSL) {
      rgbToHsl(this, this);
    }
    this.s = clamp100(s);
    return this;
  },

  withAlpha: function(a) {
    this.a = Math.max(0, Math.min(a, 1));
    return this;
  },
  
  withoutAlpha: function() {
    delete this.a;
    return this;
  }
};
