/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import Crypto from './Crypto';
import AESDecrypt from './AESDecrypt';
import SHA1 from './SHA1';
import SHA256 from './SHA256';
import ROT8000 from './ROT8000';


// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

// Service config for service locator
export const config = [ "crypto", [ ], createCryptoService ];


// -----------------------------------------------------------------------------
// CRYPTO SERVICE
// -----------------------------------------------------------------------------

/**
 * Factory function to initialize the crypto service
 *
 * @return {object} API that this service provides
 */
function createCryptoService() {

  // ---------------------------------------------------------------------------
  // PRIVATE METHODS
  // ---------------------------------------------------------------------------

  const initCrypto = function initCrypto() {
    return new Crypto();
  }


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    initCrypto,
    AESDecrypt,
    ROT8000,
    SHA1,
    SHA256
  };

};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  createCryptoService,
  AESDecrypt,
  ROT8000,
  SHA1,
  SHA256
};
