/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2022-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// CONSTANTS
// -----------------------------------------------------------------------------

/**
 * Tag for log output etc.
 *
 * @type {string}
 * @default
 */
const TAG = '[service/fetch]';

/**
 * Allowed methods for requests
 *
 * @type {object}
 * @default
 */
const XHR_REQUEST_METHODS = {
  HEAD: 'HEAD',
  GET: 'GET',
  POST: 'POST'
};

/**
 * Allowed types of responses for requests
 *
 * @type {object}
 * @default
 */
const XHR_RESPONSE_TYPES = {
  ARRAYBUFFER: 'arraybuffer',
  BLOB: 'blob',
  DOCUMENT: 'document',
  JSON: 'json'
};

/**
 * Default headers for specific response types
 *
 * @type {object}
 * @default
 */
const HEADERS = {
  JSON: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};


/**
 * Available error codes/messages
 *
 * @type {object}
 * @default
 */
const FETCH_ERROR_CODES = Object.freeze({
  ABORT: {
    message: 'While fetching the resource, the request was aborted.'
  },

  BAD_REQUEST: {
    message: 'The resource could not be loaded due to a bad request.'
  },

  COMMON_ERROR: {
    message: 'While fetching the resource, there was an error.'
  },

  NO_URL_PROVIDED: {
    message: 'The request could not be fulfilled, because no URL was given.'
  },

  SERVER_ERROR: {
    message: 'While fetching the resource, the server encountered an error.'
  },

  TIMEOUT: {
    message: 'Fetching the resource timed out.',
    details: {
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/408
      status: 408
    }
  },

  UNKNOWN_RESPONSE_TYPE: {
    message: ''
  }
});


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  FETCH_ERROR_CODES,
  HEADERS,
  TAG,
  XHR_REQUEST_METHODS,
  XHR_RESPONSE_TYPES
}
