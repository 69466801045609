/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2022-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import { datadogRum } from '@datadog/browser-rum';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[service/common/monitoring]';


// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

// Service config for service locator
export const config = [
  "monitoring",
  [
    "logger",
    "localConfig",
    "featureFlag"
  ],
  createMonitoringService ];


// -----------------------------------------------------------------------------
// DATADOG SERVICE
// -----------------------------------------------------------------------------

/**
 * This function creates a service with given
 * dependencies
 */
export function createMonitoringService(logger, localConfig, featureFlagService) {
  const appConfig = localConfig.getLocalConfig();
  const allowedDomains = [ "kobo.com" ];

  // ---------------------------------------------------------------------------
  // PRIVATE FUNCTIONS
  // ---------------------------------------------------------------------------

  const filterMonitoringURLs = event =>
    event &&
    allowedDomains.some( url => event.view?.url.includes( url ) );

  // ---------------------------------------------------------------------------
  // PUBLIC FUNCTIONS
  // ---------------------------------------------------------------------------

  const setUp = ({
    applicationId,
    clientToken
  }) => {
    // Check if application allows for any monitoring activity
    const disabledFeatures = featureFlagService.getDisabledFeatures();
    if (disabledFeatures.indexOf(featureFlagService.FEATURES.ANALYTICS_MONITORING) > -1) {
      logger.info(`${TAG} Method "setUp()" not executed, because feature "${featureFlagService.FEATURES.ANALYTICS_MONITORING}" is disabled`);
    }

    try {
      // 'env' variable for the Datadog
      // readnow.kobo.com         -> kobo-production
      // readnowstage.kobo.com    -> kobo-stage
      // readnowhd{NUM}.kobo.com  -> kobo-hd{Num}
      const env_mode = appConfig.DEPLOYMENT.ENVIRONMENT;
      const deploymentEnvironment = appConfig.MONITORING.DATADOG_PREFIX + env_mode;
      const serviceName = appConfig.APPLICATION.NAME.replace( /\s/g, '-' ).toLowerCase();

      // Version to identify the app deployed in Datadog
      const version = appConfig.APPLICATION.VERSION;

      datadogRum.init({
        applicationId,
        clientToken,
        // `site` refers to the Datadog site parameter of organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.com',
        service: serviceName,
        env: deploymentEnvironment,
        version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        traceContextInjection: true, // Injects DD trace context for XHR/fetch requests
        traceSampleRate: 100, // 100 - trace all requests
        trackUserInteractions: true,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        beforeSend: filterMonitoringURLs
      });
    } catch (error) {
      console.error(`Monitoring setUp error: ${error.message}`);
    }
  };


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    setUp
  };
}
