/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import merge from 'lodash/merge';

// Internal dependencies
import logger from '@rakuten/services-common';


// -----------------------------------------------------------------------------
// HELPER
// -----------------------------------------------------------------------------

/**
 * Removes the first {@param levels} of the stack trace of an Error instance
 *
 * @param {string} stack The stack trace of an Error
 * @param {number} levels The number of levels to remove
 * @return {string} The truncated stack trace
 * @see https://stackoverflow.com/a/71624129
 */
function trimCallStack(stack, levels) {
  if (stack) {
    const newLineChar = '\n';
    const isFirefoxCallStack = stack.indexOf('@') > -1;
    let iterations = (isFirefoxCallStack ? 0 : 1) + (levels ?? 0);
    let start = 0;
    while (iterations-- && start !== -1) {
      start = stack.indexOf(newLineChar, start + 1);
    }

    stack = start !== -1 ? stack.substring(start + 1) : '';
  }

  return stack || '';
}


// -----------------------------------------------------------------------------
// CUSTOM ERROR CLASS
// -----------------------------------------------------------------------------

class CustomError extends Error {

  constructor(messageOrData, options = { }) {
    // The constructor can be called either via a message and (optional) options
    // or via an object containing information and (optional) options
    let message;
    if (messageOrData.constructor === String) {
      message = messageOrData;
    } else {
      message = messageOrData.message;
      options = merge(messageOrData, options);
    }

    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    // Default
    this.name = options.name || 'CustomError';
    this.displayName = options.displayName || this.name;
    this.tag = options.tag || '[error/customError]';
    this.message = message;

    // Extensions
    this.id = options.id || null;
    this.shouldWriteToLogger = options.shouldWriteToLogger !== false ? true : false;
    this.details = options.details || undefined;

    // We do not need the call to this CustomError class in the call stack, we
    // are only interested in the class that inherited from CustomError
    // TODO: Make sure to only filter calls to CustomError and not something else
    this.stack = trimCallStack(this.stack, 1);

    // Be default, we want all errors to be logged
    if (this.shouldWriteToLogger === true) {
      this.writeToLogger();
    }
  }

  writeToLogger() {
    const output = `${this.tag}`;

    if (this.details) {
      logger.error(output, this, '\n\nDetails:', this.details);
    } else {
      logger.error(output, this);
    }
  }
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default CustomError;
