import Color from "@rakuten/common-util/color";

// base colors as `Color` objects, used to derive colors with `lightness`,
// `saturation`, or `withAlpha`
const black       = new Color(0, 0, 0);
const white       = new Color(255, 255, 255);
const red         = new Color(255, 0, 0);
const blue        = new Color(0, 0, 255);
const yellow      = new Color(255, 255, 0);

const colorPalette = {

  black:             black.toString(),
  black04:           black.withAlpha(0.04).toString(),
  black12:           black.withAlpha(0.12).toString(),
  black15:           black.withAlpha(0.15).toString(),
  black20:           black.withAlpha(0.20).toString(),
  black42:           black.withAlpha(0.42).toString(),
  black65:           black.withAlpha(.65).toString(),

  transparent:       white.withAlpha(0).toString(),
  white:             white.withoutAlpha().toString(),
  white04:           white.withAlpha(.04).toString(),
  white08:           white.withAlpha(0.08).toString(),
  white24:           white.withAlpha(0.24).toString(),
  white32:           white.withAlpha(.32).toString(),
  white48:           white.withAlpha(0.48).toString(),
  white50:           white.withAlpha(0.50).toString(),
  white67:           white.withAlpha(.67).toString(),
  white75:           white.withAlpha(0.75).toString(),

  red:               red.toString(),
  blue:              blue.toString(),
  yellow:            yellow.toString()
};

export default colorPalette;
