/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { TAG } from "./google-analytics.constants";
import { GoogleAnalyticsError } from "./google-analytics.error";


// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

// Service config for service locator
export const config = [ "googleAnalytics", [
  "logger",
  "localConfig",
  "session",
  "fetch",
  "featureFlag"
], createGaService ];


// -----------------------------------------------------------------------------
// GOOGLE ANALYTICS SERVICE
// -----------------------------------------------------------------------------

/**
 * Helper to inject ga in the <head>
 */
function injectGA(gaId) {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', gaId);
  };
}

const checkConsent = (privacyPermissions) =>
  privacyPermissions.indexOf('analytics.tracking.googleAnalytics') > -1;


/**
 * Factory function which creates a service with given
 * dependencies
 *
 * Inizialize google analytics
 */
export function createGaService(logger, localConfig, sessionService, fetchService, featureFlagService) {
  // App configuration
  const appConfig = localConfig.getLocalConfig();
  const env = appConfig.DEPLOYMENT.ENVIRONMENT;
  const path = window.location.pathname;
  const productId = path.substring(path.lastIndexOf('/') + 1);
  let readContent;
  let bookFormat = "";
  let ownershipStatus = "";

  function isFeatureDisabled() {
    const disabledFeatures = featureFlagService.getDisabledFeatures();
    return disabledFeatures.indexOf(featureFlagService.FEATURES.ANALYTICS_GOOGLE_ANALYTICS) > -1;
  }


  function announceDisabledFeature(method) {
    logger.info(`${TAG} Method "${method}()" not executed, because feature "${featureFlagService.FEATURES.ANALYTICS_GOOGLE_ANALYTICS}" is disabled`);
  }


  // TODO: rename 'injectCloseContent' to something better
  // when we update PreviewReader as it uses the same method
  function injectCloseContent(appConfig, contentInfo) {
    if (isFeatureDisabled()) {
      announceDisabledFeature('injectCloseContent');
      return;
    }

    window.addEventListener( 'beforeunload', (event) => onPageClose({ appConfig, contentInfo, event }) );
  }


  function onPageClose({ appConfig, contentInfo }) {
    if (isFeatureDisabled()) {
      announceDisabledFeature('onPageClose');
      return;
    }

    const path = window.location.pathname;
    const productId = path.substring(path.lastIndexOf('/') + 1);
    const orientation = window.matchMedia("(orientation: portrait)").matches
      ? appConfig.MEDIA_QUERY.ORIENTATION.PORTRAIT
      : window.matchMedia("(orientation: landscape)").matches
      ? appConfig.MEDIA_QUERY.ORIENTATION.LANDSCAPE
      : 'Unknown';

    const bookFormat = contentInfo?.contentDownloadInfo?.productFormat ||
      contentInfo?.contentDownloadInfo?.downloadUrl?.substring(contentInfo?.contentDownloadInfo?.downloadUrl?.lastIndexOf('.') + 1) ||
      'Unknown';
    const ownershipStatus = contentInfo?.ownershipStatus || 'Unknown';

    if (typeof window.gtag === 'function') {
      window.gtag(appConfig.ANALYTICS.GOOGLE.ID_EVENT, appConfig.ANALYTICS.GOOGLE.EVENTS.LEAVE_CONTENT, {
        book_id: productId,
        orientation,
        book_format: bookFormat,
        book_type: ownershipStatus
      });
    } else {
      console.log('event:', appConfig.ANALYTICS.GOOGLE.ID_EVENT, '- event_name:', appConfig.ANALYTICS.GOOGLE.EVENTS.LEAVE_CONTENT, '-', {
        book_id: productId,
        orientation,
        book_format: bookFormat,
        book_type: ownershipStatus
      });
    }
  }


  function setUp(content) {
    if (isFeatureDisabled()) {
      announceDisabledFeature('setUp');
      return Promise.resolve();
    }

    if (content) {
      readContent = content;
    } else {
      if (!productId) {
        return Promise.reject(new GoogleAnalyticsError("NO_PRODUCT_ID"));
      }

      return sessionService?.fetchSession()
        .then(sessionId => {

          if (!sessionId) {
            return Promise.reject(new GoogleAnalyticsError("NO_SESSION"));
          }
          return fetchService.fetch({
            url: `${appConfig.READINGSERVICES.BASE_URL}/ReadContent/${productId}`,
            options: {
              credentials: 'include'
            },
            timout: 10000
          }).then(response => {
            if (response?.result === "Success") {
              readContent = response;
            }

            if (response.result === 'Error') {
              return Promise.reject(new GoogleAnalyticsError("FAULTY_PAYLOAD", { detail: { response } }));
            }

            //NotAvailable
            return Promise.reject(new GoogleAnalyticsError("FAILED_REQUEST", { detail: { response } }));
          });
        }).catch(error => {

          //pass through for BookmarkErrors
          if (error instanceof GoogleAnalyticsError) {
            return Promise.reject(error);
          }
          return Promise.reject(new GoogleAnalyticsError("NO_SESSION"), { detail: { parent: error } });
        });
    }
  }


  function handleConsent(gaId) {
    if (isFeatureDisabled()) {
      announceDisabledFeature('handleConsent');
      return;
    }

    const privacyPermissions = readContent?.privacyPermissionsInfo?.privacyPermissions;
    bookFormat = readContent?.contentDownloadInfo?.productFormat || readContent?.contentDownloadInfo?.downloadUrl?.substring(readContent?.contentDownloadInfo?.downloadUrl?.lastIndexOf('.') + 1);
    ownershipStatus = readContent?.ownershipStatus;

    if (privacyPermissions && checkConsent(privacyPermissions) && env === 'production')
      injectGA(gaId);
  }


  function sendEvent(id, name, showBaseParams, params) {
    if (isFeatureDisabled()) {
      announceDisabledFeature('sendEvent');
      return;
    }

    const baseParams = showBaseParams ? {
      book_format: bookFormat || 'Unknown',
      book_type: ownershipStatus || 'Unknown',
      orientation: window.matchMedia("(orientation: portrait)").matches ? appConfig.MEDIA_QUERY.ORIENTATION.PORTRAIT : (window.matchMedia("(orientation: landscape)").matches ? appConfig.MEDIA_QUERY.ORIENTATION.LANDSCAPE : 'Unknown')
    } : null;

    const data = {
      book_id: productId,
      ...baseParams,
      ...params
    };

    if (typeof window.gtag === 'function') {
      window.gtag(id, name, data);
    } else {
      logger.log(`${TAG} - event:${id} - event_name: ${name} - ${JSON.stringify(data)}`);
    }
  }


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    setUp,
    handleConsent,
    injectCloseContent,
    sendEvent
  };
}
