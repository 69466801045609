/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { TAG } from "./bookmark.constants";
import { BookmarkError } from "./bookmark.error";
import { preventConcurrentExec } from "~common/util/promise";

// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["bookmark", ["localConfig", "session", "fetch"], createBookmarkService];

// -----------------------------------------------------------------------------
// Kobo Bookmark SERVICE
// -----------------------------------------------------------------------------

/**
 * Factory function which creates a service with given
 * dependencies
 */
export function createBookmarkService(localConfig, sessionService, fetchService) {

  // App configuration
  const appConfig = localConfig.getLocalConfig();

  // Prevent multiple calls to functions that create the same promise
  const guardedPromise = preventConcurrentExec();

  const ReadingState = {
    READYTOREAD: 'ReadyToRead',
    READING: 'Reading',
    FINISHED: 'Finished'
  }

  /**
   * updateBookmark updates the current bookmark on server side
   */
  function updateBookmark(entitlementId, bookmark) {

    return guardedPromise(`${TAG}:updateBookmark`, () => {

      if (!entitlementId) {
        return Promise.reject(new BookmarkError("MISSING_ID"));
      }

      if (!bookmark) {
        return Promise.reject(new BookmarkError("MISSING_PAYLOAD"));
      }

      const firstSpan = 'kobo.1.1';
      let koboBookmark = {
        Type: "Kobospan",
        ContentSource: bookmark.chapter,
        Value: firstSpan
      };

      if(bookmark.begin?.serializeString) {
        let splitLocation = bookmark.begin.serializeString.split(':');

        koboBookmark.ContentSource = splitLocation[0];
        koboBookmark.Value = splitLocation[1] !== undefined && splitLocation[1] !== '#'
          ? splitLocation[1]
          : firstSpan
      }

      return sessionService.fetchSession()
        .then(() => {
          return fetchService.fetch({
            data: JSON.stringify({
              bookmark: JSON.stringify(koboBookmark),
              progressPercent: bookmark.percentageOfBook * 100,
              contentSourceProgressPercent: bookmark.begin.pagePercentageInChapter * 100,
              readingState: ReadingState.READING
            }),
            options: {
              credentials: 'include',
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              responseType: 'json',
              keepalive: true
            },
            url: `${appConfig.READINGSERVICES.BASE_URL}/Bookmark/${entitlementId}`,
            timout: 10000
          })
            .then(response => {
              if (response?.result === "Success") {
                return response;
              }

              if (response.result === 'Error') {
                return Promise.reject(new BookmarkError("FAULTY_PAYLOAD", { detail: { response } }));
              }
              return Promise.reject(new BookmarkError("FAILED_REQUEST", { detail: { response } }));
            })
            .catch((error) => {

              //pass through for BookmarkErrors
              if (error instanceof BookmarkError) {
                return Promise.reject(error);
              }
              return Promise.reject(new BookmarkError("ERROR", { detail: { parent: error } }));

            });
        });
    });
  }

  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    updateBookmark
  };
}
