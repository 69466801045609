/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import { PURGE as REDUX_PURGE } from 'redux-persist';

// Internal dependencies
import {
  MQ_BREAKPOINT_CHANGED,
  MQ_ORIENTATION_CHANGED
} from './../actions/types';
import { validateSchema } from '@rakuten/schemas/utils';
import mediaQuerySchema from '@rakuten/schemas/reducers/mediaQuery.schema';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

const DEFAULT_STATE = {
  breakpoint: {
    id: null,
    max: Number.MAX_SAFE_INTEGER,
    min: 0
  },
  orientation: null
};


// -----------------------------------------------------------------------------
// MEDIA QUERY REDUCER
// -----------------------------------------------------------------------------

function mq(state, action) {
  let newState = state || DEFAULT_STATE;

  switch (action.type) {
    case MQ_BREAKPOINT_CHANGED:
      if (state.breakpoint.id !== action.payload.id) {
        newState = {
          ...state,
          breakpoint: {
            id: action.payload.id,
            max: action.payload.max,
            min: action.payload.min
          }
        };
      }
    break;

    case MQ_ORIENTATION_CHANGED:
      if (state.orientation !== action.payload.orientation) {
        newState = {
          ...state,
          orientation: action.payload.orientation
        };
      }
    break;

    case REDUX_PURGE:
      newState = DEFAULT_STATE;
    break;
  }

  validateSchema(newState, mediaQuerySchema);

  return newState;
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default mq;
