/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// SCHEMA DEFINITION
// -----------------------------------------------------------------------------

export default {
  id: 'schema/reducer/mediaQuery',
  type: 'object',
  properties: {
    breakpoint: {
      type: 'object',
      properties: {
        id: {
          enum: [
            'smallScreen',
            'mediumScreen',
            'largeScreen',
            'extraLargeScreen',
            null
          ]
        },
        max: {
          type: 'integer'
        },
        min: {
          type: 'integer'
        }
      }
    },
    orientation: {
      enum: [
        'landscape',
        'portrait',
        null
      ]
    }
  },
  required: [
    'breakpoint',
    'orientation'
  ],
  additionalProperties: false
};
