/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

// Internal dependencies
import GLOBALS from '~/GLOBALS';
import { locateService } from '~services/locator/locator';
import logger from '@rakuten/services-common';
import i18n from '~services/common/i18n/i18n';  // FIXME: Make use of a "real" service
import { findObjByValue } from '@rakuten/common-util/object';
import { resolveThemeName } from '@rakuten/common-util/theme';
import {
  debounce,
  getDebouncedFunction
} from '@rakuten/common-util/function';
import { configureStore } from '@rakuten/redux/store.kobowebrx';
import {
  changeLocale,
  changeTheme as changeReduxTheme
} from '@rakuten/redux/actions/app.actions';
import {
  changeBreakpoint,
  changeOrientation
} from '@rakuten/redux/actions/mediaQuery.action';
import { batchDispatch } from '@rakuten/redux/utils';
import ThemeContextProvider, { isValidTheme } from '@rakuten/contexts/ThemeContext';
import ActivityIndicator from '@rakuten/components/molecules/ActivityIndicator';

const App = React.lazy(() =>
  import('./App')
);


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[AppContainer]';

// Configuration for react-i18next
const i18nConfig = {
  backend: {
    loadPath: 'intermediates/locales/{{lng}}/{{ns}}.json'
  },
  debug: logger.getLogLevel()?.value >= 2,
  defaultNS: GLOBALS.LOCALE.NAMESPACE,
  fallbackLng: GLOBALS.LOCALE.FALLBACK,
  interpolation: {
    escapeValue: false
  },
  lng: null,  // will be set in initI18n method
  load: 'currentOnly',
  ns: GLOBALS.LOCALE.NAMESPACES,
  supportedLngs: GLOBALS.LOCALE.SUPPORTED
};


// -----------------------------------------------------------------------------
// HELPER
// -----------------------------------------------------------------------------

/**
 * We use Modernizr to check if everything we need is available on the platform/
 * browser the app is currently executed on.
 */
const checkRequiredTechFeatures = () => {
  const notSupportedTechFeatures = [];
  Object.entries(Modernizr).forEach(
    entry => {
      entry[1] !== true && notSupportedTechFeatures.push(entry[0]);
    }
  );
  if (notSupportedTechFeatures.length > 0) {
    logger.warn(`${TAG} Platform does not support required feature(s): ${notSupportedTechFeatures.join(', ')}`)
    // TODO: Handle not supported features
  } else {
    logger.info(`${TAG} Platform supports all required feature(s): ${Object.keys(Modernizr)}`);
  }
};


/**
 * Tries to determine the color scheme of the browser. If none can be
 * determined, we fallback to the 'light' scheme, which should be mapped to
 * our light theme.
 *
 * @return {string} The determined color scheme or 'light' by default
 */
const getBrowserColorScheme = () => {
  const selector = theme => `(prefers-color-scheme: ${theme})`;
  let scheme;

  // Check if the browser supports color scheme detection and get color scheme
  if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
    const dark = window.matchMedia(selector('dark')).matches === true;
    scheme = dark ? 'dark' : 'light';
  }

  // We were not able to detect the color scheme from the browser
  return scheme || 'light';
};


// -----------------------------------------------------------------------------
// APP CONTAINER COMPONENT
// -----------------------------------------------------------------------------

function AppContainer() {

  // ---------------------------------------------------------------------------
  // CONFIGURATION
  // ---------------------------------------------------------------------------

  // Initialize state variables
  const [areAppPrerequisitesFulfilled, setAreAppPrerequisitesFulfilled] =
    useState(false);
  const [ redux, setRedux ] = useState(null);
  const [ theme, setTheme ] = useState(null);

  // Indicators whether event listeners have been registered or not
  const subscriptions = {
    hasColorSchemeChangeListener: false,
    hasWindowResizeListener: false,
    hasVisibilityChangedListener: false,
    reduxChangeListener: null
  };


  // ---------------------------------------------------------------------------
  // EVENT HANDLER
  // ---------------------------------------------------------------------------

  /**
   * @param {Event} e The window orientationchange event
   */
  const debouncedResizeHandler = getDebouncedFunction(e => {
    return locateService('messageBus')
    .then(messageBus => messageBus.publish(
        GLOBALS.MESSAGE_BUS.CHANNEL.APP,
        GLOBALS.MESSAGE_BUS.MESSAGE.APP_WINDOW_RESIZE,
        {
          height: e.target.innerHeight || window.innerHeight,
          width: e.target.innerWidth || window.innerWidth
        }
      ));
  }, GLOBALS.TIMEOUT.WINDOW_RESIZE_DEBOUNCE);

  /**
   * Called whenever the dimensions of the browser window change. We use this as
   * central hub to publish these new dimensions to other parts of the
   * application.
   *
   * Do NOT register additional events for the window's resize event, instead
   * subscribe to the postal channel to get notified.
   *
   * @param {Event} e The window resize event
   */
  const onWindowResize = debouncedResizeHandler;

  /**
   * Called whenever the orientation of the browser window/device changes. We
   * use this as central hub to publish new window dimensions to other parts
   * of the application.
   *
   * Do NOT register additional events for the window's resize event, instead
   * subscribe to the postal channel to get notified.
   *
   * @param {Event} e The window orientationchange event
   */
  const onWindowOrientationChange = debouncedResizeHandler;


  const onVisibilityChanged = () => {
    return locateService('messageBus')
    .then(messageBus => messageBus.publish(
      GLOBALS.MESSAGE_BUS.CHANNEL.APP,
      GLOBALS.MESSAGE_BUS.MESSAGE.APP_VISIBILITY_CHANGED,
      {}
    ));
  };

  /**
   * Called whenever the browser's color scheme changes.
   *
   * @param {MediaQueryListEvent} e The color scheme change event
   */
  const onColorSchemeChange = e => {
    const state = redux.store.getState();
    let theme;
    if (e?.matches === true) {
      // Dark mode
      theme = resolveThemeName('theme-dark', state.app.theme);
    } else {
      // Either auto or light mode
      theme = resolveThemeName('theme-light', state.app.theme);
    }

    redux.store.dispatch(changeReduxTheme(theme));
    setTheme(theme);
  };


  /**
   * Called whenever the Redux state changes. Only handle things that affect the
   * AppContainer component, everything else should be handled via
   * mapStateToProps, useSelectors etc. in each component individually.
   */
  const onReduxChange = () => {
    const state = redux.store.getState();

    // Apply new theme/styles
    if (state.app.theme !== theme) {
      setTheme(state.app.theme);
    }
  };


  // ---------------------------------------------------------------------------
  // INITIALIZATION
  // ---------------------------------------------------------------------------

  /**
   * Initialize i18n/locale handling. We try to get a valid locale identifier
   * from various placer:
   * 1. Locale identifier passed as URL parameter
   * 2. Locale identifier stored in Redux state
   * 3. Default fallback locale identifier defined in GLOBALS
   */
  const initI18n = async () => {
    const queryString = await locateService("queryString");
    const urlLocale = queryString.getQueryParameter("locale");
    const storedLocale = redux.store.getState().app.locale;
    // Locale defined by browser settings
    const browserLocale = navigator.language;
    const fallbackLocale = GLOBALS.LOCALE.FALLBACK;
    const defaultLocale = storedLocale || browserLocale || fallbackLocale;

    let locale;

    // Let the i18n service know which locales we support
    i18n.setSupportedLocales( GLOBALS.LOCALE.SUPPORTED );

    // Initialize i18next/react-i18next and load resources for the current locale
    if (urlLocale && urlLocale !== storedLocale) {
      if (i18n.isValidLocale(urlLocale) === false) {
        locale = i18n.getClosestLocale(urlLocale) || defaultLocale;
        logger.warn(`${TAG} Locale ${urlLocale} is not supported -> Falling back to ${locale}`);
      } else {
        locale = urlLocale;
      }
    } else {
      locale = defaultLocale;
    }

    locale = i18n.getClosestLocale( locale ) || fallbackLocale;

    i18nConfig.lng = locale;
    await i18n.initI18n(i18nConfig);

    // If we have a new locale, store it for later usage
    if ( locale !== storedLocale ) {
      redux.store.dispatch(changeLocale(locale));
    }
  };


  /**
   * Initialize theme. The theme identifier is determined in this order:
   * 1. If a theme has been provided as an URL parameter we use it
   * 2. If a theme name is available in the Redux store, we compare it to
   *    the browser color scheme.
   *    1. If redux theme and browser color scheme match, we use the Redux theme
   *    2. If redux theme and browser color scheme do not match, we try to
   *       apply a theme that matches the browser color scheme
   * 3. If no theme is stored in the Redux store, we use the browser color
   *    scheme to apply a theme based on the color scheme
   * 4. If all fails, we use the fallback theme defined in the application config
   */
  const initTheme = urlTheme => {
    const browserColorScheme = getBrowserColorScheme();
    const storedReduxTheme = redux.store.getState().app.theme;
    const fallbackTheme = GLOBALS.THEME.FALLBACK;
    let appliedTheme = null;

    // Check theme provided in URL
    if (urlTheme && isValidTheme(urlTheme) === false) {
      logger.warn(`${TAG} Theme "${urlTheme} is not a valid theme identifier -> Previous/Fallback theme will be used`);
    }

    if (urlTheme && isValidTheme(urlTheme) === true) {
      // Use theme provided in URL
      appliedTheme = urlTheme;
    } else {
      if (storedReduxTheme) {
        if (browserColorScheme) {
          if (storedReduxTheme.includes(browserColorScheme)) {
            // Use theme from Redux store
            appliedTheme = storedReduxTheme
          } else {
            // Apply theme based on browser color scheme
            appliedTheme = resolveThemeName(`theme-${browserColorScheme}`, redux.store.getState().app.theme);
          }
        } else {
          // Use fallback theme
          appliedTheme = fallbackTheme;
        }
      } else {
        if (browserColorScheme) {
          // Apply theme based on browser color scheme
          appliedTheme = resolveThemeName(`theme-${browserColorScheme}`, redux.store.getState().app.theme);
        } else {
          // Use fallback theme
          appliedTheme = fallbackTheme;
        }
      }
    }

    if (appliedTheme !== storedReduxTheme) {
      redux.store.dispatch(changeReduxTheme(appliedTheme));
    }
    setTheme(appliedTheme);
  };


  /**
   * Register listeners for theme breakpoints, i.e. the width of the window
   * changes and we need to update our UI. When a breakpoint is reached, we
   * trigger a Redux update with the breakpoint information. Components
   * listening to the breakpoint change - via the Redux state - can then update
   * their UI, if necessary.
   */
  const registerThemeBreakpoints = async () => {
    const localConfig = await locateService('localConfig');
    const appConfig = localConfig.getLocalConfig();
    const __getMQId = min => {
      const obj = findObjByValue(GLOBALS.MEDIA_QUERY, min);
      if (obj) {
        const key = Object.keys(obj).find(key => obj[key] === min);
        return GLOBALS.MEDIA_QUERY.BREAKPOINT_IDS[key];
      }

      return null;
    };

    const sortedBreakpoints = Object.values(GLOBALS.MEDIA_QUERY.BREAKPOINTS).
      sort((a, b) => a - b);
    sortedBreakpoints.forEach((min, index) => {
      // `max` is the next breakpoint's min - 1
      const max = (sortedBreakpoints[index + 1] - 1 || Number.MAX_SAFE_INTEGER);
      const mediaDesc = max < Number.MAX_SAFE_INTEGER
        ? `(min-width: ${min}px) and (max-width: ${max}px)`
        : `(min-width: ${min}px)`;
      const mediaQuery = window.matchMedia(mediaDesc);

      const mqListener = e => {
        if (e.matches) {
          const action = changeBreakpoint({ id: __getMQId(min), min, max });
          batchDispatch(
            redux.store,
            action.type,
            action.payload.breakpoint,
            appConfig
          );
          debounce(function debounceBreakpoint() {
            return locateService('messageBus')
              .then(messageBus => messageBus.publish(
                GLOBALS.MESSAGE_BUS.CHANNEL.APP,
                GLOBALS.MESSAGE_BUS.MESSAGE.DEVICE_BREAKPOINT_CHANGE,
                action.payload.breakpoint,
                appConfig
              ));
          }, GLOBALS.REDUX.BATCH_DISPATCH_TIMEOUT);
        }
      };
      mqListener(mediaQuery);
      mediaQuery.addEventListener('change', mqListener);
    });

    const orientationMediaDesc = '(orientation: portrait)';
    const orientationMediaQuery = window.matchMedia(orientationMediaDesc);
    const mqOrientationListener = e => {
      const orientation = e.matches === true
        ? GLOBALS.MEDIA_QUERY.ORIENTATION.PORTRAIT
        : GLOBALS.MEDIA_QUERY.ORIENTATION.LANDSCAPE;
      const action = changeOrientation(orientation);
      batchDispatch(
        redux.store,
        action.type,
        { orientation: action.payload.orientation },
        appConfig
      );
      debounce(function debounceOrientation() {
        return locateService('messageBus')
          .then(messageBus => messageBus.publish(
            GLOBALS.MESSAGE_BUS.CHANNEL.APP,
            GLOBALS.MESSAGE_BUS.MESSAGE.DEVICE_ORIENTATION_CHANGE,
            action.payload.orientation
          ))
          .catch(() => null);
      }, GLOBALS.REDUX.BATCH_DISPATCH_TIMEOUT);
    };
    mqOrientationListener(orientationMediaQuery);
    orientationMediaQuery.addEventListener('change', mqOrientationListener);
  };


  // ---------------------------------------------------------------------------
  // HOOKS
  // ---------------------------------------------------------------------------

  // Fulfill/Init app prerequisites
  useEffect(function effectInitApplication() {
    // Check if all required tech features are available on the current platform
    // We won't do anything with this information, yet. It's only for
    // informational purposes.
    checkRequiredTechFeatures();

    // Initialize Redux store so it can be used throughout the application.
    locateService([
      'logger',
      'localConfig',
      'indexedDb'
    ]).then(([ logger, localConfig, indexedDb ]) => {
      const store = configureStore(logger, localConfig.getLocalConfig(), indexedDb);
      setRedux(store);
    })
  }, [ ]);

  // After Redux is available, we initialize the rest of the application
  useEffect(function effectReduxInit() {
    if (redux) {
      initI18n().then(async () => {
        const queryString = await locateService("queryString")

        initTheme(queryString.getQueryParameter("theme"));
        await registerThemeBreakpoints();

        if (subscriptions.hasWindowResizeListener === false) {
          logger.info(`${TAG} Registering event listener for 'resize' event of window`);
          window.addEventListener('resize', onWindowResize);
          window.addEventListener('orientationchange', onWindowOrientationChange);
          screen.orientation.addEventListener('change', onWindowOrientationChange);
          subscriptions.hasWindowResizeListener = true;
        }
        if (subscriptions.hasVisibilityChangedListener === false) {
          logger.info(`${TAG} Registering event listener for 'visibilitychanged' event on the window`);
          window.addEventListener('visibilitychange', onVisibilityChanged);
          subscriptions.hasVisibilityChangedListener = true;
        }
        if (subscriptions.hasColorSchemeChangeListener === false) {
          logger.info(`${TAG} Registering event listener for 'prefers-color-scheme' event of window.matchMedia`);
          window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', onColorSchemeChange);
          subscriptions.hasColorSchemeChangeListener = true;
        }
        if (subscriptions.reduxChangeListener === null) {
          logger.info(`${TAG} Registering event listener for Redux state changes`);
          subscriptions.reduxChangeListener = redux.store.subscribe(onReduxChange);
        }

        // Signal that everything we need to start the application is done
        setAreAppPrerequisitesFulfilled(true);
      });

      return () => {
        if (subscriptions.hasWindowResizeListener === true) {
          logger.info(`${TAG} Removing event listener for 'resize' event of window`);
          window.removeEventListener('resize', onWindowResize);
          window.removeEventListener('orientationchange', onWindowOrientationChange);
          screen.orientation.removeEventListener('change', onWindowOrientationChange);
          subscriptions.hasWindowResizeListener = false;
        }
        if (subscriptions.hasVisibilityChangedListener === true) {
          logger.info(`${TAG} Removing event listener for 'visibilitychange' event on the window`);
          window.removeEventListener('visibilitychange', onVisibilityChanged);
          subscriptions.hasVisibilityChangedListener = false;
        }
        if (subscriptions.hasColorSchemeChangeListener === true) {
          logger.info(`${TAG} Removing event listener for 'prefers-color-scheme' event of window.matchMedia`);
          window
            .matchMedia('(prefers-color-scheme: dark)')
            .removeEventListener('change', onColorSchemeChange);
          subscriptions.hasColorSchemeChangeListener = false;
        }
        if (subscriptions.reduxChangeListener) {
          logger.info(`${TAG} Removing event listener for Redux state changes`);
          subscriptions.reduxChangeListener();
          subscriptions.hasColorSchemeChangeListener = null;
        }
      }
    }
  }, [ redux ]);


  // ---------------------------------------------------------------------------
  // RENDER
  // ---------------------------------------------------------------------------

  // Unless we have a configured Redux store, we do nothing. Afterwards, we can
  // use the PersistsGate from Redux to decide what to display to the user
  if (!redux) {
    return null;
  }

  return (
    <Provider store={ redux.store }>
      <PersistGate
        loading={ null }
        persistor={ redux.persistor }
      >
        <ThemeContextProvider appearance={ theme }>
          <Suspense fallback={
            <ActivityIndicator />
          }>
          {
            areAppPrerequisitesFulfilled &&
              <App />
          }
          </Suspense>
        </ThemeContextProvider>
      </PersistGate>
    </Provider>
  );
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default AppContainer;
