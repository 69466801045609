/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2022-present Rakuten Kobo Inc. (https://www.kobo.com)
 */


// -----------------------------------------------------------------------------
// CONFIGURTATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[service/netInfo]';

// Network identifier(s)
const NETWORK_TYPE_UNKNOWN = "unknown";

// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["netInfo", ["logger"], createNetInfoService];

// -----------------------------------------------------------------------------
// NET INFO SERVICE
// -----------------------------------------------------------------------------

/**
 * Factory function which creates a service with given
 * dependencies
 */
export function createNetInfoService(logger) {

  // Reference to the event listener registered for network state changes
  let externalEventHandler;

  // ---------------------------------------------------------------------------
  // PRIVATE METHODS
  // ---------------------------------------------------------------------------

  // ----- EVENT HANDLER -----

  /**
   * Whenever the network state changes, the browser fires either an online or
   * offline event. We use information from these events to call the external
   * event handler.
   *
   * @param {object} event The online/offline event
   */
  const onNetworkStateChange = event => {

    const networkState = {
      isConnected: event.type === 'offline' ? false : true,
      network: {
        // For web, we cannot determine the network type, yet, across all
        // browsers in a consistent way. There is an unoffical W3C draft to
        // expose connection information, but it will take some more time until
        // this draft is being ratified and the technology implemented within all
        // browsers.
        // see https://wicg.github.io/netinfo/
        type: navigator.connection?.type || NETWORK_TYPE_UNKNOWN
      }
    };

    if (externalEventHandler) {
      externalEventHandler(networkState);
    }

    logger.info(`${TAG} Network state changed: ${JSON.stringify(networkState)}`);
  };


  // ----- MISC. -----

  /**
   * Start listening for network state change events fired from the browser.
   * The provided callback will be called with the new network state whenever
   * the network state changes (see @onNetworkStateChange).
   *
   * It is only possible to register one callback for network state changes.
   *
   * @param {function} callback Called when the online/offline event is fired
   */
  const startListening = callback => {
    if (externalEventHandler) {
      logger.warn(`${TAG} There is already a listener for network state changes registered -> skipping`);
      return;
    }

    window.addEventListener('online', onNetworkStateChange);
    window.addEventListener('offline', onNetworkStateChange);
    externalEventHandler = callback;

    logger.info(`${TAG} Started to listen for network changes`);
  };


  /**
   * Stop listening for network state change events fired from the browser.
   * @return {bool}
   */
  const stopListening = () => {
    window.removeEventListener('online', onNetworkStateChange);
    window.removeEventListener('offline', onNetworkStateChange);
    externalEventHandler = null;

    logger.info(`${TAG} Stopped listening for network changes`);
  };


  /**
   * Check if browser is currently connected to a network or not.
   *
   * @return {Promise<Boolean>} Whether or not there is a network connection
   */
  const fetchIsConnected = () => new Promise(resolve => {
    resolve(navigator.onLine);
  });


  /**
   * Retrieve the current connection status, including information about the
   * network, if a connection is available.
   *
   * @return {Promise<JSON>} Information about the connection status
   */
  const fetchConnectionStatus = () => new Promise(resolve => {
    resolve({
      isConnected: navigator.onLine,
      networkInfo: {
        type: navigator.connection?.type || NETWORK_TYPE_UNKNOWN
      }
    });
  });


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    fetchIsConnected,
    fetchConnectionStatus,
    startListening,
    stopListening
  };

}
