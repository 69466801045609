/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";

import { transformProps } from "./transformProps";


// -----------------------------------------------------------------------------
// COMPONENT
// -----------------------------------------------------------------------------

export default function SvgXml(props) {
  const { xml, ...svgProps } = transformProps(props, "SvgXml");
  
  return (
    <svg
     { ...svgProps }
     dangerouslySetInnerHTML={{ __html: xml }}
    >
    </svg>
  );
}


// -----------------------------------------------------------------------------
// PROPS VALIDATION
// -----------------------------------------------------------------------------

SvgXml.propTypes = {
  accessible: PropTypes.bool,
  accessibilityLabel: PropTypes.string,
  accessibilityRole:  PropTypes.string,
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  style: PropTypes.oneOfType([ PropTypes.array, PropTypes.object, PropTypes.string ]),
  testID: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  xml: PropTypes.string
};
