/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 *
 * Custom prop type validator functions
 */

/**
 * Check whether or not the value for the prop in question is of type String
 *
 * @param {object} props All component props
 * @param {string} propName Name of the prop to validate
 * @param {string} componentName Name of the component props should be validated for
 * @return {undefined|Error} Error with info is returned if validation fails
 */
export function isStringValidator(props, propName, componentName) {
  const propValue = props[propName];
  if (propValue !== undefined && typeof propValue !== "string") {
    return new Error(`The prop "${ propName }" of component "${ componentName }" should be of type "string", but its type is "${ typeof propValue }".`);
  }
}

/**
 * Check whether or not a value for the prop in question has been set, if, and
 * only if, the `onPress` prop is set.
 *
 * @param {object} props All component props
 * @param {string} propName Name of the prop to validate
 * @param {string} componentName Name of the component props should be validated for
 * @return {undefined|Error} Error with info is returned if validation fails
 */
export function onPressValidator(props, propName, componentName) {
  const propValue = props[propName];
  if (typeof props.onPress === "function" && !propValue) {
    return new Error(`The prop "${ propName }" of component "${ componentName }" is required if prop "onPress" is also defined.`);
  }
}
