/**
 *  ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗  █████╗ ██████╗ ██████╗
 *  ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗██╔══██╗██╔══██╗██╔══██╗
 *     ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║███████║██████╔╝██████╔╝
 *     ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║██╔══██║██╔═══╝ ██╔═══╝
 *     ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝██║  ██║██║     ██║
 *     ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
 *
 * (c) Copyright 2018-present Rakuten Kobo Inc. (http://www.kobo.com)
 */

import React from "react";
import ReactDOM from "react-dom";
import AnimatedImplementation from "./react-native-libs/Animated/AnimatedImplementation";
import styleProcessor from "./styleProcessorCSS";
import { transformProps } from "./transformProps";
import { SyntheticEvent } from "./nativeEvent";
import AsyncStorage from "./AsyncStorage";
import Image from "./Image";
import SvgXml from "./SvgXml";

// =============================================================================
// MAPPING NATIVE COMPONENTS -> HTML
// =============================================================================

/*
 The following Components/Interfaces are exported in "react-native" by default:
 items preceeded by "●" or "?" are marked for consideration as a web polyfill.
 Core components might also be replaced by a shared component for both web and
 React Native such as "UILabel" or "Button".

 ? AccessibilityInfo
   ActionSheetIOS
 ● ActivityIndicator
   Alert
 ● Animated
   AppRegistry
 ? AppState
   Appearance
   AsyncStorage
   BackHandler
 ● Button
 ● CheckBox
   Clipboard
   ColorAndroid
   ColorPropType
   DatePickerAndroid
   DatePickerIOS
   DevSettings
   DeviceEventEmitter
 ? DeviceInfo
 ● Dimensions
   DrawerLayoutAndroid
   DynamicColorIOS
   Easing
   EdgeInsetsPropType
 ● FlatList
   I18nManager
 ● Image
   ImageBackground
   ImagePickerIOS
   InputAccessoryView
   InteractionManager
   Keyboard
   KeyboardAvoidingView
   LayoutAnimation
 ● Linking
   LogBox
   MaskedViewIOS
 ? Modal
   NativeAppEventEmitter
   NativeDialogManagerAndroid
   NativeEventEmitter
   NativeModules
   Networking
   PanResponder
   PermissionsAndroid
   Picker
   PickerIOS
 ? PixelRatio
 ● Platform
   PlatformColor
   PointPropType
   Pressable
   ProgressBarAndroid
   ProgressViewIOS
   PushNotificationIOS
   RefreshControl
 ● SafeAreaView
 ● ScrollView
 ? SectionList
   SegmentedControlIOS
   Settings
   Share
   Slider
   StatusBar
   StatusBarIOS
 ● StyleSheet
   Switch
   Systrace
   TVEventHandler
 ● Text
 ● TextInput
   ToastAndroid
 ● Touchable
   TouchableHighlight
   TouchableNativeFeedback
 ● TouchableOpacity
   TouchableWithoutFeedback
   TurboModuleRegistry
   UIManager
   UTFSequence
   Vibration
 ● View
   ViewPropTypes
   VirtualizedList
   VirtualizedSectionList
*/


// This is the code for the _simple_ component factory without the LayoutContainer
// required for `onLayout`:

// const createComponent = (componentName, tagName, additionalProps) =>
//   React.forwardRef((props, ref) =>
//     React.createElement(tagName, transformProps(props, componentName, ref)));


function createComponent(componentName, tagName, additionalProps) {
  let containerRef = null;

  class LayoutContainer extends React.PureComponent {
    constructor() {
      super();
      this.onResize = this.onResize.bind(this);
    }
    componentDidMount() {
      window.addEventListener("resize", this.onResize, false);
    }
    componentWillUnmount() {
      window.removeEventListener("resize", this.onResize, false);
    }
    onResize(event) {
      const element = ReactDOM.findDOMNode(containerRef);
      if (element) {
        event.layout = element.getBoundingClientRect();
        const syntheticEvent = new SyntheticEvent(event, element);
        this.props.onLayout.call(element, syntheticEvent);
      }
    }
    render() {
      const props = transformProps(this.props, componentName, this.props._forwardedRef);
      return React.createElement(tagName, props);
    }
  }

  // The second param "ref" provided by React.forwardRef.
  // It is passed along to LayoutContainer as a regular prop `_forwardedRef`
  function fnForwardRef(props, fwdRef) {
    let refTagName = tagName;

    if (additionalProps) {
      props = Object.assign({}, additionalProps, props);
    }
    if (tagName === "input" && props.multiline && props.numberOfLines > 1) {
      // re-map tag "input" -> "textarea" for multiline input
      refTagName = "textarea";
    }
    // If props.texttype is 'text' no change is needed
    // props.texttype could be h1,..,h6,a
    if (tagName === "span"  && !((props.texttype)?.includes('text')) ) {

      // re-map tag "span" -> props.texttype like: h1,...,h6,a
      refTagName = props.texttype || refTagName;
    }
    fnForwardRef.displayName = refTagName;

    return typeof props.onLayout !== "function"
      ? React.createElement(refTagName, transformProps(props, componentName, fwdRef))
      : React.createElement(LayoutContainer, Object.assign({}, props, { "_forwardedRef": fwdRef, "ref": (ref) => containerRef = ref }));
      // : <LayoutContainer {...props} _forwardedRef={fwdRef} ref={ (ref) => containerRef = ref } />;
  }

  return React.forwardRef(fnForwardRef);
}


const Button = createComponent("Button", "button", { type: "button" });

const ImageBackground  = createComponent("ImageBackground", "div");
const Pressable        = createComponent("Pressable", "div");
const ScrollView       = createComponent("ScrollView", "div");
const Switch           = createComponent("Switch", "input", { type: "checkbox" }); // @ToDo: Replace by dedicated Toggle component
const Text             = createComponent("Text", "span");
const TextInput        = createComponent("TextInput", "input", { type: "text" });
const TouchableOpacity = createComponent("TouchableOpacity", "div");
const View             = createComponent("View", "div");

// Non-core components (added as 3rd party components in RN) but available as
// HTML tags (such as <svg>) are defined here.

const Svg = createComponent("Svg", "svg");
const SvgImage = createComponent("SvgImage", "img");
const Use = createComponent("Use", "use");


// =============================================================================
// OVERWRITE COMPONENTS IN ANIMATED API
// =============================================================================

const animatedScrollProps = {
  scrollEventThrottle: 0.0001
};

const createAnimatedComponent =
  AnimatedImplementation.createAnimatedComponent.bind(null, transformProps);

// @ToDo: Enable `FlatList` and `SectionList` as soon as implementations are defined

const Animated = Object.assign({}, AnimatedImplementation, {
  createAnimatedComponent,
  // FlatList: createAnimatedComponent(FlatList, animatedScrollProps),
  Image: createAnimatedComponent(Image),
  ScrollView: createAnimatedComponent(ScrollView, animatedScrollProps),
  // SectionList: createAnimatedComponent(SectionList, animatedScrollProps),
  View: createAnimatedComponent(View),
  Text: createAnimatedComponent(Text)
});

// =============================================================================
// REACT NATIVE API
// =============================================================================

// IIFE to create a local scope around all definitions belonging to API `Dimensions`
const Dimensions = (function() {

  const UNDEFINED_DIM = Object.freeze({ height: undefined, width: undefined });

  const getDimensions = {
    "screen": () => ({ height: window.screen.height, width: window.screen.width }),
    "window": () => ({ height: window.innerHeight,   width: window.innerWidth })
  };

  const EVENT_HANDLER = new WeakMap();

  function onDimensionChange(handler) {
    handler({ screen: getDimensions.screen(), window: getDimensions.window() });
  }

  function addEventListener(type, handler) {
    // only "change" is allowed as @type -> don't care
    if (typeof handler !== "function") {
      return;
    }
    const changeListener = onDimensionChange.bind(null, handler);
    window.addEventListener("orientationchange", changeListener);
    window.addEventListener("resize", changeListener);
    EVENT_HANDLER.set(handler, changeListener);
  }

  function removeEventListener(type, handler) {
    const changeListener = EVENT_HANDLER.get(handler);
    if (typeof changeListener === "function") {
      window.removeEventListener("orientationchange", changeListener);
      window.removeEventListener("resize", changeListener);
      EVENT_HANDLER.delete(handler);
    }
  }

  return {
    get: (dim) => (dim in getDimensions) ? getDimensions[dim]() : UNDEFINED_DIM,
    set: Function.prototype,
    addEventListener,
    removeEventListener
  };
})();


const Linking = {
  addEventListener:    () => console.warn(`Linking: method "addEventListener" is not applicable.`),
  canOpenURL:          (url) => Promise.resolve(/^(https?:\/\/|mailto:)/i.test(url)),
  getInitialURL:       () => window.location.href,
  openSettings:        () => console.warn(`Linking: method "openSettings" is not applicable.`),
  openURL:             (url) => Promise.resolve(window.open(url)),
  removeEventListener: () => console.warn(`Linking: method "removeEventListener" is not applicable.`)
};


const Platform = {
  OS: "web",
  select: (platforms) => ("web" in platforms ? platforms.web : platforms.default)
};

const StyleSheet = {
  compose:        styleProcessor.composeStyles,
  create:         styleProcessor.createStyles,
  createMemoized: styleProcessor.createStylesMemoized,
  getStyles:      styleProcessor.getStyles,
  flatten:        styleProcessor.flattenStyle,
  hairlineWidth:  1 / (window.devicePixelRatio || 2) // value may change when the window is moved to another screen
};


// =============================================================================
// EXPORTS
// =============================================================================

export {
  Animated,
  AsyncStorage,
  Button,
  Dimensions,
  Image,
  ImageBackground,
  Linking,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Svg,
  SvgImage,
  SvgXml,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  Use,
  View
};
