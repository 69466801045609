/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 *
 * Mixins for the default theme
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { getService } from "~services/locator/locator";
import Color from "@rakuten/common-util/color";


export function createMixIns(colors, variables, utils) {

  // ---------------------------------------------------------------------------
  // CONFIGURATION
  // ---------------------------------------------------------------------------

  // Array of CSS length units which are accepted as `fontSize` unit, see RegExp `RE_CSS_LENGTH`
  const CSS_LENGTH_UNITS = [
    "ch", "cm", "em", "ex", "in", "mm", "pc", "pt", "px", "rem", "vh", "vw", "%"
  ];

  // Regular expression to match a numeric value with a CSS length unit as specified in `CSS_LENGTH_UNITS`
  const RE_CSS_LENGTH = new RegExp(`([+-]?\\d*\\.?\\d+)\\s?(${ CSS_LENGTH_UNITS.join("|") })?`);

  const USER_SELECT = {
    "all"    : "all",
    "text"   : "text",
    "none"   : "none",
    "inherit": "inherit"
  };

  // -----------------------------------------------------------------------------
  // HELPER
  // -----------------------------------------------------------------------------

  const getEdgeNames = function getEdgeNames(axisOrEdges) {
    let edges = [ ];

    const addEdges = (...newEdges) => {
      edges = edges.concat(newEdges);
    };

    axisOrEdges.forEach(axisOrEdge => {
      switch (axisOrEdge) {
        case 'x':
          addEdges('left', 'right');
          break;
        case 'y':
          addEdges('top', 'bottom');
          break;
        case 'all':
          addEdges('bottom', 'left', 'right', 'top');
          break;
        default:
          addEdges(axisOrEdge);
      }
    });

    return edges;
  };


  // ---------------------------------------------------------------------------
  // REUSABLE MIXINS
  // ---------------------------------------------------------------------------

  /**
   * Returns value for calculation based on basic unit.
   *
   * @example
   *  // Calculating just the value
   *  paddingLeft: themes.mixins.basicUnit(4) // -> '3.2rem'
   *
   *  // Calculating for a specific prop
   *  ...theme.mixins.basicUnit(2, 'marginTop') // -> marginTop: '1.6rem'
   *
   *  // Calculating with pixel unit
   *  paddingLeft: themes.mixins.basicUnit(4, null, 'px') // -> '4px'
   *
   * @param {number} [multi=1] A number to multiply baseicUnit with
   * @param {string} [prop=null] A prop to which to assign the calculated value to
   * @param {string} [unit='rem'] The unit of the calculated value
   * @returns
   */
  function basicUnit(multi = 1, prop = null, unit = 'rem') {
    const value = `${variables.basicUnitRaw * multi}${unit}`;

    return prop
      ? { [prop]: value }
      : value;
  }

  const textEllipsis = Object.freeze({
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    whiteSpace: 'nowrap !important'
  });

  const stylesScrollable = Object.freeze({
    overflowScrolling: 'touch',

    '::-webkit-scrollbar': {
      display: 'block',
      height: basicUnit(1.4),
      width:  basicUnit(1.4)
    },

    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: colors.divider,
      borderColor: colors.transparent,
      borderStyle: "solid",
      borderRadius: basicUnit(0.7),
      borderWidth: 3,
      height: 3
    },

    '::-webkit-scrollbar-track': {
      display: 'none'
    }
  });

  const focusStyle = Object.freeze({
    border: basicUnit(.25) + ' solid ' + colors.transparent,

    ':focus-visible': {
      borderColor: colors.background,
      outline: basicUnit(.25) + ' solid ' + colors.icon,
      zIndex: 1
    }
  });

  const lineItemFocusStyle = Object.freeze({
    ':focus-visible': {
      backgroundColor: colors.iconHover,
      outline: `${ basicUnit(.25) } solid ${ colors.icon } !important`,
      outlineOffset: basicUnit(.25)
    }
  });


  // ---------------------------------------------------------------------------
  // MIXINS
  // ---------------------------------------------------------------------------

  return {

    /**
     * Returns @value w/ @unit suffix if @value is numeric, otherwise plain @value.
     *
     * @param {Number|String} value Any numeric or string value
     * @param {String} [unit="rem"] CSS unit
     * @return {String} @value w/ @unit suffix if @value is numeric, otherwise @value
     */
    asCSSLengthOrPlain: function(value, unit = "rem") {
      return typeof value === "number"
        ? `${value}${unit}`
        : value;
    },

    basicUnit,

    /**
     * Returns basicUnit multiplied by @multi without any unit, i.e. just the
     * raw value.
     *
     * NOTE: Remember to use the calculated value with a unit like rem, if you
     *       apply the calcualted value as a CSS value.
     *
     * @example
     *  const bu = themes.mixins.basicUnitRaw(4); // -> 3.2
     *
     * @param {number} [multi=1] A number to multiply basicUnitRaw with
     * @return {number} The calculated value
     */
    basicUnitRaw: function(multi = 1) {
      return variables.basicUnitRaw * multi;
    },

    /**
     * Returns the result of the calculation @value * @factor + @add with the
     * (CSS) unit of @value.
     * There is no conversion between different units yet, so @factor and @add
     * are required to be unitless numeric values.
     *
     * @param {Number|String} value Value with optional CSS unit, e.g. "1.6rem" or "18px"
     * @param {Number} [factor=1] Multiplier
     * @param {Number} [add=0] Offset to add
     * @return {Number|String} Result of the calculation @value * @factor + @add
     */
    calc: function(value, factor = 1, add = 0) {
      const match = RE_CSS_LENGTH.exec(value);
      if (!match) {
        console.warn(`mixin calc: "${value}" did not match a numeric value`);
        return value;
      }
      const result = Number(match[1]) * factor + add;
      return match[2]
        ? String(result) + match[2]
        : result;
    },

    colorOverlay(baseColor, overlayColor) {
      const overlayColorForMix = new Color(overlayColor);
      return new Color(baseColor).composeAlpha(overlayColorForMix).toString();
    },

    colorWithOpacity(baseColor, opacity) {
      return new Color(baseColor).withAlpha(opacity).toString();
    },

    focusStyle,

    lineItemFocusStyle,

    /**
     * Returns a font styles object according to @size and @fontWeight
     *
     * @param {String} [size="text"] Font size identifier as defined in `variables.fontSize`,
     *                 @size is taken as value if the key is not defined and
     *                 @size ends with a CSS length unit.
     * @param {Number|String} [fontWeight=400] Font weight, 100 .. 900, `bold`, or `normal`
     * @return {Object} Font styles according to @size and @fontWeight
     */
    font: function(size = "text", fontWeight = 400) {
      let fontFamily = size === 'monospace'
        ? variables.fontFamily.MONOSPACE
        : variables.fontFamily.UI
      return {
        fontFamily,
        fontSize: variables.fontSize[size]
          || (RE_CSS_LENGTH.test(size) ? size : variables.fontSize.body),
        fontWeight
      };
    },

    fullWidthHeight: function() {
      return {
        flex: 1,
        height: '100%',
        width: '100%'
      };
    },

    iconMarginHelper: function(edge, size = 'default') {
      const edgeName = `${edge.substring(0, 1).toUpperCase()}${edge.substring(1)}`;

      return {
        [`margin${edgeName}`]: variables.iconSizeMarginCorrection[size]
      };
    },

    lineHeight: function(fontSize, factor, unit = 'rem') {
      const platformService = getService('platform');

      // For native apps, we need a number that will be automatically
      // transformed into a absolute pixel value by our core components.
      // For web apps, we need a factor that the browser will use to
      // multiple the font size with.
      if (platformService.getPlatform() === platformService.platforms.WEB) {
        return factor;
      } else {
        if (unit === 'rem') {
          return utils.convertPixelToREM(fontSize * factor * variables._BASIC_FONT_SIZE) + 'rem';
        }

        return fontSize * factor * variables._BASIC_FONT_SIZE + unit;
      }
    },

    padding: function(size, ...axisOrEdges) {
      const padding = { };

      const edges = getEdgeNames(axisOrEdges);
      edges.forEach(edge => {
        const edgeName = `padding${edge.substring(0, 1).toUpperCase()}${edge.substring(1)}`;
        const sizeName = `padding${size.substring(0, 1).toUpperCase()}${size.substring(1)}`;
        padding[edgeName] = variables[sizeName];
      });

      return padding;
    },

    paddingAdjustHelper: function(size, ...axisOrEdges) {
      const margin = { };

      const edges = getEdgeNames(axisOrEdges);
      edges.forEach(edge => {
        const edgeName = `margin${edge.substring(0, 1).toUpperCase()}${edge.substring(1)}`;
        const sizeName = `padding${size.substring(0, 1).toUpperCase()}${size.substring(1)}`;
        margin[edgeName] = `-${variables[sizeName]}`;
      });

      return margin;
    },

    /**
     * Adds automatic scroll behaviour and styles the scroll bar, where possible
     * (currently, only WebKit based browsers support styling of the scroll bar).
     *
     * @param {String} [scrollAxis] If provided, overflow handling for this axis
     *                              is added. Possible values: "x", "y".
     * @return {Object} Styles for a scrollable container
     */
    scrollable: function(scrollAxis) {
      if (!scrollAxis) {
        return stylesScrollable;
      }
      const overflowAttr = /^(x|y)$/i.test(scrollAxis)
        ? "overflow" + scrollAxis.toUpperCase()
        : "overflow";

      return Object.assign({}, stylesScrollable, {
        [overflowAttr]: "auto"
      });
    },

    textEllipsis,

    transition: function(prop) {
      return {
        transition: `${prop} ${variables.animationDuration} ${variables.animationEasing}`
      }
    },

    userSelect: function(select) {
      const value = USER_SELECT[select] || "auto";
      return {
        WebkitUserSelect: value,
        userSelect: value
      }
    }
  };
}
