/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2022-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import postal from './postal';


// -----------------------------------------------------------------------------
// CONFIGURTATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[service/message-bus]';

// List of channels/topics for which we already warned that there are no
// subscribers available
const WARNINGS = {};

// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["messageBus", ["logger"], createMessageBusService];

// -----------------------------------------------------------------------------
// PRIVATE MODULE METHODS
// -----------------------------------------------------------------------------

/**
   * Creates/Gets the postal channel (object) identified by the provided
   * channel name.
   *
   * @param {string} name The name of the postal channel to create/get
   * @return {object} Postal channel
   */
const _getChannel = function _getChannel(name) {
  return postal.channel(name);
};


/**
 * Makes sure we are dealing with a postal channel
 *
 * @param {string|object} channel Either postal channel name or postal channel
 * @return {object} Postal channel
 */
const _normalizeChannel = function normalizeChannel(channel) {
  return typeof channel === 'string'
    ? _getChannel(channel)
    : channel;
};


/**
 * Creates a key from channel name and topic name to be used as
 * identifier for warnings.
 *
 * @param {string|object} channel Either postal channel name or postal channel
 * @param {string} topic Postal topic name
 * @return {string} Key to identify warning
 */
const _createWarningKey = function _createWarningKey(channel, topic) {
  const _channel = _normalizeChannel(channel);

  return `${_channel.channel}_${topic}`;
};

/**
 * We return a rejection for messages that are published but we also need
 * to supply the sender a chance to check if there is a subscriber :)
 *
 */
function hasSubscribers(channel, topic) {
  const _channel = _normalizeChannel(channel);

  return Boolean(postal.getSubscribersFor({
    channel: _channel.channel,
    topic
  })?.length > 0)
}


// -----------------------------------------------------------------------------
// MESSAGE BUS SERVICE
// -----------------------------------------------------------------------------

export function createMessageBusService(logger) {

  // ---------------------------------------------------------------------------
  // PRIVATE METHODS
  // ---------------------------------------------------------------------------

  /**
   * Creates/Gets the postal channel (object) identified by the provided
   * channel name.
   *
   * @param {string} name The name of the postal channel to create/get
   * @return {object} Postal channel
   */
  const getChannel = _getChannel;



  /**
   * Publishes a topic with optional data on the provided channel. If there
   * are no subscribers registered for the topic on the channel, we reject
   * the promise that is returned for each call to publish.
   *
   * @param {string|object} channel Either postal channel name or postal channel
   * @param {string} topic Postal topic name
   * @param {object} [data] Date to pass to subscribers
   * @return {Promise}
   */
  const publish = function publish(channel, topic, data) {
    return new Promise(function onPostalPublish(resolve, reject) {
      const _channel = _normalizeChannel(channel);

      if (hasSubscribers(_channel.channel, topic)) {
        postal.publish({
          channel: _channel.channel,
          topic,
          data
        });
        return resolve();
      } else {
        // We only want one warning when there is no subscription for the
        // topic for the channel
        const key = _createWarningKey(channel, topic);
        if (!WARNINGS[key]) {
          const message = `${TAG} Channel "${_channel.channel}" has no subscriptions for "${topic}"`;
          logger.warn(message);
          WARNINGS[key] = true;
          return reject(message)
        }
      }
    });
  };


  /**
   * Start listening to the provided topic for the provided channel.
   *
   * The subscription object that will be returned by this method is used
   * to unsubscribe from the subscription
   *
   * @param {string|object} channel Either postal channel name or postal channel
   * @param {string} topic Postal topic name
   * @param {function} callback The function to call when the topic is published on the channel
   * @return {object} A postal subscription
   */
  const subscribe = function subscribe(channel, topic, callback) {
    const _channel = _normalizeChannel(channel);

    // Reset warning that no subscriber is registered for channel/topic
    const key = _createWarningKey(channel, topic);
    delete WARNINGS[key];

    return postal.subscribe({
      channel: _channel.channel,
      topic,
      callback
    });
  };


  /**
   * Stop listening to the provided subscription
   *
   * @param {object} subscription The postal subscription to unsubscribe from
   */
  const unsubscribe = function unsubscribe(subscription) {
    postal.unsubscribe(subscription);
  };


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------


  return {
    hasSubscribers,
    getChannel,
    publish,
    subscribe,
    unsubscribe
  };

}
