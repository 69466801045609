/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// App internal dependencies
// -----------------------------------------------------------------------------

import { preventConcurrentExec } from "@rakuten/common-util/promise";

// -----------------------------------------------------------------------------
// Module internal dependencies
// -----------------------------------------------------------------------------

import { SessionError } from "./session.error";
import { TAG } from "./session.constants";

// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["session", ["logger"], createSessionService];

// -----------------------------------------------------------------------------
// Kobo Session SERVICE
// -----------------------------------------------------------------------------

/**
 * Helper to strip the session id from the cookie string
 */
function getSession(){
  //https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith("sessionId="))
    ?.split("=")[2];
}

/**
 * Factory function which creates a service with given
 * dependencies
 * 
 * The ReadingService will use cookies passed through in the headings
 * of the request to determine if the user making the request is
 * authenticated.   If a user is authenticated we will redirect the
 * user to the index page of the WebReader
 * (i.e. https://readingservices.kobo.com/Reading/{ProductId}).
 * If a user is authenticated ReadNow will make a request to the
 * WebReader at https://readingservices.kobo.com/Reading/{ProductId}.
 *
 */
export function createSessionService(logger) {

  //used to determine if session has changed
  let currentSessionId;

  // Prevent multiple calls to functions that create the same promise
  const guardedPromise = preventConcurrentExec();

  /**
   * gets the sessionId from the cookie. It is basically synchronous
   * but I'am not sure if it should stay that way since other resellers
   * could introduce a different approach.
   */
  function fetchSession() {
    return guardedPromise(`${TAG}:fetchSession`, () => {

      let sessionId;
      try {
        sessionId = getSession()
        if (!sessionId) {
          return Promise.reject(new SessionError("MISSING_SESSION"));
        }
      } catch (e) {
        return Promise.reject(new SessionError("MISSING_SESSION", {
          detail: {
            parent: e
          }
        }));
      }

      if (sessionId !== currentSessionId) {
        logger.warn("Session id has changed");
        currentSessionId = sessionId
      }

      return Promise.resolve(sessionId);
    });
  }
  
  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    fetchSession
  };
}
