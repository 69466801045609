/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import {
  MQ_BREAKPOINT_CHANGED,
  MQ_ORIENTATION_CHANGED
} from './types';


// -----------------------------------------------------------------------------
// ACTION CREATORS
// -----------------------------------------------------------------------------

export function changeBreakpoint(breakpoint) {
  return {
    payload: {
      breakpoint
    },
    type: MQ_BREAKPOINT_CHANGED
  };
}


export function changeOrientation(orientation) {
  return {
    payload: {
      orientation
    },
    type: MQ_ORIENTATION_CHANGED
  };
}
