/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */


// =============================================================================
// IMPORTS
// =============================================================================

import KOBO_VARIABLES_DEFAULT, { BASIC_UNIT, BASIC_FONT_SIZE } from './../kobo/variables-default'

// =============================================================================
// DEFAULT VARIABLES
// =============================================================================

const VARIABLES = {
  ...KOBO_VARIABLES_DEFAULT,


  // ---------------------------------------------------------------------------
  // TYPOGRAPHY
  // ---------------------------------------------------------------------------

  fontFamily: {
    MONOSPACE: 'monospace',
    UI: 'NotoSansJP'
  },

  // ---------------------------------------------------------------------------
  // COMPONENTS
  // ---------------------------------------------------------------------------

  button: {
    ...KOBO_VARIABLES_DEFAULT.button,
    borderRadius: {
      textButton: `${BASIC_UNIT * 0.5}rem`
    }
  }
};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------


export default VARIABLES;
export {
  BASIC_FONT_SIZE,
  BASIC_UNIT
};
