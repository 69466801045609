/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 *
 * Mixins for the default theme
 */

import { createMixIns as createCommonMixIns } from "../common/mixins";


export function createMixIns(colors, variables, util) {
  const mixins = createCommonMixIns(colors, variables, util);
  const basicUnit = mixins.basicUnit;

  function boxShadow(offsetY = 1, blurRadius = 1.5, isOptional) {
    return isOptional
      ? "unset"
      : `0 ${basicUnit(offsetY * 0.6875)} ${basicUnit(blurRadius * 0.8333)} ${colors.black42}`;
  }

  return Object.freeze({
    ...mixins,

    boxShadow,

    pageNavButton: Object.freeze({
      backgroundColor: colors.icon
    }),

    tabBorder: function(height) {
      return {
        borderColor: colors.divider,
        borderRadius: basicUnit(variables.borderRadiusFactor * 0.5 * height),
        borderBottomStyle: 'solid',
        borderBottomWidth: 1
      };
    },

    tabBorderActive: Object.freeze({
      borderColor: colors.primary,
      borderBottomWidth: 2
    }),

    tabInactiveHover: Object.freeze({
      ':hover': {
        backgroundColor: colors.iconHover
      }
    }),

    zoomUIStylesNavBar: function( disabled ) {
      return {
        fill: disabled ? mixins.colorOverlay(colors.lightBackgroundUI, colors.lightIconDisabled) + " !important" : colors.lightIcon,
        backgroundColor: disabled ?
          mixins.colorOverlay(colors.lightBackgroundUI, colors.lightIconHover) + " !important"
          : mixins.colorOverlay(colors.lightBackgroundUI, colors.lightIconHover),

        ":hover": {
          backgroundColor: disabled ? colors.transparent : mixins.colorOverlay(colors.lightBackgroundUI, colors.lightIconSelected)
        },

        ":focus-visible": {
          backgroundColor: disabled ? colors.transparent : mixins.colorOverlay(colors.lightBackgroundUI, colors.lightIconSelected)
        }
      }
    }
  });
}
