/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// Tag for log output etc.
// NOTE: We need to apply the global tag [tolino] ourselves because we cannot
//       use logger service in this module (yet)
export const TAG = '[tolino] [service/service-locator]';

export const DEFAULT_ERROR_KEY = "Error";
export const LOCATOR_ERROR_CODES = Object.freeze({

  "NON_VALID_IDENTIFIER": {
    message: "function needs to be called with service name as a string",
    name: "NON_VALID_IDENTIFIER"
  },

  "MISSING_CONFIG": {
    message: "Missing service config",
    name: "MISSING_CONFIG"
  },
  
  [DEFAULT_ERROR_KEY]: {
    message: `Unexpected error in ${TAG}`,
    name: "Error"
  }

});
