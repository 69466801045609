import color from "./color-palette";
import KOBO_COLORS_DARK from "./../kobo/colors-dark";

/**
 * Kobo Japan theme matches Kobo theme (excl. primary colors)
 */
const COLORS_DARK = {
  ...KOBO_COLORS_DARK,

  primary:             color.koboJPRedDark,
  primaryDisabled:     color.white48,
  primaryHover:        color.koboJPRedDark08,
  primarySelected:     color.koboJPRedDark24,
  primaryPressed:      color.koboJPRedDark32,

  // Exceptions
  tabBackgroundActiveHover:  color.koboJPRedDark08         // color of dark/primaryHover
};

export default COLORS_DARK;
