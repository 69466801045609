/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

//for local development we cheat and add a cookie
if(BUNDLER_BUILD_MODE === 'development'){
  document.cookie = "; sessionId=sessionid=d00faffe-d00f-affe-d00f-affed00faffe";
}

// Tag for log output etc.
// NOTE: We need to apply the global tag [tolino] ourselves because we cannot
//       use logger service in this module (yet)
export const TAG = '[tolino] [service/session-service]';

export const SESSION_ERROR_CODES = Object.freeze({

  MISSING_FEATURE: {
    "message": "This user agent has no cookie store",
    "name": "Missing feature"
  },
  
  FAULTY_SESSION: {
    "message": "Cookie contains no session infos",
    "name": "FaultySession"
  },

  MISSING_SESSION: {
    "message": "User has no active session",
    "name": "MissingSession"
  },

  DEFAULT_ERROR_KEY: {
    message: "Unexpected error in kobo session service",
    name: "Error"
  }

});
