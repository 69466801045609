/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import koboColorsDefault from "./../kobo/colors-default";

// -----------------------------------------------------------------------------
// DEFAULT COLORS
// -----------------------------------------------------------------------------

/**
 * Kobo Japan theme matches Kobo theme (excl. primary colors)
 */
const COLORS_DEFAULT = {
  ...koboColorsDefault
};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default COLORS_DEFAULT;
