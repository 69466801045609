import color from "./color-palette";

const COLORS_LIGHT = {
  primary:                    color.koboRed,
  primaryDisabled:            color.koboRed38,
  primaryHover:               color.koboRed04,
  primarySelected:            color.koboRed08,
  primaryPressed:             color.koboRed16,

  icon:                       color.black,
  iconDisabled:               color.black42,
  iconHover:                  color.black04,
  iconSelected:               color.black12,
  iconPressed:                color.darkCharcoal16,
  iconFilled:                 color.koboRed,

  label:                      color.black65,
  divider:                    color.black12,
  textHeadline:               color.black,
  text:                       color.black,
  background:                 color.white,
  backgroundUI:               color.white,

  highlight:                  color.coolYellow,
  highlightSearch:            color.coolYellow,
  highlightAlpha:             color.platinumYellow,
  alert:                      color.pastelRed,

  // Exceptions
  iconActive:                 color.darkCharcoal,
  toggleActive:               color.darkCharcoal,
  tabsBackground:             color.transparent,
  tabBackground:              color.transparent,
  tabBackgroundHover:         color.black04,          // color of light/iconHover
  tabBackgroundActive:        color.transparent,
  tabBackgroundActiveHover:   color.koboRed04,        // color of light/primaryHover

  lightBackgroundUI:          color.white,            // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIcon:                  color.black,            // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconDisabled:          color.black42,          // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconHover:             color.black04,          // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconSelected:          color.black12,          // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconPressed:           color.darkCharcoal16,   // for theme independent iconButtons (Zoom UI -Floating -Navbar)

  iconDarkThemeHover:         color.white08,
  iconLightThemeHover:        color.black04
};

export default COLORS_LIGHT;
