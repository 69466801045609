import * as bookmark from "./bookmark/bookmark.service";
import * as dogear from "./dogear/dogear.service";
import * as drm from "./drm/drm.service";
import * as readContent from "./read-content/read-content.service";
import * as session from "./session/session.service";

export default [
  bookmark,
  dogear,
  drm,
  readContent,
  session
];
