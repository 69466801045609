import color from "./color-palette";
import KOBO_COLORS_CHARCOAL from "./../kobo/colors-charcoal";

/**
 * Kobo Japan theme matches Kobo theme (excl. primary colors)
 */
const COLORS_CHARCOAL = {
  ...KOBO_COLORS_CHARCOAL,

  primary:             color.koboJPRedDark,
  primaryDisabled:     color.white48,
  primaryHover:        color.koboJPRedDark08,
  primarySelected:     color.koboJPRedDark24,
  primaryPressed:      color.koboJPRedDark32
};

export default COLORS_CHARCOAL;