
/**
 * Returns the memoized function @func, caching the returned result for given arguments.
 * Requirement: @func must be deterministic, i.e. return the same result for a
 * defined combination of parameters.
 * 
 * @param {Function} func
 * @return {Function} @func with memoization
 */
export function memoize(func) {
  const context  = this;
  const cache    = {};
  const slice    = Array.prototype.slice;
  const refMap   = new WeakMap();
  let refCounter = 0;

  function createRef() {
    refCounter += 1;
    return "$$" + refCounter;
  }

  // replaces an function argument by a unique identifier, which is the argument
  // for primitive values or an identifier for referential values (objects or functions)
  function resolveRef(arg) {
    if (typeof arg !== "object" && typeof arg !== "function" || !arg) {
      return arg;
    }
    if (!refMap.has(arg)) {
      refMap.set(arg, createRef());
    }
    return refMap.get(arg);
  }

  return function(...args) {
    const key = slice.call(args.map(resolveRef)); // or JSON.stringify(args.map(resolveRef))
    return key in cache
      ? cache[key]
      : (cache[key] = func.apply(context, args)); // func(...args));
  };
}


const memoizedFunctions = new WeakMap();


export function callMemoized(func, ...args) {
  // create memoized function for @func if this is the first call for @func:
  if (!memoizedFunctions.has(func)) {
    memoizedFunctions.set(func, memoize(func));
  }
  
  const fnMemoized = memoizedFunctions.get(func);
  return fnMemoized(...args);
}