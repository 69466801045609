import COLORS_LIGHT from "./colors-light";

/**
 * The Sepia-theme matches the light-theme
 * excl. background & text of the previewreader
 * they are defined here:
 * - background: apps/kobo-previewreader/static/assets/styles/global.css
 * - text: apps/kobo-previewreader/static/assets/styles/epubreader.css
 */

const COLORS_SEPIA = {
  ...COLORS_LIGHT
};

export default COLORS_SEPIA;