/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// Tag for log output etc.
// NOTE: We need to apply the global tag [tolino] ourselves because we cannot
//       use logger service in this module (yet)
export const TAG = '[tolino] [service/drm-service]';

export const DRM_ERROR_CODES = Object.freeze({

  DEFAULT_ERROR_KEY: {
    message: "Unexpected error in kobo drm service",
    name: "Error"
  },

  FAILED_REQUEST: {
    message: "Unexpected error in kobo drm service",
    name: "FAILED_REQUEST"
  },

  FAULTY_PAYLOAD: {
    message: "Unexpected error in kobo drm service",
    name: "FAULTY_PAYLOAD"
  },

  NO_PRODUCT_ID: {
    message: "Unexpected error in kobo drm service",
    name: "NO_PRODUCT_ID"
  },

  NO_SESSION: {
    message: "Unexpected error in kobo drm service",
    name: "NO_SESSION"
  }

});
