/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 *
 * Utility functions around everything related to app theme handling
 */

// -----------------------------------------------------------------------------
// PUBLIC API
// -----------------------------------------------------------------------------

/**
 * Tests @themeName for the presence of the theme set identifier. If the identifier
 * is missing, the identifier from @oldThemeName is inserted into @themeName,
 * otherwise @themeName is returned unchanged.
 * Prerequisite: @oldThemeName is defined.
 *
 * @param {String} themeName Theme name, w/ or w/o set identifier, "theme-dark" or "theme-kobo-light"
 * @param {String} [oldThemeName] Current theme name, e.g. "theme-tolino-light"
 * @return {String} Resolved theme name with set identifier
 */
function resolveThemeName(themeName, oldThemeName) {
  // test @themeName for missing theme set identifier ("kobo", "tolino")
  const match = /^theme\-([^-]+)$/.exec(themeName);
  // determine current theme set identifier from @oldThemeName
  const prefix = oldThemeName?.match(/(.+)\-[^-]+$/);
  return match && prefix
    ? `${prefix[1]}-${match[1]}`
    : themeName;
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  resolveThemeName
};
