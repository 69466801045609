/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import {
  APP_LOCALE_CHANGED,
  APP_THEME_CHANGED,
  APP_URLPARAMS_ADDED
} from './types';


// -----------------------------------------------------------------------------
// ACTION CREATORS
// -----------------------------------------------------------------------------

export function changeLocale(locale) {
  return {
    payload: {
      locale
    },
    type: APP_LOCALE_CHANGED
  };
}

export function changeTheme(theme) {
  return {
    payload: {
      theme
    },
    type: APP_THEME_CHANGED
  };
}

export function addURLParams(urlParams) {
  return {
    payload: {
      urlParams
    },
    type: APP_URLPARAMS_ADDED
  }
}
