/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import CustomError from '@rakuten/common-error/CustomError';

// Internal dependencies
import { DOGEAR_ERROR_CODES, TAG} from "./dogear.constants";

// -----------------------------------------------------------------------------
// ERROR EXPORTS
// -----------------------------------------------------------------------------

export class DogearError extends CustomError {

  constructor(message, options) {
    options = Object.assign({
      type: 'DogearError',
      tag: TAG
    }, options);
    super(DOGEAR_ERROR_CODES[message] || DOGEAR_ERROR_CODES["ERROR"], options);
  }
};
