/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// SCHEMA DEFINITION
// -----------------------------------------------------------------------------

export default {
  id: 'schema/reducer/kobowebrx',
  type: 'object',
  properties: {
    locale: {
      enum: [
        'de-DE',
        'en-US',
        'es-ES',
        'fr-FR',
        'it-IT',
        'nl-NL',
        null
      ]
    },
    theme: {
      enum: [
        'theme-kobo-charcoal',
        'theme-kobo-dark',
        'theme-kobo-light',
        'theme-kobo-sepia',
        'theme-kobojp-charcoal',
        'theme-kobojp-dark',
        'theme-kobojp-light',
        'theme-kobojp-sepia',
        null
      ]
    },
    urlParams: {
      type: [ 'object', 'null' ],
      properties: {
        backref_url: { type: 'string' },
        end_of_book: { type: 'string' },
        engine: { enum: [ 'teng', 'ur' ] },
        epub_url: { type: 'string' },
        locale: { type: 'string' },
        purchase_url: { type: 'string' },
        theme: { type: 'string' }
      },
      additionalProperties: false
    }
  },
  required: [
    'locale',
    'theme'
  ],
  additionalProperties: false
};
