/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

export const TAG = '[service/common/tracking]';

export const TRACKING_ERROR_CODES = Object.freeze({

  NO_SESSION: {
    message: "User has no active session",
    name: "MissingId"
  },

  NO_PRODUCT_ID: {
    message: "No product id",
    name: "MissingPayload"
  },

  FAULTY_PAYLOAD: {
    message: "Payload error",
    name: "FaultyPayload"
  },

  FAILED_REQUEST: {
    message: "Unexpected error",
    name: "Error"
  }
});
