// =============================================================================
// IMPORTS
// =============================================================================

import StyleCache from "./styleCache";
import { callMemoized } from "../memoize";

// =============================================================================
// STYLE PROCESSING FOR CSS/WEB TARGET
// =============================================================================

const styleCache = new StyleCache();


const STYLESHEET_ID = "#core-styles";

const DEFAULT_UNIT = "px";

const hasNumericValues = {
  flex: true,
  flexGrow: true,
  flexShrink: true,
  fontWeight: true,  // values might be 100, 200, ... 900
  lineHeight: true,  // line height multiplier
  opacity: true,
  orphans: true,
  zIndex: true,
  zoom: true
}

const isDirectionalProp = {
  borderBottom: true,
  borderLeft: true,
  borderRight: true,
  borderTop: true,
  borderWidth: true,
  margin: true,
  padding: true
};


// translation of props which are available in RN styles but not defined in CSS
const reactNativeProps = {
  marginHorizontal:  [ "marginLeft", "marginRight" ],
  marginVertical:    [ "marginTop", "marginBottom" ],
  paddingHorizontal: [ "paddingLeft", "paddingRight" ],
  paddingVertical:   [ "paddingTop", "paddingBottom" ]
};


// @ToDo: apply or notify properties with different default values
// ┌───────────────┬───────────────┬──────────────────┐
// │               │ React Native  │       CSS        │
// ╞═══════════════╪═══════════════╪══════════════════╡
// │ boxSizing     │ "border-box"  │                  │
// ├───────────────┼───────────────┼──────────────────┤
// │ display       │ "flex"        │ "block"/"inline" │
// ├───────────────┼───────────────┼──────────────────┤
// │ flexDirection │ "column"      │ "row"            │
// ├───────────────┼───────────────┼──────────────────┤
// │ alignItems    │      ?        │                  │
// └───────────────┴───────────────┴──────────────────┘
// eslint-disable-next-line no-unused-vars
const flexContainerProps = [
  "flexDirection", "flexWrap", "flexFlow", "justifyContent", "alignItems", "alignContent"
];


const RE_MEDIA_QUERY = /^\s*@media\b(?:(only|not)?\s*([_a-z][_a-z0-9-]*)|(\([^\)]+\)))(?:\s*and\s*(.*))?$/i;

const isMediaQuery = (mediaQuery) =>
  RE_MEDIA_QUERY.test(mediaQuery);


const fnIdentity = (x) => x;

// split @value at whitespace characters except those in parentheses, e.g. rgb(...)
const splitWhiteSpaceList = (value) =>
  value.trim().split(/(?!\(.*)\s(?![^(]*?\))/);

const appendDefaultUnit = (value) =>
  isNaN(Number(value)) ? value : value + DEFAULT_UNIT;


function toCSSValue(value, propName) {
  if (typeof value === "number" && !hasNumericValues[propName]) {
    // append default unit to numeric length properties
    return value + DEFAULT_UNIT;
  }
  if (isDirectionalProp[propName]) {
    // append default unit to each directional value w/o unit,
    // e.g. "margin: 10 15% 20" -> "margin: 10px 15% 20px"
    return splitWhiteSpaceList(value).map(appendDefaultUnit).join(" ");
  }
  return value;
}


function hyphenate(str) {
  return str.replace(/([A-Z])/g, (char) => `-${char.toLowerCase()}`);
}


function convertProperty(styleObj, propName, value, transformProp = fnIdentity) {
  const cssValue = toCSSValue(value, propName);
  // is @propName a property specific to RN only (not available in CSS)?
  const propMapping = reactNativeProps[propName];

  if (propMapping) {
    propMapping.forEach((cssName) => styleObj[transformProp(cssName)] = cssValue);
  }
  else {
    styleObj[transformProp(propName)] = cssValue;
  }
}


function convertInlineStyle(style) {

  function convertInlineStyleObject(obj) {
    if (obj && typeof obj === "object") {
      Object.keys(obj).forEach((propName) =>
        convertProperty(obj, propName, style[propName])
      );
    }
    return obj;
  }

  return Array.isArray(style)
    ? style.map(convertInlineStyleObject)
    : convertInlineStyleObject(style);
}

/**
 *
 * @param {Object} jsStyles
 * @return {Object} converted @jsStyles being usable for React inline styles
 */
function getStyles(jsStyles) {
  for (let styleName in jsStyles) {
    const style = jsStyles[styleName];

    for (let propName in style) {
      const value = style[propName];
      // getStyles is used for inline styles (a HTML element's `style` attribute)
      // -> ignore pseudo-classes which are represented as objects
      if (typeof value === "object" && !Array.isArray(value)) {
        delete style[propName];
      }
      convertProperty(style, propName, value);
    }
  }
  // console.log("============== getStyles: %o", jsStyles);

  return jsStyles;
}


/**
 * Converts each key-value pair from @jsStyles into a list of CSS rules,
 * nested values (-> CSS pseudo classes) are unfolded to multiple CSS rules
 *
 * @param {Object} jsStyles Javascript style definitions
 * @param {String [scope] Prefix for the generated class names used as a kind of namespace
 * @return {Object] Object with the converted `rules` ansd the corresponding `classNames`
 */
function objectToCSSRules(jsStyles, scope = "") {
  const rules        = Object.create(null);
  const classNames   = Object.create(null);
  const mediaQueries = Object.create(null);

  const getClassName = scope
    ? (key) => `${scope}_${key}`
    : (key) => key;

  function convertRule(target, styleID, styles) {
    const className  = getClassName(styleID);
    const cachedRule = styleCache.get(className, styles);

    if (cachedRule?.suffix) {
      styleID = styleID + cachedRule.suffix;
    }

    const ruleObj = cachedRule?.styles || Object.create(null);

    const isMobile = Modernizr.mobile || Modernizr.touchscreen;
  
    for (let propName in styles) {
      const value = styles[propName];
      
      if (propName.startsWith('&')) {
        const regexp = /^&\s*(.*)/;
        const cmpName = propName.match(regexp)[1] || null;

        for (let innerPropName in value) {
          convertRule(target, `${styleID} .${cmpName}_${innerPropName}`, value[innerPropName]);
        }
      }
      else if (typeof value !== "object" || Array.isArray(value)) {
        if (!cachedRule) {
          convertProperty(ruleObj, propName, value, hyphenate);
        }
      }
      else if (isMediaQuery(propName)) {
        const mqRules = mediaQueries[propName] || (mediaQueries[propName] = Object.create(null));
        convertRule(mqRules, styleID, value);
      }
      else {
        if (isMobile && propName === ':hover') {
          continue;
        }
        // recursive call for nested objects (CSS pseudo classes), e.g. ":hover"
        convertRule(target, styleID + propName, value);
      }
    }

    // add the converted CSS `ruleObj` to the cache. `className` and `props` are
    // *both* used as cache key since a single component might have several instances
    // with different style variants, depending on the props.
    // -> `className` get a unique Suffix for different `props` instances
    const ref = cachedRule?.ref || styleCache.set(className, styles, ruleObj);
    target[`.${ref}`] = ruleObj;

    return ref;
  }

  for (let styleName in jsStyles) {
    // `styleName` is used as CSS class name, prefix by `scope`
    // @ToDo: this conversion rearranges the class names into
    //       alphabetical order of the keys -> CSS priority issues?
    classNames[styleName] = convertRule(rules, styleName, jsStyles[styleName]);
  }

  return {
    classNames,
    rules,
    mediaQueries
  };
}


// =============================================================================
// WEB-SPECIFIC
// AUXILIARY FUNCTIONS FOR CSS RULE MANAGEMENT
// =============================================================================

function createStyleSheet(selector, contentDoc) {
  const head = contentDoc.querySelector("head");
  if (!head) {
    console.warn("createStyleSheet: missing <head> element");
    return null;
  }
  const styleElem = contentDoc.createElement("style");
  styleElem.type = "text/css";

  const match = selector.match(/(#)([-\w]+)/)
    || selector.match(/(?:style\b)?\[([-\w]+)=["'](.*)["']\]/);

  if (match) {
    const attrName = match[1] === "#" ? "id" : match[1];
    styleElem.setAttribute(attrName, match[2]);
  }

  head.appendChild(styleElem);
  return styleElem.sheet;
}


function getStyleSheet(selector, contentDoc) {
  contentDoc = contentDoc || window.document;
  if (!contentDoc) {
    console.warn("getStyleSheet: missing content document");
    return null;
  }
  const styleElem = contentDoc.querySelector(selector);
  return (styleElem && styleElem.sheet) || createStyleSheet(selector, contentDoc);
}


/**
 * CSSStyleRule: {
 *   cssText: "selectorText: {}",
 *   selectorText: ".className > div:first-child",
 *   style: {   CSSStyleDeclaration
 *      length: 3
 *      "1":
 *   }
 * }
 *
function hasEqualStyles(cssRule, ruleObj) {

  function hasEqualProperties(cssStyle) {
    for (let i = 0, length = cssStyle.length; i < length; i++) {
      const propName = cssStyle.item(i);
      if (cssStyle[propName] !== ruleObj[propName]) {
        return false;
      }
    }
    return true;
  }

  return cssRule.style.length === Object.keys(ruleObj).length
    && hasEqualProperties(cssRule.style);
}
*/


/**
 * Returns the text representation for the list of CSS @descriptors
 * Note: to be used for CSS style rules only (CSS_RULE.STYLE_RULE)!
 *
 * @param {Object} descriptors CSS descriptors
 * @return {String} Text representation for the list of CSS @descriptors
 */
function getCSSText(descriptors) {
  return Object.keys(descriptors)
    .map((property) => `${property}: ${descriptors[property]};`)
    .join(" ");
}

/**
 * Returns the index of the rule with @selectorText in @cssRules.
 * Assertion: there should be only a single rule in the list of @cssRules
 * matching @selectorText.
 *
 * @param {CSSRuleList} cssRules List of `CSSStyleRule` objects with properties
 *               `cssText`, `selectorText`, and `style`
 * @param {String} selectorText
 * @return {Number} Index of the rule with @selectorText in @cssRules or -1 if there's no such rule
 */
function getRuleIndex(cssRules, selectorText) {
  return Array.prototype.findIndex.call(cssRules,
    (rule) => rule.selectorText === selectorText
  );
}


function getMediaRule(styleSheet, conditionText) {
  let index = Array.prototype.findIndex.call(styleSheet.cssRules,
    (rule) => rule.type === window.CSSRule.MEDIA_RULE && rule.cssText.startsWith(conditionText)
  );
  if (index === -1) {
    index = styleSheet.insertRule(conditionText + " {}");
  }
  return styleSheet.cssRules[index];
}


/**
 *
 * @ToDo: check if a set of rules can be removed when a component unmounts.
 * (there might be multiple instances of a component sharing the same rules)
 *
 * @param {Object} rules Map of CSS rules to be added, key is the CSS selector text
 * @param {CSSStyleSheet|CSSGroupingRule} styleSheet HTML styleSheet object or CSS grouping rule
 */
function addRules(rules, styleSheet) {
  if (!styleSheet) {
    console.warn("addRules: missing stylesheet or grouping rule");
    return;
  }

  const cssRules = styleSheet.cssRules;

  function insertRuleAtIndex(cssText, index) {
    try {
      styleSheet.insertRule(cssText, index);
    }
    catch(ex) {
      console.warn(`Error inserting CSS rule "${cssText}": ${ex.message}`);
    }
  }

  for (let selector in rules) {
    const index = getRuleIndex(cssRules, selector);
    if (index === -1) {
      const ruleText = `${selector} { ${getCSSText(rules[selector])} }`;
      // there's no rule with `selector` yet -> append a new rule at the end
      insertRuleAtIndex(ruleText, cssRules.length);
    }
  }
}


/**
 * Executes a "Garbage Collection" of unreferenced CSS style rules in @contentDoc
 *
 * @param {HTMLDocument} [contentDoc] The HTML document to which the garbage collection is to be applied
 */
function deleteUnreferencedRules(contentDoc = window.document) {
  const styleSheet = getStyleSheet(STYLESHEET_ID, contentDoc);
  const cssRules   = styleSheet.cssRules;

  for (let index = cssRules.length - 1; index >= 0; index--) {
    const rule = cssRules[index];
    if (rule.type === window.CSSRule.STYLE_RULE && contentDoc.querySelector(rule.selectorText) === null) {
      styleSheet.deleteRule(index);
    }
  }
}


/**
 *
 * @param {Object} jsStyles
 * @param {String} [scope] Prefix for the generated class names used as "namespace" scope
 * @param {HTMLDocument} [contentDoc] The HTML document in which the @jsStyles are to be created
 * @return {Object} Object with the generated `classNames` assigned to the keys of @jsStyles
 */
function createStyles(jsStyles, scope, contentDoc) {
  contentDoc = contentDoc || window.document;

  const css = objectToCSSRules(jsStyles, scope);
  const styleSheet = getStyleSheet(STYLESHEET_ID, contentDoc);

  // add the rules to the document's stylesheet
  addRules(css.rules, styleSheet);

  for (let mq in css.mediaQueries) {
    const styleSheet = getStyleSheet(`style[data-media-query="${mq}"]`, contentDoc);
    const mediaRule = getMediaRule(styleSheet, mq);
    const rules = css.mediaQueries[mq];

    addRules(rules, mediaRule);
  }
  // console.log("==============\nJS STYLES: %o\nCSS STYLES: %o", jsStyles, css);

  return css.classNames;
}


/**
 *
 * @param {String} scope Prefix for the generated class names used as "namespace" scope
 * @param {Function} fnCreateStyles Function returning a js style object
 * @param {...*} args Arguments passed to fnCreateStyles
 * @return {Object} Object with the generated list of class names for @args
 */
function createStylesMemoized(scope, fnCreateStyles, ...args) {
  const jsStyles = callMemoized(fnCreateStyles, ...args);
  return createStyles(jsStyles, scope);
}


/**
 * Combines two styles such that @style2 will override any styles in @style1.
 * If either style is falsy, the other one is returned without allocating an array,
 * saving allocations and maintaining reference equality for PureComponent checks.
 * @ToDo: compose two single styles (class name or object)
 * @param {Object} style1
 * @param {Object} style2
 * @return {Object|Array}
 */
function composeStyles(style1, style2) {
  if (typeof style1 === "object" && typeof style2 === "object") {
    return (style1 && style2)
      ? Object.assign(style1, style2)
      : (style1 || style2);
  }
  if (typeof style1 === "string") {
    // @style1 is a CSS class name
    // @ToDo: retrieve CSS class rule @style1 and overwrite/merge style declarations
  }
}


function flattenStyle(styles) {
  if (!Array.isArray(styles)) {
    return styles;
  }
  const flattenedStyle = {};

  styles.forEach((style) =>
    Object.assign(flattenedStyle, flattenStyle(style))
  );
  return flattenedStyle;
}


export default {
  composeStyles,
  convertInlineStyle,
  createStyles,
  createStylesMemoized,
  deleteUnreferencedRules,
  getStyles,
  flattenStyle,
  addRules,
  getStyleSheet,
  objectToCSSRules
};
