/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * * Utility functions around everything related to JavaScript strings
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

/**
 * Map of HTML character entity references and their hexadecimal code points,
 * used as lookup table to unescape entities by their actual UTF-8 symbol
 * or by their decimal character reference (to be accepted by an XML/XHTML DOM
 * parser).
 *
 * @see http://en.wikipedia.org/wiki/List_of_XML_and_HTML_character_entity_references
 *
 * @type {object}
 */
const ENTITY_CODE = {
  "quot": 0x0022, "amp": 0x0026, "apos": 0x0027, "lt": 0x003C, "gt": 0x003E, "nbsp": 0x00A0,
  "iexcl": 0x00A1, "cent": 0x00A2, "pound": 0x00A3, "curren": 0x00A4, "yen": 0x00A5, "brvbar": 0x00A6,
  "sect": 0x00A7, "uml": 0x00A8, "copy": 0x00A9, "ordf": 0x00AA, "laquo": 0x00AB, "not": 0x00AC,
  "shy": 0x00AD, "reg": 0x00AE, "macr": 0x00AF, "deg": 0x00B0, "plusmn": 0x00B1, "sup2": 0x00B2,
  "sup3": 0x00B3, "acute": 0x00B4, "micro": 0x00B5, "para": 0x00B6, "middot": 0x00B7, "cedil": 0x00B8,
  "sup1": 0x00B9, "ordm": 0x00BA, "raquo": 0x00BB, "frac14": 0x00BC, "frac12": 0x00BD, "frac34": 0x00BE,
  "iquest": 0x00BF, "Agrave": 0x00C0, "Aacute": 0x00C1, "Acirc": 0x00C2, "Atilde": 0x00C3, "Auml": 0x00C4,
  "Aring": 0x00C5, "AElig": 0x00C6, "Ccedil": 0x00C7, "Egrave": 0x00C8, "Eacute": 0x00C9, "Ecirc": 0x00CA,
  "Euml": 0x00CB, "Igrave": 0x00CC, "Iacute": 0x00CD, "Icirc": 0x00CE, "Iuml": 0x00CF, "ETH": 0x00D0,
  "Ntilde": 0x00D1, "Ograve": 0x00D2, "Oacute": 0x00D3, "Ocirc": 0x00D4, "Otilde": 0x00D5, "Ouml": 0x00D6,
  "times": 0x00D7, "Oslash": 0x00D8, "Ugrave": 0x00D9, "Uacute": 0x00DA, "Ucirc": 0x00DB, "Uuml": 0x00DC,
  "Yacute": 0x00DD, "THORN": 0x00DE, "szlig": 0x00DF, "agrave": 0x00E0, "aacute": 0x00E1, "acirc": 0x00E2,
  "atilde": 0x00E3, "auml": 0x00E4, "aring": 0x00E5, "aelig": 0x00E6, "ccedil": 0x00E7, "egrave": 0x00E8,
  "eacute": 0x00E9, "ecirc": 0x00EA, "euml": 0x00EB, "igrave": 0x00EC, "iacute": 0x00ED, "icirc": 0x00EE,
  "iuml": 0x00EF, "eth": 0x00F0, "ntilde": 0x00F1, "ograve": 0x00F2, "oacute": 0x00F3, "ocirc": 0x00F4,
  "otilde": 0x00F5, "ouml": 0x00F6, "divide": 0x00F7, "oslash": 0x00F8, "ugrave": 0x00F9, "uacute": 0x00FA,
  "ucirc": 0x00FB, "uuml": 0x00FC, "yacute": 0x00FD, "thorn": 0x00FE, "yuml": 0x00FF, "OElig": 0x0152,
  "oelig": 0x0153, "Scaron": 0x0160, "scaron": 0x0161, "Yuml": 0x0178, "fnof": 0x0192, "circ": 0x02C6,
  "tilde": 0x02DC, "Alpha": 0x0391, "Beta": 0x0392, "Gamma": 0x0393, "Delta": 0x0394, "Epsilon": 0x0395,
  "Zeta": 0x0396, "Eta": 0x0397, "Theta": 0x0398, "Iota": 0x0399, "Kappa": 0x039A, "Lambda": 0x039B,
  "Mu": 0x039C, "Nu": 0x039D, "Xi": 0x039E, "Omicron": 0x039F, "Pi": 0x03A0, "Rho": 0x03A1, "Sigma": 0x03A3,
  "Tau": 0x03A4, "Upsilon": 0x03A5, "Phi": 0x03A6, "Chi": 0x03A7, "Psi": 0x03A8, "Omega": 0x03A9,
  "alpha": 0x03B1, "beta": 0x03B2, "gamma": 0x03B3, "delta": 0x03B4, "epsilon": 0x03B5, "zeta": 0x03B6,
  "eta": 0x03B7, "theta": 0x03B8, "iota": 0x03B9, "kappa": 0x03BA, "lambda": 0x03BB, "mu": 0x03BC,
  "nu": 0x03BD, "xi": 0x03BE, "omicron": 0x03BF, "pi": 0x03C0, "rho": 0x03C1, "sigmaf": 0x03C2,
  "sigma": 0x03C3, "tau": 0x03C4, "upsilon": 0x03C5, "phi": 0x03C6, "chi": 0x03C7, "psi": 0x03C8,
  "omega": 0x03C9, "thetasym": 0x03D1, "upsih": 0x03D2, "piv": 0x03D6, "ensp": 0x2002, "emsp": 0x2003,
  "thinsp": 0x2009, "zwnj": 0x200C, "zwj": 0x200D, "lrm": 0x200E, "rlm": 0x200F, "ndash": 0x2013,
  "mdash": 0x2014, "lsquo": 0x2018, "rsquo": 0x2019, "sbquo": 0x201A, "ldquo": 0x201C, "rdquo": 0x201D,
  "bdquo": 0x201E, "dagger": 0x2020, "Dagger": 0x2021, "bull": 0x2022, "hellip": 0x2026, "permil": 0x2030,
  "prime": 0x2032, "Prime": 0x2033, "lsaquo": 0x2039, "rsaquo": 0x203A, "oline": 0x203E, "frasl": 0x2044,
  "euro": 0x20AC, "image": 0x2111, "weierp": 0x2118, "real": 0x211C, "trade": 0x2122, "alefsym": 0x2135,
  "larr": 0x2190, "uarr": 0x2191, "rarr": 0x2192, "darr": 0x2193, "harr": 0x2194, "crarr": 0x21B5,
  "lArr": 0x21D0, "uArr": 0x21D1, "rArr": 0x21D2, "dArr": 0x21D3, "hArr": 0x21D4, "forall": 0x2200,
  "part": 0x2202, "exist": 0x2203, "empty": 0x2205, "nabla": 0x2207, "isin": 0x2208, "notin": 0x2209,
  "ni": 0x220B, "prod": 0x220F, "sum": 0x2211, "minus": 0x2212, "lowast": 0x2217, "radic": 0x221A,
  "prop": 0x221D, "infin": 0x221E, "ang": 0x2220, "and": 0x2227, "or": 0x2228, "cap": 0x2229, "cup": 0x222A,
  "int": 0x222B, "there4": 0x2234, "sim": 0x223C, "cong": 0x2245, "asymp": 0x2248, "ne": 0x2260,
  "equiv": 0x2261, "le": 0x2264, "ge": 0x2265, "sub": 0x2282, "sup": 0x2283, "nsub": 0x2284, "sube": 0x2286,
  "supe": 0x2287, "oplus": 0x2295, "otimes": 0x2297, "perp": 0x22A5, "sdot": 0x22C5, "lceil": 0x2308,
  "rceil": 0x2309, "lfloor": 0x230A, "rfloor": 0x230B, "lang": 0x2329, "rang": 0x232A, "loz": 0x25CA,
  "spades": 0x2660, "clubs": 0x2663, "hearts": 0x2665, "diams": 0x2666
};

/**
 * Mapping table for Base64 encoded data (also for web-safe Base64 strings)
 *
 * @type {object}
 */
const BASE64_ENCODING = {
  "A":  0, "B":  1, "C":  2, "D":  3, "E":  4, "F" : 5, "G":  6, "H":  7, "I":  8,
  "J":  9, "K": 10, "L": 11, "M": 12, "N": 13, "O": 14, "P": 15, "Q": 16, "R": 17,
  "S" :18, "T": 19, "U": 20, "V": 21, "W": 22, "X": 23, "Y": 24, "Z": 25, "a": 26,
  "b": 27, "c": 28, "d": 29, "e": 30, "f" :31, "g": 32, "h": 33, "i": 34, "j": 35,
  "k": 36, "l": 37, "m": 38, "n": 39, "o": 40, "p": 41, "q": 42, "r": 43, "s" :44,
  "t": 45, "u": 46, "v": 47, "w": 48, "x": 49, "y": 50, "z": 51, "0": 52, "1": 53,
  "2": 54, "3": 55, "4": 56, "5" :57, "6": 58, "7": 59, "8": 60, "9": 61, "+": 62,
  "/": 63, "=": 64,

  // "-" and "_" may used as web-safe replacements for "+" and "/"
  "-": 62, "_": 63
};

/**
 * Map of entity names and code points, indicating which characters _must_
 * be escaped in XML/XHTML documents. Used as lookup table to _prevent_
 * unescaping reserved XML entities.
 *
 * @see http://stackoverflow.com/questions/7248958/which-are-the-html-and-xml-special-characters
 *
 * @type {object}
 */
const IS_XML_ENTITY = {
  "quot": true, "amp": true, "apos": true, "lt": true, "gt": true,
  "#x0022": true, "#x0026": true, "#x0027": true, "#x003C": true, "#x003E": true,
  "#34": true, "#38": true, "#39": true, "#60": true, "#62": true
};

/**
 * use a blank as replacement for unknown entity names, i.e. names not defined
 * in map ENTITY_CODE
 *
 * @type {number}
 */
const FALLBACK_CODE = ' '.charCodeAt(0);


// -----------------------------------------------------------------------------
// PRIVATE METHODS
// -----------------------------------------------------------------------------

/**
 * Parses an entity string and returns its decimal code point,
 * e.g. "amp" -> 38, or "#x00A0" -> 160
 *
 * @param {string} entity HTML entity identifier, e.g. "amp" or "#x0022"
 * @return {number} Decimal code point for @entity
 */
function getCodePoint(entity) {
  return entity[0] !== '#'
    ? ENTITY_CODE[entity] || FALLBACK_CODE
    : ( entity[1] === 'x'
        ? parseInt(entity.substr(2), 16)
        : parseInt(entity.substr(1), 10)
      );
}


/**
 * Replacer function to convert an HTML entity into the corresponding code point.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#Specifying_a_function_as_a_parameter
 *
 * @param {string} match Matched substring
 * @param {string} entity HTML entity identifier, e.g. "amp" or "#x0022"
 * @return {string} HTML code point for @entity
 */
function replaceByDecimalCodePoint(match, entity) {
  return '&#' + getCodePoint(entity) + ';';
}


/**
 * Replacer function to convert an HTML entity into the corresponding symbol.
 *
 * @param {string} match Matched substring
 * @param {string} entity HTML entity identifier, e.g. "amp" or "#x0022"
 * @return {string} Symbol for @entity
 */
function replaceByEntitySymbol(match, entity) {
  return IS_XML_ENTITY[entity]
    ? match // leave XML entity names unchanged!
    : String.fromCharCode(getCodePoint(entity));
}


/**
 * Replaces in @str the web-safe characters "-" and "_" by "+" and "/".
 * The native `atob` function throws a DOMException:
 * "Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded."
 * if a string contains "-" or "_".
 *
 * @param {String} str Base64-encoded string
 * @return {String} @str without web-safe replacement characters "-" and "_"
 */
const replaceWebSafeChars = (str) =>
  str.replace(/[-_]/g, (char) => char === '-' ? '+' : '/');


/**
 * Removes leading and trailing whitespaces or matching quotation marks from @str.
 *
 * @param {String} str Any text string
 * @return {String} @str w/o surrounding whitespaces or quotation marks
 */
function stripQuotes(str) {
  return str && str.replace(/^\s*(["']?)(.*)\1\s*$/, "$2");
}


// -----------------------------------------------------------------------------
// POLYFILLS
// -----------------------------------------------------------------------------

/**
 * Polyfill for TextEncoder API
 *
 * The TextEncoder interface takes a stream of code points as input and emits
 * a stream of UTF-8 bytes.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/TextEncoder
 * @return {function} TextEncoder instance or polyfill function
 */
const textEncoder = typeof TextEncoder === 'function'
  ? new TextEncoder()
  : {
      encode: function(str) {
        var length = str.length;
        var result = typeof Uint8Array === 'undefined'
          ? new Array(length * 1.5)
          : new Uint8Array(length * 3);
        var pos = -1;

        for (var point = 0, nextcode = 0, i = 0; i !== length; ) {
          point = str.charCodeAt(i), i += 1;
          if (point >= 0xD800 && point <= 0xDBFF) {
            if (i === length) {
              result[pos += 1] = 0xef;
              result[pos += 1] = 0xbf;
              result[pos += 1] = 0xbd;
              break;
            }
            // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
            nextcode = str.charCodeAt(i);
            if (nextcode >= 0xDC00 && nextcode <= 0xDFFF) {
              point = (point - 0xD800) * 0x400 + nextcode - 0xDC00 + 0x10000;
              i += 1;
              if (point > 0xffff) {
                result[pos += 1] = (0x1e << 3) | (point >>> 18);
                result[pos += 1] = (0x2  << 6) | ((point >>> 12) & 0x3f);
                result[pos += 1] = (0x2  << 6) | ((point >>> 6) & 0x3f);
                result[pos += 1] = (0x2  << 6) | (point & 0x3f);
                continue;
              }
            }
            else {
              result[pos += 1] = 0xef;
              result[pos += 1] = 0xbf;
              result[pos += 1] = 0xbd;
              continue;
            }
          }

          if (point <= 0x007f) {
            result[pos += 1] = (0x0 << 7) | point;
          }
          else if (point <= 0x07ff) {
            result[pos += 1] = (0x6 << 5) | (point >>> 6);
            result[pos += 1] = (0x2 << 6) | (point & 0x3f);
          }
          else {
            result[pos += 1] = (0xe <<4) | (point >>> 12);
            result[pos += 1] = (0x2 <<6) | ((point >>> 6) & 0x3f);
            result[pos += 1] = (0x2 <<6) | (point & 0x3f);
          }
        }

        if (typeof Uint8Array !== 'undefined') {
          return result.subarray(0, pos + 1);
        }

        result.length = pos + 1;
        return result;
      }
  };


/**
 * Replaces line-breaks and multiple whitespaces by single spaces,
 * removes leading and trailing whitespaces.
 *
 * @param {String} text Any text string
 * @return {String} @text w/o line-breaks and multiple consecutive whitespaces
 */
function compactString(text = "") {
  return text?.replace(/\s+/g, " ").trim();
}


/**
 * Decodes a Base64-encoded string
 *
 * @param {string} base64Str Base64-encoded string
 * @return {array} Array of byte values representing the decoded Base64 string
 */
const decodeString = typeof atob === 'function'
  ? base64Str =>
      atob(replaceWebSafeChars(base64Str)).split('').map((chr) => chr.charCodeAt(0))
  : base64Str => {
      var length = base64Str.length;
      var remainder = length % 4;

      if (remainder === 3) {
        throw new Error("The length of Base64-encoded data has to be a multiple of four (" + length + ")");
      }
      // patch incorrect length by appending missing "=" to the end
      if (remainder > 0) {
        base64Str += (remainder === 2) ? '==' : '=';
        length += remainder;
      }

      var result = [];
      for (var i = 0; i < length;) {
        var byte1 = BASE64_ENCODING[base64Str.charAt(i++)];
        var byte2 = BASE64_ENCODING[base64Str.charAt(i++)];
        var byte3 = BASE64_ENCODING[base64Str.charAt(i++)];
        var byte4 = BASE64_ENCODING[base64Str.charAt(i++)];

        if (byte1 === undefined || byte2 === undefined || byte3 === undefined || byte4 === undefined) {
          var pos = i - 4;
          throw new Error("Argument contains invalid symbols \"..." + base64Str.substr(pos, 4) + "...\" at position " + pos);
        }

        result.push((byte1 << 2) | (byte2 >> 4));

        if (byte3 !== 64) {
          result.push(((byte2 << 4) & 0xF0) | (byte3 >> 2));
          if (byte4 !== 64) {
            result.push(((byte3 << 6) & 0xC0) | byte4);
          }
        }
      }
      return result;
    };


/**
 * Returns the UTF-8 representation of @str
 *
 * @param {string} str String
 * @return {string} UTF-8 representation of @str
 */
const encodeUTF8 = textEncoder
  ? str => String.fromCharCode.apply(null, textEncoder.encode(str))
  : str => {
      str = str.replace(/\r\n/g, "\n");
      var UTF8 = "";

      for (var i = 0, length = str.length; i < length; i++) {
        var c = str.charCodeAt(i);

        if (c < 128) {
          UTF8 += String.fromCharCode(c);
        }
        else if((c > 127) && (c < 2048)) {
          UTF8 += String.fromCharCode((c >> 6) | 192);
          UTF8 += String.fromCharCode((c & 63) | 128);
        }
        else {
          UTF8 += String.fromCharCode((c >> 12) | 224);
          UTF8 += String.fromCharCode(((c >> 6) & 63) | 128);
          UTF8 += String.fromCharCode((c & 63) | 128);
        }
      }
      return UTF8;
    };


/**
 * Convert the first letter/char of a string into uppercase
 *
 * @param {string} str A string
 * @return {string} The converted string
 */
const firstToUpperCase = function firstToUpperCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};


/**
 * Returns the length (in bytes) of UTF-8 string @str using the TextEncoder API
 * or a fallback, if not available.
 *
 * @see https://developer.mozilla.org/de/docs/Web/API/TextEncoder and fallback
 * @see http://stackoverflow.com/questions/5515869/string-length-in-bytes-in-javascript
 *
 * @param {String} str UTF-8 String
 * @return {Integer} Byte length of @str
 */
const lengthInUTF8Bytes = textEncoder
  ? str => textEncoder.encode(str).length
  : str => {
      let matches = encodeURIComponent(str).match(/%[89ABab]/g);
      return matches ? str.length + matches.length : str.length;
    };


/**
 * Returns the length of the @node's text content in bytes, resolving UTF-8
 * byte sequences.
 *
 * @param {Node} node DOM node
 * @param {Integer} [charOffset] character offset
 * @return {Integer} Length of the @node's text content in bytes, or the length
 *                    until the first @charOffset characters
 */
function getByteOffset(node, charOffset) {
  if (!node) {
    return 0;
  }
  var text = charOffset === undefined ? node.textContent : node.textContent.substr(0, charOffset);
  return lengthInUTF8Bytes(text);
}


/**
 * Returns the character position in UTF-8 string @str for the given @byteOffset.
 *
 * @see https://en.wikipedia.org/wiki/UTF-8
 *
 * @param {String} str UTF-8 String
 * @param {Integer} byteOffset Byte offset
 * @return {Integer} Character offset in UTF-8 string @str for @byteOffset
 */
function getCharOffsetForUTF8Bytes(str, byteOffset) {
  for (var charOffset = 0; byteOffset > 0; charOffset++) {
    var codePoint = str.codePointAt(charOffset);
    var numBytes = codePoint < 0x0080 ? 1 : (codePoint < 0x0800 ? 2 : (codePoint <= 0xFFFF ? 3 : 4));

    byteOffset -= numBytes;
  }
  return charOffset;
}


/**
 * Replaces all occurences of HTML entities in @str by their
 * symbol (UTF-8 encoding) or by their decimal character reference,
 * e.g. "&euro;" -> "€" or "&#8364;" as decimal code point
 *
 * @param {string} str String containing HTML entities
 * @param {boolean} [asDecimalCharRef=false] If true, HTML entities are
 *                  replaced by decimal character references
 * @return {string} String w/o HTML entities, replaced by their
 *                  symbol or decimal character reference
 */
const unescapeEntities = function unescapeEntities(str, asDecimalCharRef = false) {
  // set the replacer function for either decimal char refs or symbols:
  let replaceEntity = asDecimalCharRef
    ? replaceByDecimalCodePoint
    : replaceByEntitySymbol;

  return str.replace(/&(.+?);/g, replaceEntity);
};

/**
 * Insert a camel and get a kebab
 *
 * localConfig -> local-config
 *
 * @param {string} s input string
 * @returns {string}
 */
function camelToKebab(s){
  return s && s.replace(/[A-Z]/g, x => x && `-${x.toLowerCase()}`);
}

/**
 * Insert a kebab and get a camel
 *
 * local-config -> localConfig
 *
 * @param {string} s input string
 * @returns {string}
 */
function kebabToCamel(s){
  return s && s.replace(/-./g, x=>x[1].toUpperCase());
}

/**
 * Returns a string with the number of bytes converted to the closest size unit
 *
 * @param {number} bytes
 * @returns {string}
 */
function formatSizeUnits(bytes) {
  let count = bytes;
  let unit = "bytes";

  if (bytes >= 1073741824) {
    count = (bytes / 1073741824).toFixed(2);
    unit = "GB";
  } else if (bytes >= 1048576) {
    count = (bytes / 1048576).toFixed(2);
    unit = "MB";
  } else if (bytes >= 1024) {
    count = (bytes / 1024).toFixed(2);
    unit = "KB";
  } else if (bytes > 1) {
    count = bytes;
  } else if (bytes === 1) {
    count = bytes;
    unit = "byte";
  } else {
    count = "0";
  }
  return {
    count,
    unit
  };
}

/**
 * Converts seconds into hours in the format of hh:mm:ss
 *
 * @param {number} seconds Seconds to convert to
 * @return {string} String in format hh:mm:ss
 */
function formatSecondsToHours(seconds = 0) {
  return new Date(seconds * 1000).toISOString().substring(11, 19);
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  compactString,
  camelToKebab,
  decodeString,
  encodeUTF8,
  firstToUpperCase,
  formatSecondsToHours,
  formatSizeUnits,
  getByteOffset,
  getCharOffsetForUTF8Bytes,
  kebabToCamel,
  lengthInUTF8Bytes,
  stripQuotes,
  textEncoder,
  unescapeEntities
};
