/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// Tag for log output etc.
// NOTE: We need to apply the global tag [tolino] ourselves because we cannot
//       use logger service in this module (yet)
export const TAG = '[service/google-analytics-service]';

export const GA_ERROR_CODES = Object.freeze({

  NO_SESSION: {
    message: "User has no active session",
    name: "NO_SESSION"
  },

  FAULTY_PAYLOAD: {
    "message": "Unexpected payload error",
    "name": "FaultyPayload"
  },

  FAILED_REQUEST: {
    "message": "Unexpected error in google analytics service",
    "name": "FailedRequest"
  }
});
