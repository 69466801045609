/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import React from 'react';
import { createRoot } from 'react-dom/client';
import { detectIncognito } from 'detectincognitojs';

// Side effect import to configure locator for this app
import '~services/locator/services.kobowebrx';

// Internal dependencies
import GLOBALS from '~/GLOBALS';
import { locateService } from '~services/locator/locator';
import AppContainer from './AppContainer';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// The ID of the DOM node under which our app will be renderd
const DOM_CONTAINER_ID = 'root';

(function () {
  detectIncognito().then(detectIncognitoResult => {
    return locateService(["logger", "localConfig"])
      .then(([logger, localConfig]) => {

        localConfig.populateLocalConfig(GLOBALS);
        const appConfig = localConfig.getLocalConfig();

        return locateService([
          'platform',
          'queryString',
          'featureFlag',
          'monitoring'
        ]).then(([platform, queryString, featureFlag, monitoring]) => {

          // Parse URL parameters
          queryString.parseQueryString();

          // Configure log levels and log output
          // NOTE: Setting the log level is probably the last point in the code where
          //       you should use the native `console` object methods. From then on,
          //       use `logger` service by importing it and using the `.log`,
          //       `.info` etc. methods.
          logger.reRouteErrorHandler();
          logger.setLogLevel(queryString.getQueryParameter('loglevel') || GLOBALS.LOGGING.DEFAULT_LEVEL);

          // Define the platform this app is running on
          // TODO: Platform information is very basic atm. Add relevant information
          platform.setPlatform(GLOBALS.PLATFORM.WEB);

          // ---------------------------------------------------------------------
          // GLOBAL KOBO OBJECT
          // ---------------------------------------------------------------------

          window.kobo = {
            appinfo: null,
            buildinfo: null,
            develop: {},
            deviceInfo: {
              isIncognitoMode: detectIncognitoResult.isPrivate
            }
          };

          window.kobo.appinfo = {
            APP_VERSION: GLOBALS.APPLICATION.VERSION,
            REACT_VERSION: React.version
          };
          window.kobo.buildinfo = GLOBALS.BUILDINFO;

          // Direct access to methods at runtime, e.g. via the developer console
          window.kobo.develop.setLogLevel = logger.setLogLevel;

          // Announce the supported features
          // FIXME: App features and service features have been mixed
          featureFlag.setApplicationFeatures(Object.values(appConfig.FEATURES));

          // Announce the disabled features
          const disabledFeatures = [
            appConfig.FEATURES.RX_FULL_TEXT_SEARCH
          ];
          featureFlag.setDisabledFeatures(disabledFeatures);

          // Initialize any monitoring, analytics etc.
          monitoring.setUp({
            applicationId: GLOBALS.MONITORING.DATADOG_APP_ID,
            clientToken: GLOBALS.MONITORING.DATADOG_CLIENT_TOKEN
          });

          // Import services that are used synchronously at a later point, similar to
          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#import_a_module_for_its_side_effects_only
          return locateService([
            'messageBus',
            'decompress'
          ]).then(() => {
            // ------------------------------------------------------------------
            // INIT/START
            // ------------------------------------------------------------------

            const rootDomElement = document.getElementById(DOM_CONTAINER_ID)
            const root = createRoot(rootDomElement);

            root.render(<AppContainer />);
          });
        });
      });
    });
})();
