import Color from "@rakuten/common-util/color";
import koboColorPalette from "./../kobo/color-palette";

// base colors as `Color` objects, used to derive colors with `lightness`,
// `saturation`, or `withAlpha`
const koboJPRed     = new Color(216, 0, 0);
const koboJPRedDark = new Color(248, 95, 95);

/**
 * Kobo Japan theme matches Kobo theme (excl. primary colors)
 */
const colorPalette = {
  ...koboColorPalette,

  koboJPRed:         koboJPRed.toString(),                       // #d80000   hsla(358, 100%, 39%, 1)    rgb(216, 0, 0)
  koboJPRed38:       koboJPRed.withAlpha(.38).toString(),     // #d8000061 hsla(358, 100%, 39%, 0.38) rgba(216, 0, 0, 0.38)
  koboJPRed16:       koboJPRed.withAlpha(.16).toString(),     // #d8000029 hsla(358, 100%, 39%, 0.16) rgba(216, 0, 0, 0.16)
  koboJPRed08:       koboJPRed.withAlpha(.08).toString(),     // #d8000014 hsla(358, 100%, 39%, 0.08) rgba(216, 0, 0, 0.08)
  koboJPRed04:       koboJPRed.withAlpha(.04).toString(),     // #d800000a hsla(358, 100%, 39%, 0.04) rgba(216, 0, 0, 0.04)

  koboJPRedDark:     koboJPRedDark.toString(),                   // #f85f5f   hsla(0, 92%, 67%, 1)    rgb(248, 95, 95)
  koboJPRedDark32:   koboJPRedDark.withAlpha(.32).toString(), // #f85f5f52 hsla(0, 92%, 67%, 0.32) rgba(248, 95, 95, 0.32)
  koboJPRedDark24:   koboJPRedDark.withAlpha(.24).toString(), // #f85f5f3d hsla(0, 92%, 67%, 0.24) rgba(248, 95, 95, 0.24)
  koboJPRedDark08:   koboJPRedDark.withAlpha(.08).toString()  // #f85f5f14 hsla(0, 92%, 67%, 0.08) rgb(248, 95, 95)
};

export default colorPalette;
