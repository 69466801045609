import COLORS_DARK from "./colors-dark";

/**
 * The Charcoal-theme matches the dark-theme
 * excl. background & text of the previewreader
 * they are defined here:
 * - background: apps/kobo-previewreader/static/assets/styles/global.css
 * - text: apps/kobo-previewreader/static/assets/styles/epubreader.css
 */

const COLORS_CHARCOAL = {
  ...COLORS_DARK
};

export default COLORS_CHARCOAL;