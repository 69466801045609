/**
 *  ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗  █████╗ ██████╗ ██████╗
 *  ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗██╔══██╗██╔══██╗██╔══██╗
 *     ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║███████║██████╔╝██████╔╝
 *     ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║██╔══██║██╔═══╝ ██╔═══╝
 *     ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝██║  ██║██║     ██║
 *     ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
 *
 * (c) Copyright 2018-present Rakuten Kobo Inc. (http://www.kobo.com)
 */

import isEqual from 'lodash/isEqual';

// =============================================================================
// WEB-SPECIFIC
// CACHING OF JS STYLES FOR CLASSNAMES
// Styles for instances of the same component can be different depending on the
// style definition and the properties
// -> the style cache keeps track of a style's different instances and returns
// a unique reference (suffix) for the class name.
// =============================================================================

function isEqualStyle(cacheItem) {
  return cacheItem.styleRef === this || isEqual(cacheItem.styleRef, this);
}


function StyleCache() {
  this.cache = Object.create(null);
  this.counter = 0;
}


/**
 * Returns the cached style object for @styleName and @styleRef.
 *
 * @param {String} styleName Key for a JS styles object
 * @param {Object} styleRef Object containing JS style (rule) definitions
 * @return {Object} Cached style object for @styleName and @styleRef
 */
StyleCache.prototype.get = function(styleName, styleRef) {
  const cachedStyles = this.cache[styleName];
  return cachedStyles?.find(isEqualStyle, styleRef);
}

/**
 * Adds @styleName, @styleRef, and @styles to the internal cache.
 * A unique suffix is appended to @styleName, if there is already a definition
 * with the same @styleName but different @styelRef in cache.
 *
 * @param {String} styleName Key for a JS styles object
 * @param {Object} styleRef Object containing JS style (rule) definitions
 * @param {Object} styles Converted @styleRef styles object
 * @return {String} Resolved @styleName
 */
StyleCache.prototype.set = function(styleName, styleRef, styles) {
  const cachedStyles = this.cache[styleName] || (this.cache[styleName] = []);

  let suffix = "";
  let ref = styleName;

  if (cachedStyles.length > 0) {
    this.counter += 1;
    suffix = "_" + this.counter.toString(36);

    // match @styleName with selector `match[1]` and optional pseudo class `match[2]`
    const match = styleName.match(/^([^:]+)(.*)?$/);
    ref = match[1] + suffix + (match[2] || "");
  }

  cachedStyles.push({ ref, suffix, styleRef, styles });

  return ref;
}


export default StyleCache;
