/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2022-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import includes from 'lodash/includes';

// Internal dependencies
import FetchServiceError from './fetch.error';
import {
  FETCH_ERROR_CODES,
  HEADERS,
  TAG,
  XHR_REQUEST_METHODS,
  XHR_RESPONSE_TYPES
} from "./fetch.constants";

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Error texts
const ERR_NOT_SUPPORTED = `${TAG} Range requests are not supported`;
const ERR_NO_URL = `${TAG} No URL provided to fetch`;

// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["fetch", ["logger"], createFetchService];

// -----------------------------------------------------------------------------
// FETCH SERVICE
// -----------------------------------------------------------------------------


/**
 * Removes/Cleans up the @xhr's event listeners
 *
 * @param {XMLHttpRequest} xhr XMLHttpRequest instance
 */
function cleanUpRequest(xhr) {
  delete xhr.onabort;
  delete xhr.onprogress;
  delete xhr.onreadystatechange;
  delete xhr.ontimeout;
  delete xhr.onerror;
  delete xhr.onload;
}


/**
 * Returns the content length [bytes] from the @xhr response headers. If the
 * response status is 206 ("Partial content"), then the @xhr should expose a
 * "content-range" response header matching
 * "<unit> <range-start>-<range-end>/<size>", e.g. "bytes 1-100/1764739".
 * Otherwise the value of the "content-length" response header is returned.
 *
 * @param {XMLHttpRequest} xhr The XMLHttpRequest object
 * @return {Number} The content length [bytes] from the @xhr response headers
 */
function getContentLength(xhr) {
  if (xhr.status === 206) {
    const matchRange = /\d+-\d+\/(\d+)/.exec(xhr.getResponseHeader('content-range'));
    if (matchRange && matchRange[1]) {
      return parseInt(matchRange[1], 10);
    }
  }

  return parseInt(xhr.getResponseHeader('content-length') || 0, 10);
}

/**
 * Factory function which creates a service with given
 * dependencies
 */
export function createFetchService(logger) {

  // ---------------------------------------------------------------------------
  // PRIVATE FUNCTIONS
  // ---------------------------------------------------------------------------

  /**
   * Request data from a server in an asynchronous way
   *
   * Sends a request to the provided URL with the provided configuration. This
   * method should function as one stop solution for all requests. Convenience
   * methods may be defined, but in the end all those should use this method.
   *
   * // TODO: XMLHttpRequest cannot deal with AbortController, so we need to
   *          find another mechanism to cancel/abort requests.
   *
   * @param {object} config Configuration for the request
   * @param {string} config.url The URL to load data from
   * @param {object} [config.options] Options for the request
   * @param {string} [config.options.method] Request method, i.e. GET, POST etc.
   * @param {object} [config.options.headers] Headers to add to the request
   * @param {string} [config.options.responseType] What type of data to get
   * @param {number} [config.timeout] Maximum time the request should last
   * @param {object} [config.data] Data to send to the URL
   * @param {function} [config.progressHandler] Called during the progression of the request
   * @return {Promise<Response, Error>} The promise to fetch the URL
   */
  const fetch = function fetch(config = {}) {

    return new Promise((resolve, reject) => {
      const {
        url = null,
        options = {},
        timeout = null,
        data = null,
        progressHandler = null
      } = config;

      // Let's check that we have a URL to load resources from
      if (url === null) {
        reject(new (FETCH_ERROR_CODES.NO_URL_PROVIDED, {
          details: { config }
        }));
        return;
      }

      // Let's check that the responseType is known to fetch service
      if (options.responseType && !includes(XHR_RESPONSE_TYPES, options.responseType)) {
        reject(new FetchServiceError(FETCH_ERROR_CODES.UNKNOWN_RESPONSE_TYPE, {
          details: { config }
        }));
        return;
      }

      // Request options and headers
      options.method = options.method || XHR_REQUEST_METHODS.GET;
      options.responseType = options.responseType || XHR_RESPONSE_TYPES.JSON;
      options.headers = {
        ...HEADERS[options.responseType],
        ...options.headers
      };

      const xhr = new XMLHttpRequest();
      xhr.open(options.method, url, true);
      xhr.responseType = options.responseType;

      /*
       * The XMLHttpRequest.withCredentials property is a boolean value
       * that indicates whether or not cross-site Access-Control
       * requests should be made using credentials such as cookies, authorization
       * headers or TLS client certificates.
       * Setting withCredentials has no effect on same-origin requests.
       * @see https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
       */
      if(options.credentials === 'include'){
        xhr.withCredentials = true;
      }

      Object.entries(options.headers).forEach(([header, value]) => {
        xhr.setRequestHeader(header, value);
      });

      if (timeout) {
        xhr.timeout = timeout;
      }

      if (progressHandler) {
        xhr.onprogress = function onXHRProgress(e) {
          progressHandler(e.loaded, e.total);
        };
      }

      xhr.onerror = function onXHRError() {
        reject(new FetchServiceError(FETCH_ERROR_CODES.COMMON_ERROR, {
          details: { xhr, config }
        }));
      };

      xhr.onabort = function onXHRAbort() {
        reject(new FetchServiceError(FETCH_ERROR_CODES.ABORT, {
          details: { xhr, config }
        }));
      };

      xhr.ontimeout = function onXHRTimeout() {
        reject(new FetchServiceError(FETCH_ERROR_CODES.TIMEOUT, {
          details: { xhr, config }
        }));
      };

      xhr.onload = function onXHRLoad() {
        if (xhr.status >= 200 && xhr.status <= 399) {
          // Request was successful
          logger.info(`${TAG} successfully requested resource from ${url}`);
          resolve(xhr.response);
        } else if (xhr.status >= 400 && xhr.status <= 499) {
          // Seems like we sent a malformed request
          reject(new FetchServiceError(FETCH_ERROR_CODES.BAD_REQUEST, {
            details: { xhr, config, status: xhr.status }
          }));
        } else if (xhr.status >= 500) {
          // Server has problems fulfilling our request
          reject(new FetchServiceError(FETCH_ERROR_CODES.SERVER_ERROR, {
            details: { xhr, config, status: xhr.status }
          }));
        }
      };

      xhr.send(data);
    });
  };

  /**
   *
   * @param {*} config
   * @returns
   */
  function fetchAsArrayBuffer(config) {
    config.options = {
      ...(config.options || {}),
      responseType: XHR_RESPONSE_TYPES.ARRAYBUFFER
    };
    return fetch(config);
  };


  /**
   * Sends a "HEAD" request to @url to receive the total size [bytes] of the
   * resource.
   *
   * @param {String} url
   * @param {Number} [timeout=2000]
   * @return {Promise} Promise resolved with the size of the resource specified by @url
   */
  function fetchSize(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('HEAD', url, true);

      xhr.onload = xhr.onreadystatechange = function (event) {
        if (xhr.status >= 400) {
          return xhr.onerror();
        }

        const contentLength = getContentLength(xhr) || event.total;
        if (contentLength === undefined) {
          cleanUpRequest(xhr);
          return reject(new Error(ERR_NOT_SUPPORTED));
        }
        if (xhr.readyState === xhr.DONE) {
          cleanUpRequest(xhr);
          resolve(contentLength);
        }
      };

      xhr.onerror = function () {
        cleanUpRequest(xhr);
        reject(new Error(`${TAG} Error fetching URL "${url}": ${xhr.statusText} ${xhr.status}`));
      };

      xhr.send();
    });
  }


  /**
   * Fetches the binary data from the given @url for the byte range @start .. @end
   * into an arraybuffer, returning a promise resolved with with an object containing
   * the response status and the arraybuffer. The Range HTTP request header indicates
   * the part of a document that the server should return.
   * If either @start or @end are not defined, the complete file will be fetched from
   * @url without a range specified.
   * For range requests there are three relevant response status codes:
   *   • 206 (Partial Content) - The server sent back range(s)
   *   • 416 (Range Not Satisfiable) - Invalid range(s)
   *   • 200 (OK) - The server ignored the range header and returned the whole document
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Range_requests
   *
   * @param {String} url
   * @param {Number} [start] Start offset of the requested range
   * @param {Number} [end] End offset of the requested range
   * @return {Promise} Promise resolved with an object containing the response status and buffer
   */
  function fetchByteRange(url, start, end) {

    return new Promise((resolve, reject) => {
      if (!url) {
        return reject(new Error(ERR_NO_URL));
      }

      if (start > end) {
        return resolve({ status: 416 });  // "Range Not Satisfiable"
      }

      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'arraybuffer';

      if (start !== undefined && end !== undefined) {
        xhr.setRequestHeader('Range', `bytes=${start}-${end}`);
      }

      // In Chromium based browsers, range requests should not be cached
      // see https://stackoverflow.com/questions/53259737/content-range-working-in-safari-but-not-in-chrome
      // FIXME: Make caching work in Chromium based browsers
      xhr.setRequestHeader('Cache-Control', "no-cache, no-store, max-age=0");

      xhr.onload = function () {
        cleanUpRequest(xhr);

        if( xhr.status > 399){
          return reject(new Error(ERR_NOT_SUPPORTED));
        }

        resolve({
          status: xhr.status,
          buffer: xhr.response
        });
      };

      xhr.onerror = function () {
        cleanUpRequest(xhr);
        reject(new Error(ERR_NOT_SUPPORTED));
      };

      xhr.send();
    });
  }

  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    ERR_NOT_SUPPORTED,
    FetchServiceError,
    fetch,
    fetchAsArrayBuffer,
    fetchByteRange,
    fetchSize
  };

}
