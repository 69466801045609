/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */


// -----------------------------------------------------------------------------
// CONFIGURTATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[service/local-config]';


// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["localConfig", ["logger"], createLocalConfigService];

// -----------------------------------------------------------------------------
// LOCAL CONFIG SERVICE
// -----------------------------------------------------------------------------

export function createLocalConfigService(logger) {

  // Reference to the currently executed app's configuration
  let localConfig = null;


  // ---------------------------------------------------------------------------
  // PRIVATE METHODS
  // ---------------------------------------------------------------------------

  /**
   * Store the local config, i.e. the apps global constants etc., for later usage
   *
   * @param {object} config Application's config/globals
   */
  const populateLocalConfig = config => {
    logger.info(`${TAG} Populating local config`, config);
    localConfig = config;
  };


  /**
   * Retrieves the currently stored local config
   *
   * @returns {object|null} The currently stored local config
   */
  const getLocalConfig = () => localConfig;


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    getLocalConfig,
    populateLocalConfig
  };

}
