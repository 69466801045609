/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import ThemeContextProvider from './ThemeContext.shared';


// -----------------------------------------------------------------------------
// THEME CONTEXT PROVIDER FOR WEB
// -----------------------------------------------------------------------------

class ThemeContextProviderWeb extends ThemeContextProvider {

  constructor(props) {
    super(props);

    // Initially set the background color of the application
    this.updateApplicationBackground({ themeName: this.state.themeName });
  }


  /**
   * Helper method to apply theme colors to the background of the application,
   * i.e. the <body> element for web apps. By setting the propper background
   * color, we prevent accidental flashes of the wrong background color, e.g.
   * if a dark theme is selected and a white background is set and the user
   * resizes the window.
   *
   * @param {string} themeName Name of the new theme
   */
  updateApplicationBackground = ({ themeName }) => {
    const body = document.body;
    body.classList.forEach(className => {
      if (/^theme\-(tolino|kobo|kobojp)\-\w+$/.test(className)) {
        body.classList.remove(className);
      }
    });
    body.classList.add(themeName);
  }
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export * from './ThemeContext.shared';
export default ThemeContextProviderWeb;
