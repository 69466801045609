/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 *
 * Mixins for the default theme
 */

import { createMixIns as createCommonMixIns } from "../common/mixins";


export function createMixIns(colors, variables, utils) {
  const mixins = createCommonMixIns(colors, variables, utils);
  const basicUnit = mixins.basicUnit;

  function boxShadow(offsetY = 1, blurRadius = 1.5) {
    return `
      0 ${basicUnit(offsetY)} ${basicUnit(blurRadius)} ${colors.black15},
      0 ${basicUnit(offsetY * 0.66)} ${basicUnit(blurRadius * 1.25)} ${colors.black12},
      0 ${basicUnit(offsetY * 0.66)} ${basicUnit(blurRadius * 0.6)} ${colors.black20}
    `;
  }

  return Object.freeze({
    ...mixins,

    boxShadow,

    pageNavButton: Object.freeze({
      borderColor: colors.whiteWarm,
      borderStyle: "solid",
      borderWidth: 1,
      boxShadow: boxShadow(0.125, 0.5)
    }),

    tabBorder: function(height) {
      return {
        borderColor: colors.transparent,
        borderRadius: basicUnit(variables.borderRadiusFactor * 0.5 * height),
        borderStyle: 'solid',
        borderWidth: 1
      };
    },

    tabBorderActive: Object.freeze({
      borderColor: colors.primary
    }),

    tabInactiveHover: Object.freeze({
      ':hover': {
        backgroundColor: mixins.colorOverlay(colors.divider, colors.iconHover)
      }
    }),

    zoomUIStylesNavBar: function( disabled ) {
      return {
        fill: disabled ? mixins.colorOverlay(colors.lightBackgroundUI, colors.lightIconDisabled) + " !important" : colors.themeBackground.light,
        backgroundColor: disabled ? mixins.colorOverlay(colors.lightBackgroundUI, colors.lightIconHover) + " !important" : colors.lightPrimary,

        ":hover": {
          backgroundColor: disabled ? colors.transparent : mixins.colorOverlay(colors.lightPrimary, colors.lightIconHover)
        },

        ":focus-visible": {
          backgroundColor: disabled ? colors.transparent : mixins.colorOverlay(colors.lightPrimary, colors.lightIconHover)
        }
      }
    }
  });

}
