/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import { batch } from 'react-redux'
import isEqual from 'lodash/isEqual';

// Internal dependencies
import logger from '@rakuten/services-common';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[redux/util]';

// -----------------------------------------------------------------------------
// UTILITIES
// -----------------------------------------------------------------------------

/**
 * Batch dispatching of the provided @type with the provided @payload. Each
 * combination of @type and @payload is considered a dispatchable action. The
 * collection of all actions is dispatched with a timeout of
 * config.REDUX.BATCH_DISPATCH_TIMEOUT, e.g. 20ms, after the initial call.
 *
 * @param {object} store The Redux store for which to dispatch the actions
 * @param {string} type Name/Type of the action to dispatch
 * @param {object} payload The payload of the action
 */
function batchDispatch(store, type, payload, appConfig) {

  const actions = batchDispatch.actions ||
    (batchDispatch.actions = []);
  actions.push({
    type, payload
  });

  if (batchDispatch.timerID) {
    return;
  }

  batchDispatch.timerID = setTimeout(() => {

    logger.group(`${TAG} Batch dispatching the following actions:`);
    logger.table(actions);
    logger.groupEnd();
    batch(
      () => actions.forEach(store.dispatch)
    );
    actions.length = 0;
    batchDispatch.timerID = null;


  }, appConfig.REDUX.BATCH_DISPATCH_TIMEOUT);
};

/* The redux-toolkit maintainers refuse to add a way to disable immer.js for
 * specific reducers, therefore we need to create an escape hatch by ourselves.
 * Immer.js needs to be disabled in certain cases for performance reasons.
 * Link: https://github.com/reduxjs/redux-toolkit/issues/242
 * @see https://gist.github.com/romgrk/bb16d7b8d3827481d04eb535e8d1bc74
 */

/** Add reducers without immer.js to a redux-toolkit slice */
function addRawReducers(slice, reducers) {

  const originalReducer = slice.reducer
  const actionMap =
    Object.fromEntries(
      Object.entries(reducers)
        .map(([name, fn]) => [`${slice.name}/${name}`, fn]))

  slice.reducer = (state, action) => {
    const fn = actionMap[action.type]
    if (fn)
      return fn(state, action)
    return originalReducer(state, action)
  }

  const actionCreators =
    Object.fromEntries(
      Object.entries(reducers)
        .map(([name]) =>
          [name, (payload) => ({ type: `${slice.name}/${name}`, payload })]))

  return actionCreators
}

/**
 * Immutability helper to update @state recursively with @updates returning the
 * updated @state if @updates were applied, otherwise the unmodified @state is
 * returned.
 * A modified/mutate state is returned only if there are changes detected!
 * Note: Modification of a @state of type *array* is applied by replacement if
 * @update is not deeply equal (i.e. there are no single insertions or deletes).
 *
 * Important: updateState does _not_ handle delete operations on objects
 * (only replacements)
 *
 * @example:
 *   const state = {
 *     stateItemFoo: [1, 2, 3],
 *     stateItemBar: {},
 *     sortCriteria: { titles: { isDescending: false, key: "recent" }}
 *   };
 *   updateState(state, { sortCriteria: { titles: { key: "titles" }}})
 *   -> Result: new object ref representing `state` containing new object refs
 *      `sortCriteria` and `titles` with key "recent" replaced by "titles".
 *
 * @param {*} state State object or any sub state
 * @param {Object} updates State fragment with keys/values to be updated.
 *                 Only the "path" to the updated values is needed.
 * @return {Object} @state with @updates applied
 */
function updateState(state, updates) {
  if (state instanceof Array) {
    // perform a deep compare if @state is an array
    return isEqual(state, updates) ? state : updates;
  }
  if (state === null || updates === null || typeof state !== "object" || typeof updates !== "object") {
    // terminate if @state or @updates are empty or if @state or @updates are not an object
    return state === updates ? state : updates;
  }
  const updatedState = {};
  let hasUpdates = false;

  // iterate recursively over @updates to check for changes and apply the updated sub state
  for (let key in updates) {
    const updatedValue = updateState(state[key], updates[key]);
    if (updatedValue !== state[key]) {
      updatedState[key] = updatedValue;
      hasUpdates = true;
    }
  }

  return hasUpdates
    ? Object.assign({}, state, updatedState)
    : state;
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  addRawReducers,
  batchDispatch,
  updateState
};
