/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */


// =============================================================================
// IMPORTS
// =============================================================================

import COMMON_VARIABLES_DEFAULT, { BASIC_UNIT, BASIC_FONT_SIZE } from './../common/variables-default'


// =============================================================================
// DEFAULT VARIABLES
// =============================================================================

const VARIABLES = {
  ...COMMON_VARIABLES_DEFAULT,


  // ---------------------------------------------------------------------------
  // BASIS
  // ---------------------------------------------------------------------------

  borderRadiusFactor: 1,

  // ---------------------------------------------------------------------------
  // TYPOGRAPHY
  // ---------------------------------------------------------------------------

  fontFamily: {
    MONOSPACE: 'monospace',
    UI: 'Bariol'
  },

  fontSize: {
    caption: `${BASIC_UNIT * 2.25}rem`,
    header1: `${BASIC_UNIT * 3}rem`,
    header2: `${BASIC_UNIT * 2.75}rem`,
    monospace: `${BASIC_UNIT * 1.25}rem`,
    text: `${BASIC_UNIT * 2.25}rem`,
    textLarge: `${BASIC_UNIT * 2.5}rem`,
    textSmall: `${BASIC_UNIT * 1.875}rem`,
    textXSmall: `${BASIC_UNIT * 1.25}rem`,
    title: `${BASIC_UNIT * 3.5}rem`
  },

  lineHeight: 1.5,


  // ---------------------------------------------------------------------------
  // COMPONENTS
  // ---------------------------------------------------------------------------

  // The values listed here in the COMPONENTS section might
  //  a) be used by other components for calculation.
  //  b) be customized/different for other themes.

  button: {
    borderRadius: {
      xsmall: `${BASIC_UNIT * 2}rem`,
      small: `${BASIC_UNIT * 2.25}rem`,
      medium: `${BASIC_UNIT * 3}rem`,
      large: `${BASIC_UNIT * 4}rem`,
      xlarge: `${BASIC_UNIT * 6}rem`,
      textButton: `${BASIC_UNIT * 3.75 }rem`
    },
    padding: {
      xsmall: `${BASIC_UNIT * 2}rem`,
      small: `${BASIC_UNIT * 2}rem`,
      medium: `${BASIC_UNIT * 2}rem`,
      large: `${BASIC_UNIT * 3}rem`,
      xlarge: `${BASIC_UNIT * 3}rem`,
      textButtonPaddingSmall: `${BASIC_UNIT * 1}rem`,
      textButtonPaddingMedium: `${BASIC_UNIT * 1.5}rem`,
      textButtonPaddingLarge: `${BASIC_UNIT * 2}rem`
    },
    size: {
      xsmall: `${BASIC_UNIT * 4}rem`,
      small: `${BASIC_UNIT * 4.5}rem`,
      medium: `${BASIC_UNIT * 6}rem`,
      large: `${BASIC_UNIT * 8}rem`,
      xlarge: `${BASIC_UNIT * 10}rem`,
      heightTextButtonTouch: `${BASIC_UNIT * 6}rem`,
      heightTextButton: `${BASIC_UNIT * 5}rem`,
      heightTextButtonIcon: `${BASIC_UNIT * 3}rem`,
      widthTextButtonIcon: `${BASIC_UNIT * 3}rem`
    },
    iconButtonSize: {
      xsmall: `${BASIC_UNIT * 4}rem`,
      small: `${BASIC_UNIT * 4}rem`,
      medium: `${BASIC_UNIT * 5}rem`,
      xlarge: `${BASIC_UNIT * 6}rem`,
      large: `${BASIC_UNIT * 6}rem`
    }
  }
};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------


export default VARIABLES;
export {
  BASIC_FONT_SIZE,
  BASIC_UNIT
};
