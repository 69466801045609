import color from "./color-palette";
import KOBO_COLORS_SEPIA from "./../kobo/colors-sepia";

/**
 * Kobo Japan theme matches Kobo theme (excl. primary colors)
 */
const COLORS_SEPIA = {
  ...KOBO_COLORS_SEPIA,

  primary:             color.koboJPRed,
  primaryDisabled:     color.koboJPRed38,
  primaryHover:        color.koboJPRed04,
  primarySelected:     color.koboJPRed08,
  primaryPressed:      color.koboJPRed16
};

export default COLORS_SEPIA;
