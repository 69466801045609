/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 *
 * Utility functions for the tolino theme
 */

export function createUtils(colors, variables) {

  return Object.freeze({

    /**
     * Uses the themes basic font size to calculate a rem value based
     * on the provided pixel value
     *
     * @param {number} pixel A pixel value to convert
     * @returns {number} The calculated rem value
     */
    convertPixelToREM: function(pixel) {
      return pixel / variables._BASIC_FONT_SIZE;
    },


    /**
     * Uses the themes basic font size to calculate a pixel value based
     * on the provided rem value.
     *
     * @param {number} rem A rem value to convert
     * @returns {number} The calculated pixel value
     */
    convertREMToPixel: function(rem) {
      return rem * variables._BASIC_FONT_SIZE;
    }

  });

};
