/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2022-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[service/platform]';

export const config = [ "platform", [ "localConfig", "logger" ], createPlatformService];


// -----------------------------------------------------------------------------
// PLATFORM SERVICE
// -----------------------------------------------------------------------------

/**
 * Factory function which creates a service with given
 * dependencies
 */
export function createPlatformService(localConfig, logger) {

  const appConfig = localConfig.getLocalConfig();

  // Reference to the current platform.
  let currentPlatform = null;

  /**
   * Map of platform identifiers and their regular expressions containing one
   * capturing group to identify the platform version or platform sub identifier
   *
   * @type {object}
   */
  const RE_PLATFORM = {
    'tolino' : /tolino (shine\s*\d?|visi[oó]n\s*\d?|page\s*\d?|epos\s*\d?)|imx50_rdp/,
    'android': /Android\s+([\d.]+)/i,
    'ios'    : /(?:iPhone|iPad|iPod).*OS\s+([\d_.]+)/i,
    'macos'  : /Mac OS X 10[_.]([\d_.]+)/i,
    'windows': /Windows\s+([^;])/i
  };


  // ---------------------------------------------------------------------------
  // PRIVATE METHODS
  // ---------------------------------------------------------------------------

  /**
   * Re-export all available platforms
   *
   * @return {object} Platform identifiers
   */
  const platforms = appConfig?.PLATFORM;


  /**
   * Store the name of the platform the app currently is executed on.
   *
   * @param {string} platform The platform the app runs on
   */
  const setPlatform = platform => {
    if (Object.values(platforms).indexOf(platform) === -1) {
      logger.error(`${TAG} ${platform} is not a valid application platform`);
      throw new Error(`${TAG} ${platform} is not a valid application platform -> terminating app...`)
    }

    currentPlatform = platform;
    logger.info(`${TAG} Setting platform to "${platform}"`);
  };


  /**
   * Get the platform the app is currently running on
   *
   * @return {string} The platform identifier.
   * @see {~/configuration/GLOBALS.js}
   */
  const getPlatform = () => currentPlatform;


  /**
   * Get the version number of the OS/browser the app currently runs on/in
   *
   * @return {number|null} Either the OS' version number or null if not able to get
   */
  const getOSVersion = () => {
    const navigator = window.navigator || null;
    if (navigator) {
      let key, match, version;

      for (key in RE_PLATFORM) {
        if ((match = RE_PLATFORM[key].exec(navigator.userAgent))) {
          version = parseFloat(match[1].replace("_", "."), 10);
          return isNaN(version) ? match[1] : version;
        }
      }

      // Not able to determine OS version
      return null;
    } else {
      // TODO: Implement for native platforms
    }
  };


  /**
   * Convenience function to check if the current platform is Web
   *
   * @returns {boolean} Whether or not the current platform is Web
   */
  const isWeb = () => currentPlatform === appConfig.PLATFORM.WEB


  /**
   * Convenience function to check, if the current platform is a native platform,
   * i.e. if it is either Android or iOS.
   *
   * @returns {boolean} Whether or not the current platform is a native platform
   */
  const isNative = () => currentPlatform === appConfig.PLATFORM.ANDROID ||
    currentPlatform === appConfig.PLATFORM.IOS;


  /**
   * Convenience function to check, if the current platform is Android
   *
   * @returns {boolean} Whether or not the current platform is Android
   */
  const isAndroid = () => currentPlatform === appConfig.PLATFORM.ANDROID;


  /**
   * Convenience function to check, if the current platform is iOS
   *
   * @returns {boolean} Whether or not the current platform is iOS
   */
  const isIOS = () => currentPlatform === appConfig.PLATFORM.IOS;


  /**
   * Convenience function to check, if the application is run in a browser
   * on an Android device
   *
   * @returns {boolean} Whether or not the application runs in a browser on Android
   */
  const isBrowserOnAndroid = () => navigator.userAgent &&
    RE_PLATFORM.android.test(navigator.userAgent);


  /**
   * Convenience function to check, if the application is run in a browser
   * on an iOS device
   *
   * @returns {boolean} Whether or not the application runs in a browser on iOS
   */
  const isBrowserOnIOS = () => navigator.userAgent &&
    RE_PLATFORM.ios.test(navigator.userAgent);


  /**
   * Convenience function to check, if the application is run in a browser
   * on an Android or iOS device
   *
   * @returns {boolean} Whether or not the application runs in a browser on Android or iOS
   */
  const isBrowserOnMobile = () => isBrowserOnAndroid() || isBrowserOnIOS();


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    platforms,
    setPlatform,
    getPlatform,
    getOSVersion,
    isBrowserOnAndroid,
    isBrowserOnIOS,
    isBrowserOnMobile,
    isWeb,
    isNative,
    isAndroid,
    isIOS
  };

}
