import Color from "@rakuten/common-util/color";
import defaultColorPalette from "./../common/color-palette";

// base colors as `Color` objects, used to derive colors with `lightness`,
// `saturation`, or `withAlpha`
const lightYellow = new Color("hsl(57, 79%, 83%)");
const koboRed     = new Color(189, 0, 0);                   // hsl(0, 100%, .37)

const colorPalette = {
  ...defaultColorPalette,

  koboRed:           koboRed.toString(),                    // #bd0000    hsl(0, 100%, 37%)       rgb(189, 0, 0)        light-primary, sepia-primary
  koboRed04:         koboRed.withAlpha(.04).toString(),  // #bd0000a:  hsla(0, 100%, 37%, .04) rgba(189, 0, 0, 0.04) light-primaryHover, sepia-primaryHover
  koboRed08:         koboRed.withAlpha(.08).toString(),  // #bd000014: hsla(0, 100%, 37%, .08) rgba(189, 0, 0, 0.08) light-primarySelected, sepia-primarySelected
  koboRed16:         koboRed.withAlpha(.16).toString(),  // #bd000029: hsla(0, 100%, 37%, .16) rgba(189, 0, 0, 0.16) light-primaryPressed, sepia-primaryPressed
  koboRed38:         koboRed.withAlpha(.38).toString(),  // #bd000061: hsla(0, 100%, 37%, .38) rgba(189, 0, 0, 0.38) light-primaryDisabled, sepia-primaryDisabled

  lightYellow:       lightYellow.toString(),                // #f6f2b1:   hsl(57, 79%, 83%)  rgb(246, 242, 177)         light-highlightSearch, light-highlightYellow, sepia-highlightSearch, sepia-highlightYellow
  brightYellow:      "rgb(244, 243, 158)",                  // #f5f39e:   hsla(59, 81%, 79%, 1) rgb(244, 243, 158)
  coolYellow:        "rgb(173, 167, 138)",                  // #ada78a:   hsl(50, 18%, 61%)  rgb(173, 167, 138)         dark-highlightYellow, char-highlightYellow
  platinumYellow:    "rgba(244, 243, 158, 0.60)",           // #f4f39e99: hsla(59, 81%, 79%, .60)
  pastelRed:         "rgb(254, 113, 113)",

  // sepia variants
  sepiaBlack:        "rgb(62, 44, 29)",                  // #3e2c1d:   hsl(26, 36%, 18%)  rgb(62, 44, 29)            sepia-textCopy
  sepiaBright:       "rgb(234, 221, 200)",               // #eaddc8:   hsl(38, 45%, 85%)  rgb(234, 221, 200)         sepia-background

  // shades of grey
  darkCharcoal:      "rgb(26, 26, 26)",                  // #1a1a1a:   hsl(0, 0%, 10%)  rgb(26, 26, 26)     light-iconSelected, sepia-iconSelected
  darkCharcoal16:    "rgba(26, 26, 26, 0.16)",           // #1a1a1a29: hsla(0, 0%, 10%, .16) rgba(26, 26, 26, 0.16)
  nightGrey:         "rgb(31, 31, 31)",                  // #1f1f1f:   hsl(0, 0%, 12%)  rgb(31, 31, 31)     light-divider, sepia-divider
  charcoal:          "rgb(33, 33, 33)",                  // #212121:   hsl(0, 0%, 13%)  rgb(33, 33, 33)     dark-backgroundUI, char-background, char-backgroundUI
  slateGrey:         "rgb(77, 77, 77)",
  slateGrey16:       "rgba(77, 77, 77, 0.16)",
  mediumGrey:        "rgb(127, 127, 127)",
  quickSilver:       "rgb(166, 166, 166)",               // #a6a6a6:   hsl(0, 0%, 65%)  rgb(166, 166, 166)  light-label, sepia-label
  cloudWhite:        "rgb(237, 237, 237)"                // #ededed:   hsl(0, 0%, 93%)  rgb(237, 237, 237)  dark-iconSelected, char-iconSelected
};

export default colorPalette;
