/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";

import { RESIZE_MODE, transformProps } from "./transformProps";
import { SyntheticEvent } from "./nativeEvent";


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

/**
 * Cache status identifiers according to https://reactnative.dev/docs/image#querycache
 * with additional status `fetching`
 *
 * @type {Object<String>}
 */
const CACHE_STATUS = {
  FETCHING: "fetching",
  MEMORY: "memory",
  DISK: "disk", // @ToDo: persist image data to db
  DISK_MEMORY: "disk/memory"
};

/**
 * Map of image objects, referenced by their source uri.
 * Each object has attributes `status`, `image`, `timestamp`, and while fetching
 * `promise` and `requestId`.
 * @ToDo: Cache management -> max. cache size + delete cached entries
 * 
 * @type {Object<Object>}
 */
const IMAGE_CACHE = {};

/**
 * Generator function to create a sequence of numbers used to reference request
 * identifiers, see https://reactnative.dev/docs/image#prefetch
 * 
 * @type {Function}
 */
const createRequestId = (function() {
  let requestId = 0;
  return () => requestId += 1;
})();


// -----------------------------------------------------------------------------
// PRIVATE METHODS
// ----------------------------------------------------------------------------


function isFunction(fn) {
  return typeof fn === "function";
}

function getUriFromSource(source) {
  return (typeof source === "object") ? source.uri : source;
}

/**
 * Fetches @uri with optional @headers as binary data.
 * 
 * @param {String} uri Image URI
 * @param {Object} [headers] Map of header names and values
 * @return {Promise} Promise which resolves to the fetched blob for @uri
 */
function fetchXHR(uri, headers) {
  return new Promise(function(resolve, reject) {
    const xhr = new XMLHttpRequest();
    
    xhr.open("GET", uri);
    xhr.responseType = "blob";
    
    if (typeof headers === "object") {
      for (let headerName in headers) {
        xhr.setRequestHeader(headerName, headers[headerName]);
      }
    }
    
    xhr.onload = function() {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
      else {
        reject(new Error(`Image ${uri} did not load successfully, ${xhr.statusText}`));
      }
    };
    
    xhr.onerror = function() {
      reject(new Error(`Image ${uri} did not load successfully`));
    };
    
    xhr.send();
  });
}

/**
 * Fetches the image @uri with optional @headers into an `Image` object.
 * If @headers are provided, the image is using an object URL representing the
 * binary data of @uri.
 * The fetch operation creates an entry in `IMAGE_CACHE` with attributes `status`,
 * `promise`, and `image` which will resolved by the returned promise.
 * The attribute `requestId` is removed after fetch is completed.
 * 
 * @param {String} uri Image URI
 * @param {Object} [headers] Map of header names and values
 * @return {Promise} Promise which resolves to an `Image` object with @uri as src attribute
 */
function fetchImage(uri, headers) {
  let cached = IMAGE_CACHE[uri];
  if (cached) {
    // either there's a fetch in progress or the image is already fetched/cached
    return cached.promise || Promise.resolve(cached.image);
  }
  // @uri is not in cache -> create a new entry
  cached = IMAGE_CACHE[uri] = {
    image: new window.Image(),
    requestId: createRequestId(),
    status: CACHE_STATUS.FETCHING
  };
  
  return cached.promise = new Promise(function(resolve, reject) {
    const image = cached.image;
    
    if (!uri) {
      return reject(new Error(`Image ${uri} not defined`));
    }
    
    image.onload = function() {
      cached.status = CACHE_STATUS.MEMORY;
      cached.timestamp = Date.now();
      delete cached.promise;
      delete cached.requestId;
      image.onerror = image.onload = null;
      resolve(image);
    };
    
    image.onerror = function() {
      delete IMAGE_CACHE[uri];
      image.onerror = image.onload = null;
      reject(new Error(`Image ${uri} did not load successfully`));
    };
    
    if (typeof headers === "object") {
      fetchXHR(uri, headers)
        .then(function(response) {
          image.crossOrigin = "";
          image.src = window.URL.createObjectURL(response);
        })
        .catch(reject);
    }
    else {
      image.src = uri;
    }
  });
}


// -----------------------------------------------------------------------------
// STATIC IMAGE API METHODS
// ----------------------------------------------------------------------------

/**
 * Aborts the prefetch request identified by @requestId
 * 
 * @param {Number} requestId Request id as returned by `prefetch()`
 */
function abortPrefetch(requestId) {
  const uri = Object.keys(IMAGE_CACHE)
    .find((uri) => IMAGE_CACHE[uri].requestId === requestId);
  
  // The abort is only possible if the request is still in progress,
  // i.e. the @requestId is still present in the initially created cache entry.
  if (uri) {
    // clearing the image `src` attribute is cancelling the request
    IMAGE_CACHE[uri].image.src = "";
    // remove the image from cache
    delete IMAGE_CACHE[uri];
  }
}


/**
 * Retrieve the width and height (in pixels) of an image prior to displaying it
 * with the ability to provide the headers for the request.
 * This method can fail if the image cannot be found, or fails to download.
 * It also does not work for static image resources.
 * 
 * @param {String} uri The location of the image
 * @param {Object} headers The headers for the request
 * @param {Function} cbSuccess Callback after the image was successfully retrieved
 * @param {Function} [cbFailure] The function that will be called if there was an error
 */
function getSizeWithHeaders(uri, headers, cbSuccess, cbFailure) {
  fetchImage(uri, headers)
    .then((image) => cbSuccess(image.naturalWidth, image.naturalHeight))
    .catch((ex) => {
      console.warn(`Image.getSize: ${ex.message}`);
      if (isFunction(cbFailure)) {
        cbFailure(ex);
      }
    });
}


/**
 * Retrieve the width and height [px] of an image prior to displaying it.
 * This method can fail if the image cannot be found, or fails to download.
 * In order to retrieve the image dimensions, the image may first need to be
 * loaded or downloaded, after which it will be cached.
 *
 * @param {String} uri The location of the image
 * @param {Function} cbSuccess Callback after the image was successfully retrieved
 * @param {Function} [cbFailure] The function that will be called if there was an error
 */
function getSize(uri, cbSuccess, cbFailure) {
  getSizeWithHeaders(uri, null, cbSuccess, cbFailure);
}

/**
 * Prefetches a remote image for later use by downloading it to the internal cache.
 * 
 * @param {String} url The remote location of the image
 * @param {Function} [callback] The function that will be called with the requestId.
 * @return {Promise} Promise which resolves to a boolean
 */
function prefetch(url, callback) {
  const promiseFetchImage = fetchImage(url);
  
  if (isFunction(callback)) {
    const requestId = IMAGE_CACHE[url]?.requestId;
    if (requestId) {
      callback(requestId);
    }
  }
  
  return promiseFetchImage
    .then(() => true)
    .catch((ex) => {
      console.warn(`Image.prefetch: ${ex.message}`);
      return false;
    });
}


/**
 * Perform cache interrogation. Returns a promise which resolves to a mapping
 * from URL to cache status, such as "disk", "memory" or "disk/memory".
 * If a requested URL is not in the mapping, it means it's not in the cache.
 * 
 * @param {Array} urls List of image URLs to check the cache for
 * @return {Promise} Promise which resolves to a mapping from URL to cache status
 */
function queryCache(urls) {
  // @ToDo: implementation to persist cached images for status "disk"
  const cacheStatus = {};
  
  urls.forEach(function(url) {
    const status = IMAGE_CACHE[url]?.status;
    if (status && status !== CACHE_STATUS.FETCHING) {
      cacheStatus[url] = status;
    }
  });
  
  return Promise.resolve(cacheStatus);
}


/**
 * Resolves an asset reference into an object which has the properties uri, width, and height.
 * 
 * @param {Object|String} source Image source object or string
 * @return {Object}
 */
function resolveAssetSource(source) {
  const uri = typeof source === "object"
    ? (source.uri || source.url)
    : source;
  
  const result = { uri };
  const image  = IMAGE_CACHE[uri]?.image;
  
  function setResult(width, height) {
    result.width  = width;
    result.height = height;
  }
  
  // since this method is executed synchronously, width and height can only be
  // determined immediately if the image source is already in cache.
  if (image) {
    setResult(image.naturalWidth, image.naturalHeight);
  }
  else {
    // otherwise, the determined values are later written into the returned result
    // object. This is a hack, which should work if the asset is initialised at load time.
    getSizeWithHeaders(uri, null, setResult);
  }
  
  return result;
}


// -----------------------------------------------------------------------------
// COMPONENT
// -----------------------------------------------------------------------------

/**
 * The `Image` component which has the properties of the react native component
 * and is mapped to the `img` tag, see https://reactnative.dev/docs/image
 * 
 * @Todo: implement prop `fadeDuration` using CSS background image or pseudo element
 *   background-image: url(<defaultSource>);
 *   background-size: contain;
 *   background-repeat: no-repeat;
 *   background-position: bottom center;
 *   transition: background <fadeDuration>ms;
 */
export default class Image extends React.Component {

  static abortPrefetch = abortPrefetch
  static getSize = getSize
  static getSizeWithHeaders = getSizeWithHeaders
  static prefetch = prefetch
  static queryCache = queryCache
  static resolveAssetSource = resolveAssetSource
  
  constructor(props) {
    super(props);
    this.refElem = React.createRef();
    this.state = {
      src: getUriFromSource(props.defaultSource) || getUriFromSource(props.source)
    };
    this.fetchSource(props);
  }
  
  
  fetchSource(props) {
    if (isFunction(props.onLoadStart)) {
      props.onLoadStart();
    }
    const source = getUriFromSource(props.source) || getUriFromSource(props.defaultSource);
    fetchImage(source)
      .then(this.onLoad)
      .catch(this.onError);
  }
  
  // -----------------------------------------------------------------------------
  // EVENT HANDLER
  // -----------------------------------------------------------------------------

  onLoad = (image) => {
    // `fetchImage` resolves with an `Image` object after its source was loaded
    this.setState({ src: image.src });
    if (isFunction(this.props.onLoad)) {
      this.props.onLoad({
        source: {
          uri: image.src,
          height: image.naturalHeight,
          width: image.naturalWidth
        }
      });
    }
    if (isFunction(this.props.onLoadEnd)) {
      this.props.onLoadEnd();
    }
  }
  
  onError = (err) => {
    if (isFunction(this.props.onError)) {
      this.props.onError(err);
    }
    if (isFunction(this.props.onLoadEnd)) {
      this.props.onLoadEnd();
    }
  }
  
  onResize = (event) => {
    const element = this.refElem.current;
    if (element) {
      event.layout = element.getBoundingClientRect();
      const syntheticEvent = new SyntheticEvent(event, element);
      this.props.onLayout.call(element, syntheticEvent);
    }
  }
  
  // -----------------------------------------------------------------------------
  // LIFECYCLE
  // -----------------------------------------------------------------------------
  
  componentDidMount() {
    if (isFunction(this.props.onLayout)) {
      window.addEventListener("resize", this.onResize, false);
    }
  }
  
  componentWillUnmount() {
    if (isFunction(this.props.onLayout)) {
      window.removeEventListener("resize", this.onResize, false);
    }
  }
  
  shouldComponentUpdate(nextProps) {
    if (getUriFromSource(nextProps.source) !== getUriFromSource(this.props.source)) {
      this.fetchSource(nextProps);
      return false;
    }
    return true;
  }
  
  render() {
    // remove props that are handled internally and not passed to the HTML element
    /* eslint-disable no-unused-vars */
    const {
      defaultSource,
      fadeDuration,
      onError, onLayout, onLoad, onLoadEnd, onLoadStart,
      source,
      ...props
    } = this.props;
    /* eslint-enable no-unused-vars */
    
    return (
      <img
        ref={ this.refElem }
        src={ this.state.src }
        { ...transformProps(props, "img") }
      />
    );
  }
}

// -----------------------------------------------------------------------------
// PROPS VALIDATION
// -----------------------------------------------------------------------------

Image.propTypes = {
  accessible: PropTypes.bool,
  accessibilityLabel: PropTypes.string,
  defaultSource: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
  fadeDuration: PropTypes.number,
  onError: PropTypes.func,
  onLayout: PropTypes.func,
  onLoad: PropTypes.func,
  onLoadEnd: PropTypes.func,
  onLoadStart: PropTypes.func,
  resizeMode: PropTypes.oneOf(Object.keys(RESIZE_MODE)),
  source: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
  style: PropTypes.oneOfType([ PropTypes.array, PropTypes.object, PropTypes.string ]),
  testID: PropTypes.string
};


Image.defaultProps = {
  accessible: false,
  fadeDuration: 300
}