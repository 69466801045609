/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

/**
 * Implementation of the ROT8000 algorithm in JavaScript.
 */
function rot8000(message, encode) {
  const offset = encode ? 8000 : -8000;
  let encrypted = "";
  for (let i = 0; i < message.length; i++) {
    let charCode = message.charCodeAt(i);
    charCode = (charCode + offset) % 65536;
    encrypted += String.fromCharCode(charCode);
  }
  return encrypted;
}

export default rot8000;
