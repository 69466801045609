/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2022-present Rakuten Kobo Inc. (https://www.kobo.com)
 *
 * IndexedDB storage engine to be used with redux-persist.
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import {
  deleteDB,
  openDB
} from 'idb';



// -----------------------------------------------------------------------------
// CONFIGURTATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[service/app-state/indexeddb]';

// Reference to the currently opened database
let DB = null;

// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["indexedDb", ["localConfig", "logger"], createIndexedDBService];

// -----------------------------------------------------------------------------
// INDEXEDDB SERVICE
// -----------------------------------------------------------------------------

export function createIndexedDBService(localConfig, logger) {
  const indexedDBConfig = localConfig.getLocalConfig().APPSTATE.ENGINES.INDEXEDDB;
  const objectStore = indexedDBConfig.DEFAULT_OBJECT_STORE_NAME;
  const version = indexedDBConfig.VERSION;

  // ---------------------------------------------------------------------------
  // PRIVATE METHODS
  // ---------------------------------------------------------------------------

  /**
   * Opens/Creates an IndexedDB store
   *
   * @param {string} storeName Name of the store to open
   * @param {number} version Database version number
   * @return {Promise<IDBDatabase>} The opened database
   */
  const openDatabase = () => openDB(objectStore, version, {
    upgrade(db) {
      // For now, we do not need to care about any upgrade, all of our IndexedDBs
      // for redux-persist are version 1 during initial development. Later on,
      // we might need to handle data migration, i.e. version > 1.
      db.createObjectStore(objectStore);
    }
  });


  /**
   * Initializes the database and stores a reference for later usage
   *
   * @return {Promise<IDBDatabase>} The reference to the database
   */
  const initializeDatabase = () => new Promise((resolve, reject) => {
    // If the database has been initialized before, return the reference to the
    // already opened database
    if (DB) {
      resolve(DB);
      return;
    }

    logger.info(`${TAG} Creating new persist storage engine (IndexedDB) with object store "${objectStore}" version ${version}`);

    return openDatabase().then(
      db => {
        DB = db;
        resolve(db);
      }
    ).catch(error => {
      logger.error(`${TAG} Initializing store ${objectStore} failed`, error);
      reject();
    })
  });


  /**
   * Save value into storage
   *
   * @param {string} reducerName The name of the reducer to set a value for
   * @param {any} value The value to store
   * @return {Promise<IDBRequest>} The request to insert data into the object store
   */
  const setItem = (reducerName, value) => initializeDatabase().then(
    db => db.put(objectStore, value, reducerName)
  ).catch(
    err => logger.error(`${TAG} Could not store item ${reducerName} with value ${value} in object store ${objectStore} version ${version}`, err)
  );


  /**
   * Get value from persisted storage
   *
   * @param {string} reducerName Name of the reducer for which to get the value
   * @return {Promise<IDBRequest>} The request to get data from the object store
   */
  const getItem = reducerName => initializeDatabase().then(
    db => db.get(objectStore, reducerName)
  ).catch(
    err => logger.error(`${TAG} Could not get item ${reducerName} from store ${objectStore} version ${version}`, err)
  );


  /**
   * Remove value from persisted storage
   *
   * @param {string} reducerName Name of the reducer to delete from storage
   * @return {Promise<IDBRequest>} Deletion of the database
   */
  const removeItem = reducerName => {
    logger.info(`${TAG} Deleting object store "${objectStore}" ${version}`);
    return deleteDB(reducerName);
  };


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    getItem,
    removeItem,
    setItem
  };
}
