/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * Utility functions around everything related to file support.
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { escapeRegExpMetaChars } from './base';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

/**
 * Filenames within an ePub are encoded using the encodeURI function.
 * FILENAME_ENCODING contains characters that are translated in addition to
 * encodeURI in order to avoid processing problems and to be compliant
 * with filenames referenced in RMSDK locations.
 *
 * @type {Object}
 */
const FILENAME_ENCODING = {
  "%21": "!",
  "%26": "&",
  "%27": "'",
  "%28": "(",
  "%29": ")",
  "%2B": "+",
  ","  : "%2C",
  ":"  : "%3A",
  ";"  : "%3B",
  "="  : "%3D",
  "?"  : "%3F",
  "@"  : "%40"
};

/**
 * Regular expression to match a lowercase hex encoded character,
 * i.e. a hexadecimal double-digit preceeded by a "%"
 *
 * @type {RegExp}
 * @default
 */
const RE_LOWERCASE_HEX = /%[0-9a-f]{2}/g;

/**
 * Regular expression to match any non-alphanumeric character.
 *
 * @type {RegExp}
 */
const RE_NON_ALPHANUM = /[^0-9a-zA-Z]/ig;

/**
 * Regular expression matching a encoding sequence %XX or %uXXXX
 * with X being a hexadecimal digit.
 *
 * @type {RegExp}
* @default
 */
const RE_IS_ESCAPED = /%[0-9A-Fa-f]{2}|%u[0-9A-Fa-f]{4}/;

const RE_FILENAME_ENCODING = new RegExp(Object.keys(FILENAME_ENCODING).map(escapeRegExpMetaChars).join("|"), "g");

/**
 * MIME types by file extension
 * @type {Object}
 */
const MIME_TYPES = {
  "epub": "application/epub+zip",
  "pdf" : "application/pdf",
  "jpg" : "image/jpeg",
  "jpeg": "image/jpeg",
  "png" : "image/png",
  "gif" : "image/gif",
  "svg" : "image/svg+xml",
  "otf" : "application/x-font-opentype",
  "ttf" : "application/x-font-ttf",
  "woff": "application/font-woff",
  "acsm": "application/vnd.adobe.adept+xml",
  "m4a" : "audio/mp4",
  "aac" : "audio/mp4",
  "mp3" : "audio/mpeg",
  "ogg" : "audio/ogg",
  "oga" : "audio/ogg",
  "wav" : "audio/x-wav",
  "mp4" : "video/mp4",
  "m4v" : "video/mp4",
  "webm": "video/webm",
  "ogv" : "video/ogg"
};


// -----------------------------------------------------------------------------
// PRIVATE MODULE METHODS
// -----------------------------------------------------------------------------

/**
 * Converts all hex encoded characters with lowercase hexadecimal digits
 * to uppercase hexadecimal digits, e.g. "Kottke%2cJobs" -> "Kottke%2CJobs"
 *
 * @param {String} str Arbitrary string
 * @return {String} String with uppercase hexadecimal digits (escaped by "%")
 */
function uppercaseHexEncodedChars(str) {
  // apply regular expression for lowercase hexadecimal digits only:
  return str.replace(RE_LOWERCASE_HEX, ($0) => $0.toUpperCase());
}


// -----------------------------------------------------------------------------
// PUBLIC API
// -----------------------------------------------------------------------------

/**
 * Returns the MIME type of @path based on the file name's extension
 *
 * @param {String} path The file path
 * @return {String} The MIME type of the file or "" if there's no extension
 *                  or no corresponding type in the map `MIME_TYPES`.
 */
function getMimeTypeByExtension(path) {
  const fileName = path.substr(path.lastIndexOf("/") + 1);
  const start = fileName.lastIndexOf(".") + 1;
  return start > 0 && MIME_TYPES[fileName.substr(start).toLowerCase()] || "";
}


/**
 * Auxiliary function to escape obstructive characters for file names within ePubs
 *
 * @param {String} fileName The file name to escape
 * @return {String} The escaped file name
 */
function escapeFileName(fileName) {
  if (!RE_IS_ESCAPED.test(fileName)) {
    // fileName is not encoded yet (doesn't contain escape sequences) -> apply a simple encoding first.
    // try/catch due to possible "String contained an illegal UTF-16 sequence" error:
    try {
      fileName = encodeURI(fileName);
    }
    catch(ex) {
      console.warn(ex, fileName);
    }
  }
  // apply encoding for characters specified in map `FILENAME_ENCODING`
  return uppercaseHexEncodedChars(fileName)
    .replace(RE_FILENAME_ENCODING, (match) => FILENAME_ENCODING[match]);
};


function getRootDirectory(path) {
  const match = /^[^/]*/.exec(path);
  return match && match[0];
}


/**
 * Normalizes a given path. This is directly extracted from Node.js via
 * https://raw.githubusercontent.com/zacanger/path-normalize/master/src/index.js
 *
 * @param {string} path The path to normalize
 * @return {string} The normalized path
 */
function normalizePath(path) {
  const SLASH = 47
  const DOT = 46

  const assertPath = (path) => {
    const t = typeof path
    if (t !== 'string') {
      throw new TypeError(`Expected a string, got a ${t}`)
    }
  }

  const posixNormalize = (path, allowAboveRoot) => {
    let res = ''
    let lastSegmentLength = 0
    let lastSlash = -1
    let dots = 0
    let code

    for (let i = 0; i <= path.length; ++i) {
      if (i < path.length) {
        code = path.charCodeAt(i)
      } else if (code === SLASH) {
        break
      } else {
        code = SLASH
      }
      if (code === SLASH) {
        if (lastSlash === i - 1 || dots === 1) {
          // NOOP
        } else if (lastSlash !== i - 1 && dots === 2) {
          if (
            res.length < 2 ||
            lastSegmentLength !== 2 ||
            res.charCodeAt(res.length - 1) !== DOT ||
            res.charCodeAt(res.length - 2) !== DOT
          ) {
            if (res.length > 2) {
              const lastSlashIndex = res.lastIndexOf('/')
              if (lastSlashIndex !== res.length - 1) {
                if (lastSlashIndex === -1) {
                  res = ''
                  lastSegmentLength = 0
                } else {
                  res = res.slice(0, lastSlashIndex)
                  lastSegmentLength = res.length - 1 - res.lastIndexOf('/')
                }
                lastSlash = i
                dots = 0
                continue
              }
            } else if (res.length === 2 || res.length === 1) {
              res = ''
              lastSegmentLength = 0
              lastSlash = i
              dots = 0
              continue
            }
          }
          if (allowAboveRoot) {
            if (res.length > 0) {
              res += '/..'
            } else {
              res = '..'
            }
            lastSegmentLength = 2
          }
        } else {
          if (res.length > 0) {
            res += '/' + path.slice(lastSlash + 1, i)
          } else {
            res = path.slice(lastSlash + 1, i)
          }
          lastSegmentLength = i - lastSlash - 1
        }
        lastSlash = i
        dots = 0
      } else if (code === DOT && dots !== -1) {
        ++dots
      } else {
        dots = -1
      }
    }

    return res
  }

  const decode = (s) => {
    try {
      return decodeURIComponent(s)
    } catch {
      return s
    }
  }

  const normalize = (p) => {
    assertPath(p)

    let path = p
    if (path.length === 0) {
      return '.'
    }

    const isAbsolute = path.charCodeAt(0) === SLASH
    const trailingSeparator = path.charCodeAt(path.length - 1) === SLASH

    path = decode(path)
    path = posixNormalize(path, !isAbsolute)

    if (path.length === 0 && !isAbsolute) {
      path = '.'
    }
    if (path.length > 0 && trailingSeparator) {
      path += '/'
    }
    if (isAbsolute) {
      return '/' + path
    }

    return path
  }

  return normalize(path);
};


/**
 * Resolves a relative path in relation to root to an absolute path
 *
 * @param {String} relativePath The path to transform into an absolute path
 * @param {String} root The base path
 * @return {String} The absolute path of relativePath in relation to root
 */
function resolveRelativePath(relativePath, root) {
  if (!relativePath) {
    return '';
  }
  if (typeof root !== 'string') {
    root = '';
  }

  var separatorChar = root.indexOf("\\") >= 0 ? "\\" : "/";
  var posOfLastSepChar = root.lastIndexOf(separatorChar);
  var basePath, basePathElements, sourceFile;

  if (posOfLastSepChar > 0) {
    basePath   = root.substring(0, posOfLastSepChar);
    sourceFile = root.substring(posOfLastSepChar + 1);
    // split basePath according to `separatorChar` into an array:
    basePathElements = basePath.split(separatorChar);
  }
  else {
    // there's no separatorChar or it's at position 0:
    basePathElements = [];
    sourceFile = '';
  }

  // prepend the source file name if the path starts with a hash tag:
  if (relativePath.startsWith("#")) {
    relativePath = sourceFile + relativePath;
  }

  // split the relative path according to separatorChar into an array and iterate
  // over the relative path elements and modify basePath according to the path element
  relativePath.split(separatorChar).forEach(function(pathElement) {
    if (pathElement === '..') {
      // reference to the parent directory, remove last directory from basePath:
      basePathElements.pop();
    }
    else if (pathElement !== '.' && pathElement !== '~') {
      // otherwise the current (relative) directory is appended to the basePath:
      basePathElements.push(pathElement);
    }
  });

  return escapeFileName(basePathElements.join(separatorChar));
}


/**
 * Replaces all characters of a filename that are not alphanumeric
 * (i.e. all characters except 0 .. 9, a .. z, A .. Z) by a dash (-).
 *
 * Note: Don't use this method to sanitize paths, only file names!
 *
 * @param {String} fileName The file name to sanitize
 * @return {String} The sanitized file name
 */
function sanitizeFileName(fileName) {
  return fileName ? fileName.replace(RE_NON_ALPHANUM, '-') : '';
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  escapeFileName,
  getMimeTypeByExtension,
  getRootDirectory,
  normalizePath,
  resolveRelativePath,
  sanitizeFileName
};
