/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * Utility functions around everything related to JavaScript objects
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

/**
 * Checks if two objects are equal
 *
 * @see {@link https://stackoverflow.com/a/32922084}
 *
 * @param {object} x An object
 * @param {object} y Another object
 * @returns {boolean} Whether or not the two objects are equal
 */
const areEqual = (x, y) => {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
      ok(x).every(key => areEqual(x[key], y[key]))
  ) : (x === y);
};


/**
 * Find object by a value that is the value of any of the object's
 * properties.
 *
 * @see {@link https://stackoverflow.com/a/57676749}
 *
 * @example
 *  const myObject = {
 *    propA: 123,
 *    propB: {
 *      propBA: 456,
 *      propBB: 789
 *    }
 *  };
 *
 *  findObjByValue(myObject, 789);
 *  // -> { propBA: 456, propBB: 789 }
 *
 * @param {object} obj The object in which to look for the value
 * @param {string} keyToFind The value to look for
 * @returns {object|undefined} The found object
 */
const findObjByValue = (root, valueToFind) => {
  const __find = obj => {
    for (const [key, value] of Object.entries(obj)) {
      if (key && value === valueToFind) {
        return obj;
      }

      if (typeof value === 'object') {
        const find = __find(value);
        if (find) {
          return find;
        }
      }
    }
  }

  return __find(root);
};


/**
 * Flattens an object recursively
 *
 * @see {@link https://stackoverflow.com/a/56253298}
 *
 * @example
 *  const myObject = {
 *    propA: 123,
 *    propB: {
 *      propC: 456
 *    }
 *  };
 *
 *  flatten(myObject);
 *  // -> { propA: 123, propB_propC: 456 }
 *
 * @param {object} obj The object to flatten
 * @param {object} parent The parent object of the object to flatten
 * @param {object} res The flattend object
 * @returns {object} An object with depth 1
 */
const flatten = (obj, parent, res = { }) => {
  for (let key in obj) {
    let propName = parent ? parent + '_' + key : key;
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      flatten(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }

  return res;
};


/**
 * Returns the value from an object that is identified by @keyIgnoreCase,
 * but while searching for the key, capitalization is ignored.
 *
 * @param {string} keyIgnoreCase The key to look for in @obj
 * @param {object} obj The object in which to look for @keyIgnoreCase
 * @return {*} The value identified by @obj[@keyIgnoreCase]
 */
const getValueByIgnoredCaseKey = function getValueByIgnoredCaseKey(keyIgnoreCase, obj) {
  for (let key in obj) {
    if (key.toLocaleLowerCase() === keyIgnoreCase.toLowerCase()) {
      return obj[key];
    }
  }
};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  areEqual,
  findObjByValue,
  flatten,
  getValueByIgnoredCaseKey
};
