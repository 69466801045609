/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// HELPER
// -----------------------------------------------------------------------------

/**
 * Initiates the extraction process for the first object in
 * the task queue by posting a message to the worker instance.
 *
 * @param  {ArchiveWorkerManager} context context of the ES6 class (eg. this)
 * @return {boolean} true/false if manager has accepted the task
 * @memberof ArchiveWorkerManager
 * @private
 */
function sendTaskData(context) {
  const workerList = context.worker;
  const taskQueue = context.taskQueue;

  // Check if there is a worker available or anything to do
  if (workerList.length <= 0 || taskQueue.length <= 0) {
    return false;
  }

  const worker = workerList.pop();
  const taskItem = taskQueue.shift();

  /**
   * Event handler for processing the messages (returned result) posted by the
   * web worker. Resolves the deferred promise with the result returned from
   * web worker.
   *
   * @param {Event} e Message event containing the data posted by the web worker
   * @inner
   */
  let __processWorkerResponse = function(e) {
    const msgData = e.data;
    let isProcessed = true;

    worker.removeEventListener('message', __processWorkerResponse);
    workerList.push(worker);

    while (taskQueue.length > 0 && isProcessed) {
      isProcessed = sendTaskData(context);
    }

    taskItem.deferred.resolve(msgData);
    __processWorkerResponse = null;
  };

  worker.addEventListener('message', __processWorkerResponse, false);
  worker.postMessage(taskItem.data, [ taskItem.data.buffer ]);
}


/**
 * Determines task position in queue according to @priority
 *
 * @param  {ArchiveWorkerManager} context context of the ES6 class (eg. this)
 * @param {number} priority Task priority
 * @return {number} Position in the task queue according to @priority
 * @memberof ArchiveWorkerManager
 * @private
 */
function findTaskQueuePosition(context, priority) {
  let position = context.taskQueue.length;
  while (position > 0 && priority > context.taskQueue[position - 1].priority) {
    position--;
  }

  return position;
}


// -----------------------------------------------------------------------------
// ARCHIVE WORKER MANAGER CLASS
// -----------------------------------------------------------------------------

/**
 * This class manages the asynchronous unzip/inflate process of archive
 * entries. Files are extracted using web workers. Each file is enqueued into
 * a priority queue, returning a promise that is resolved after successful
 * extraction. The maximum number of unzip processes (workers) at once is
 * controlled by @maxWorkers argument provided to the constructor.
 */
class ArchiveWorkerManager {

  // ---------------------------------------------------------------------------
  // INIT/DESTROY
  // ---------------------------------------------------------------------------

  /**
   * Initializes a new ArchiveWorkerManager and creates @maxWorkers web
   * worker.
   *
   * @param {number} maxWorkers Maximum number of parallel web worker
   */
  constructor(maxWorkers = 1) {
    /**
     * Task queue containing the list of pending tasks,
     * 1st entry is the currently processed item (removed after task is done)
     * @type {Array<Promise>}
     */
    this.taskQueue = [ ];

    /**
     * List of web worker instances
     * @type {Array<Worker>}
     */
    this.worker = [ ];

    for (let i = 0, ii = maxWorkers; i < ii; i++) {
      // https://webpack.js.org/guides/web-workers/
      let worker = this.worker[i] = new Worker(new URL('./zip.worker.js', import.meta.url));
      worker.postMessage('');
    }
  }

  /**
   * Clean-up / Destroy
   */
  destroy() {
    let numWorkers = this.worker ? this.worker.length : 0;
    for (let i = 0, ii = numWorkers; i < ii; i++) {
      this.worker[i].terminate();
    }

    this.worker.length = 0;
    this.taskQueue.length = 0;
  }


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  /**
   * Enqueues @data (ZIP archive entry) into the task queue.
   * Dependend on @maxWorker provided to the constructor the task start right away.
   * If more items are in queue then @maxWorker are available, the queue gets
   * sorted via priority. See {@link ArchiveWorkerManager.findTaskQueuePosition}.
   *
   * @example
   *  // MAX_WORKER = 1
   *  let awm = new ArchiveWorkerManager();
   *  awm.addToQueue(data,  0); // starts immediately since worker is free
   *  awm.addToQueue(data, 34); // queued since no worker available
   *  awm.addToQueue(data, 45); // queued before second call since prio is higher
   *
   * @param {Uint8Array} data The ZIP file's raw binary data
   * @param {number} [priority=0] Task priority, higher numbers represent higher priorities
   * @return {Promise} Promise of the deferred object
   */
  addToQueue(data, priority = 0) {
    let position = findTaskQueuePosition(this, priority);
    const deferred = {
      resolve: null,
      reject: null
    };

    const promise =  new Promise((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });

    // Add task to queue
    this.taskQueue.splice(position, 0, {
      data,
      deferred,
      priority
    });
    sendTaskData(this);

    return promise;
  }
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default ArchiveWorkerManager;
