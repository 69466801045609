/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Internal dependencies
import useStyleSystem from '@rakuten/hooks/useStyleSystem';
import { View } from '@rakuten/modules-core-components';
import Icon, { SIZE } from './../atoms/Icon';


// -----------------------------------------------------------------------------
// STYLES
// -----------------------------------------------------------------------------

/**
 * Calculate styles for this component, optionally based on props, theme,
 * currently matching breakpoint etc.
 *
 * @param {object} args Arguments used for style calculation
 * @param {object} args.props Current props passed to the component
 * @param {object} args.theme Current theme object
 * @return {object} Object that maps style names to style objects
 */
function createStyles({ props, theme }) {
  return {
    container: {
      backgroundColor: props.transparent
        ? theme.colors.transparent
        : theme.colors.background,
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      position: 'absolute'
    },

    iconContainer: {
      backgroundColor: theme.colors.primaryHover,
      alignItems: 'center',
      borderRadius: theme.mixins.basicUnit(1.5),
      display: 'flex',
      height: theme.mixins.basicUnit(7),
      justifyContent: 'center',
      width: theme.mixins.basicUnit(7)
    },

    icon: {
      animation: 'rotate360 1.5s linear infinite'
    }
  };
}


// -----------------------------------------------------------------------------
// ACTIVITYINDICATOR COMPONENT (WEB)
// -----------------------------------------------------------------------------

function ActivityIndicator(props) {
  // "Hook" this component into the theme/style system
  const [ styles ] = useStyleSystem(
    {
      id: 'ActivityIndicator',
      props,
      styleFn: createStyles
    }
  );


  // ---------------------------------------------------------------------------
  // RENDER
  // ---------------------------------------------------------------------------

  let dimensions = { };
  const elBBox = props.container?.current?.getBoundingClientRect();
  dimensions = elBBox
  ? {
      height: elBBox.height,
      left: elBBox.left,
      top: elBBox.top,
      width: elBBox.width
    }
  : {
      height: '100%',
      left: 0,
      top: 0,
      width: '100%'
    };

  return (
    <View style={ [ styles.container, dimensions ] }>
      <View style={ styles.iconContainer }>
        <View style={ styles.icon }>
          <Icon
            accessibilityLabel=""
            icon="sync"
            size={ SIZE.LARGE }
          />
        </View>
      </View>
    </View>
  );
}


// -----------------------------------------------------------------------------
// PROPS VALIDATION
// -----------------------------------------------------------------------------

ActivityIndicator.propTypes = {
  /** A React ref to an element over which the indicator should be shown. If
   *  not specified, the indicator will be placer on the whole viewport [optional]
   **/
  container: PropTypes.object,

  /** Whether or not the modal for the indicator should be transparent [optional] */
  transparent: PropTypes.bool
};

ActivityIndicator.defaultProps = {
  transparent: false
};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default ActivityIndicator;
