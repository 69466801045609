/**
 *  ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗  █████╗ ██████╗ ██████╗
 *  ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗██╔══██╗██╔══██╗██╔══██╗
 *     ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║███████║██████╔╝██████╔╝
 *     ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║██╔══██║██╔═══╝ ██╔═══╝
 *     ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝██║  ██║██║     ██║
 *     ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
 *
 * (c) Copyright 2018-present Rakuten Kobo Inc. (http://www.kobo.com)
 */

const fnTrue  = () => true;
const fnFalse = () => false;


function SyntheticEvent(nativeEvent, nativeEventTarget) {
  this.nativeEvent = nativeEvent;
  this.target = nativeEventTarget || nativeEvent.target;
  this.isDefaultPrevented = nativeEvent.defaultPrevented ? fnTrue : fnFalse;
  this.isPropagationStopped = fnFalse;

  ["bubbles", "cancelable", "currentTarget", "defaultPrevented", "eventPhase", "isTrusted", "timeStamp", "type"]
    .forEach((propName) => this[propName] = nativeEvent[propName]);
}

SyntheticEvent.prototype.preventDefault = function() {
  this.defaultPrevented = true;
  if (this.nativeEvent && this.nativeEvent.preventDefault) {
    this.nativeEvent.preventDefault();
    this.isDefaultPrevented = fnTrue;
  }
};

SyntheticEvent.prototype.stopPropagation = function() {
  if (this.nativeEvent && this.nativeEvent.stopPropagation) {
    this.nativeEvent.stopPropagation();
    this.isPropagationStopped = fnTrue;
  }
};


export {
  SyntheticEvent
};
