import * as crypto from "./crypto/crypto.service";
import * as decompress from "./decompress/decompress.service";
import * as featureFlag from "./feature-flag/feature-flag.service";
import * as fetch from "./fetch/fetch.service";
import * as googleAnalytics from "./google-analytics/google-analytics.service";
import * as indexedDb from "./indexed-db/indexed-db.service";
import * as localConfig from "./local-config/local-config.service";
import * as logger from "./logger/logger.service";
import * as messageBus from "./message-bus/message-bus.service";
import * as monitoring from "./monitoring/monitoring.service";
import * as netInfo from "./net-info/net-info.service";
import * as platform from "./platform/platform.service";
import * as queryString from "./query-string/query-string.service";
import * as tracking from "./tracking/tracking.service";

export default [
  crypto,
  decompress,
  featureFlag,
  fetch,
  googleAnalytics,
  indexedDb,
  localConfig,
  logger,
  messageBus,
  monitoring,
  netInfo,
  platform,
  queryString,
  tracking
];
