/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2022-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[service/feature-flag]';


// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["featureFlag", ["logger", "localConfig"], createFeatureFlagService];

// -----------------------------------------------------------------------------
// PRIVATE MODULE METHODS
// -----------------------------------------------------------------------------

/**
* Checks if the provided feature flag identifier is valid.
* For a list of valid fature flag keys, see `~configuration/GLOBALS`.
*
* @param {String} feature A feature flag to validate
* @return {Boolean} true if the feature flag is valid; false otherwise
*/
function isValidFeatureFlag(appConfig, feature) {
  return Object.values(appConfig.FEATURES).includes(feature);
}


// -----------------------------------------------------------------------------
// FEATURE FLAG SERVICE
// -----------------------------------------------------------------------------

/**
 * Factory function which creates a service with given
 * dependencies
 */
export function createFeatureFlagService(logger, localConfig) {

  //app configuration
  const appConfig = localConfig.getLocalConfig();

  /**
   * The list of features. By default, we assume that all features of the
   * software are enabled. However, some features are enabled only for a
   * specific application. This list represents these features. Features can
   * be added either via the `setFeatures()` or `addFeatures()` methods.
   *
   * @type {Array}
   * @default
   */
  let appFeatures = [ ];

  /**
   * The list of currently disabled features. By default, we assume that all
   * features of the software are enabled, thus the empty list. Each application
   * that uses this service has to set a list of disabled features either via the
   * `setDisabledFeatures()` or `addDisabledFeature()` methods.
   *
   * @type {Array}
   * @default
   */
  let disabledFeatures = [];


  // ---------------------------------------------------------------------------
  // PRIVATE METHODS
  // ---------------------------------------------------------------------------

  // APPLICATION SPECIFIC FEATURES

  /**
   * Sets a list of application/software features that should be used. These
   * could include UI related functionality visible to the user as well as
   * internal/code related things.
   *
   * For a list of valid fature flag keys, see ~configuration/GLOBALS.
   *
   * @example
   * setApplicationFeatures([
   *  GLOBALS.FEATURES.DRM_KOBO
   * ]);
   *
   * @param {Array} features List of features explicitly enabled
   */
  function setApplicationFeatures(features) {
    // Remove any existing feature flags
    appFeatures = [ ];

    features.forEach(feature => {
      if (!isValidFeatureFlag(appConfig, feature)) {
        logger.warn(`${TAG} Feature "${feature}" is not a valid feature flag identifier -> not added to application features.`);
      } else {
        appFeatures.push(feature);
      }
    });

    if (appFeatures.length > 0) {
      logger.info(`${TAG} The following features have been explicitly enabled for this application: ${appFeatures}`);
    }
  }


  /**
   * Returns the list of currently explicitly enabled features
   *
   * @return {Array} List of currently explicitly enabled features
   */
  function getApplicationFeatures() {
    return appFeatures;
  }


  function isApplicationFeatureEnabled(feature) {
    if (isValidFeatureFlag(appConfig, feature)) {
      return appFeatures.includes(feature);
    }

    logger.warn(`${TAG} Feature "${feature}" is not a valid feature flag identifier -> cannot be checked.`);
    return false;
  }


  /**
   * Adds an feature to the list of explicitly enabled features
   *
   * @param {String} feature The ID of the feature to explicitly enable
   */
  function addApplicationFeature(feature) {
    if (isValidFeatureFlag(appConfig, feature)) {
      const isAlreadyEnabled = isApplicationFeatureEnabled(feature);
      if (!isAlreadyEnabled) {
        appFeatures.push(feature);
        logger.info(`${TAG} Feature "${feature}" has explicitly enabled for this application.`);
      } else {
        logger.warn(`${TAG} Feature "${feature}" already explicitly enabled -> ignored.`);
      }
    } else {
      logger.warn(`${TAG} Feature "${feature}" is not a valid feature flag identifier -> not added to application features.`);
    }
  }


  function removeApplicationFeature(feature) {
    if (isValidFeatureFlag(appConfig, feature)) {
      const index = appFeatures.indexOf(feature);
      if (index) {
        appFeatures.splice(index, 1);

        logger.info(`${TAG} Feature "${feature}" has been removed from the explicitly enabled application feature list.`);
      } else {
        logger.info(`${TAG} Feature "${feature}" is not present in the list of explicitly enabled application features -> ignored.`);
      }
    } else {
      logger.warn(`${TAG} Feature "${feature}" is not a valid feature flag identifier. Feature cannot be removed.`);
    }
  }


  // DISABLED FEATURES

  /**
   * Sets a list of application/software features that should be disabled and
   * not be available at runtime. These could include UI related functionality
   * visible to the user as well as internal/code related things.
   *
   * For a list of valid fature flag keys, see ~configuration/GLOBALS.
   *
   * @example
   * setDisabledFeatures([
   *  GLOBALS.FEATURES.FAMILY_SHARING,
   *  GLOBALS.FEATURS.TEXT_TO_SPEECH
   * ]);
   *
   * @param {Array} features List of features that need to be disabled
   */
  const setDisabledFeatures = features => {
    // Remove any existing feature flags
    disabledFeatures = [];

    // Check that each feature flag is a valid identifier for a feature
    features.forEach(feature => {
      if (!isValidFeatureFlag(appConfig, feature)) {
        logger.warn(`${TAG} Feature "${feature}" is not a valid feature flag identifier -> not added to disabled features.`);
      } else {
        disabledFeatures.push(feature);
      }
    });

    if (disabledFeatures.length > 0) {
      logger.info(`${TAG} The following features have been disabled: ${disabledFeatures}`);
    }
  };


  /**
   * Returns the list of currently disabled features
   *
   * @return {Array} List of currently disabled features
   */
  const getDisabledFeatures = () => disabledFeatures;


  /**
   * Checks if a feature is currently disabled, i.e. if it is part of
   * `disabledFeatures`.
   *
   * @param {String} feature The ID of the feature to check
   * @return {Boolean} true if the feature is disabled; false otherwise
   */
  const isFeatureDisabled = feature => {
    if (isValidFeatureFlag(appConfig, feature)) {
      return disabledFeatures.includes(feature);
    }

    logger.warn(`${TAG} Feature "${feature}" is not a valid feature flag identifier -> cannot be checked.`);
    return false;
  };


  /**
   * Adds a feature to the list of currently disabled features
   *
   * @param {String} feature The ID of the feature to disable
   */
  const addDisabledFeature = feature => {
    if (isValidFeatureFlag(appConfig, feature)) {
      const isAlreadyDisabled = isFeatureDisabled(feature);
      if (!isAlreadyDisabled) {
        disabledFeatures.push(feature);
        logger.info(`${TAG} Feature "${feature}" has been disabled.`);
      } else {
        logger.warn(`${TAG} Feature "${feature}" already disabled -> ignored.`);
      }
    } else {
      logger.warn(`${TAG} Feature "${feature}" is not a valid feature flag identifier -> not added to disabled features.`);
    }
  };


  /**
   * Removes a feature from the list of currently disabled features. In other
   * words, this feature will be enabled.
   *
   * @param {String} feature The ID of the feature to enable
   */
  const removeDisabledFeature = feature => {
    if (isValidFeatureFlag(appConfig, feature)) {
      const index = disabledFeatures.indexOf(feature);
      if (index) {
        disabledFeatures.splice(index, 1);

        logger.info(`${TAG} Feature "${feature}" has been enabled.`);
      } else {
        logger.info(`${TAG} Feature "${feature}" is already enabled -> ignored.`);
      }
    } else {
      logger.warn(`${TAG} Feature "${feature}" is not a valid feature flag identifier. Feature cannot be removed.`);
    }
  };


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    // IDs
    FEATURES: appConfig.FEATURES,

    // APPLICATION SPECIFIC FEATURES
    setApplicationFeatures,
    getApplicationFeatures,
    isApplicationFeatureEnabled,
    addApplicationFeature,
    removeApplicationFeature,

    // DISABLED FEATURES
    setDisabledFeatures,
    getDisabledFeatures,
    isFeatureDisabled,
    addDisabledFeature,
    removeDisabledFeature
  };

}
