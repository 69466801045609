/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { unescapeEntities } from '@rakuten/common-util/string';
import ByteArray from './ByteArray';

// FIXME: We need a better way to parse the css without importing the parser here
import { parse } from '~modules/epub/CSSParser';
import { sequential } from '~common/util/promise';
import logger from '@rakuten/services-common';

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[service/decompress/unzip]';

/**
 * Local header information for each zip file entry. Each header item
 * has two values: The byte offset relative to the start and
 * the length of the header item. The offset *does* include the 4-byte
 * signature [ 0x50, 0x4B, 0x03, 0x04, ... ] of the local file header!
 *
 * @type {Object}
 * @default
 */
const LOCAL_FILE_HEADERS = {
  signature: [0, 4],
  versionNeeded: [4, 2],
  bitFlag: [6, 2],
  compressionMethod: [8, 2],
  lastModFileTime: [10, 2],
  lastModFileDate: [12, 2],
  crc32: [14, 4],
  compressedSize: [18, 4],
  uncompressedSize: [22, 4],
  fileNameLength: [26, 2],
  extraFieldLength: [28, 2]
};

/**
 * The length of local file header (in bytes) incl. signature
 *
 * @type {number}
 * @default
 */
const FIXED_LOCAL_HEADER_LENGTH = 30;


// -----------------------------------------------------------------------------
// HELPER
// -----------------------------------------------------------------------------

/**
 * Returns the value of the entry @headerName in the local file header
 *
 * @param {Uint8Array} data TypedArray view for the ZIP archive's binary data
 * @param {String} headerName Name of the header entry according to object LOCAL_FILE_HEADERS
 * @return {Number} Value of the header entry
 */
function getLocalHeader(data, headerName) {
  const headerRange = LOCAL_FILE_HEADERS[headerName];
  const start = headerRange[0];
  const end = start + headerRange[1];

  return ByteArray.asNumber(data.subarray(start, end));
}


// -----------------------------------------------------------------------------
// ARCHIVE ENTRY CLASS
// -----------------------------------------------------------------------------

/**
 * This class provides methods for managing file entries of a ZIP archive.
 *
 * It is based on CoffeeZip by Eric Heydenberk and JSUnzip by August Lilleaas.
 *
 * {@link https://github.com/appish/CoffeeZip CoffeeZip code}
 * {@link https://github.com/augustl/js-unzip JSUnzip code}
 */
class ArchiveEntry {

  // ---------------------------------------------------------------------------
  // INIT/DESTROY
  // ---------------------------------------------------------------------------

  /**
   * Constructor method for creating a new ArchiveEntry instance
   *
   * @param {Object} headers Map with header information about the file
   * @param {Object} archive Reference to the ZIP archive containing this file
   */
  constructor(headers, archive) {
    this.headers = headers;
    this.archive = archive;
    this.services = archive.services;
    this.fileContent = null;
    this.fileContentAsString = null;
    this.parsedFileContent = null;
    this._processBitFlag();

    this.extractionPromise = null;
    this.cryptoInfo = null;
  }


  /**
   * Clean-Up / Destroy
   */
  destroy() {
    this.releaseContent();
    this.headers = null;
    this.archive = null;
    this.extractionPromise = null;
    this.cryptoInfo = null;
  }


  /**
   * Release (extracted) content to be cleaned-up by garbage collector
   */
  releaseContent = () => {
    this.fileContent = null;
    this.fileContentAsString = null;
    this.parsedFileContent = null;
  }


  // -----------------------------------------------------------------------------
  // "PRIVATE" METHODS
  // -----------------------------------------------------------------------------

  /**
   * Expands header bit flags into the boolean attributes "isEncrypted",
   * "isUTF8" and "usesTrailingDescriptor" of property @header
   */
  _processBitFlag() {
    const headers = this.headers;
    const bitFlag = headers.bitFlag;

    headers.isUTF8 = (bitFlag & 0x0800) === 0x0800;
    headers.usesTrailingDescriptor = (bitFlag & 0x0008) === 0x0008;
    headers.isEncrypted = (bitFlag & 0x0001) === 0x0001;
  }


  /**
   * Calculates the byte offset of the file content
   * start position within the ZIP archive.
   *
   * @return {Promise} Promise resolved with the file content's start offset
   */
  _findFileContentStart() {
    const start = this.headers.localHeaderOffset;

    return this.archive.getData(start, FIXED_LOCAL_HEADER_LENGTH)
      .then(
        data => {
          // Determine the variable length part from the *local* header information,
          // this data is both in the zip's central directory and the local header
          // available, use the info from local header:
          const fileNameLength = getLocalHeader(data, "fileNameLength");
          const extraFieldLength = getLocalHeader(data, "extraFieldLength");
          const headerLength = FIXED_LOCAL_HEADER_LENGTH + fileNameLength + extraFieldLength;

          return start + headerLength;
        }
      );
  }


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  /**
   * Indicates whether or not the archive entry is extracted/inflated.
   *
   * @return {boolean} Indicator if the file has been extracted/inflated
   */
  isExtracted() {
    return this.fileContent !== null;
  }


  /**
   * Extracts/inflates the file content from the ZIP archive.
   *
   * @param {Boolean} [keepRawData=false] Indicator to keep the zipped data in memory
   * @param {Number} [priority=0] Task priority, higher numbers represent higher priorities
   * @return {Promise} Promise of the deferred object
   */
  extract(keepRawData = true, priority = 1) {
    if (this.isExtracted()) {
      return Promise.resolve(this.fileContent);
    }

    // Extraction of this file already underway
    if (this.extractionPromise) {
      return this.extractionPromise;
    }

    const { compressedSize, compressionMethod, uncompressedSize } = this.headers;
    const isFileStored = compressionMethod === 0;

    return this.extractionPromise = this._findFileContentStart()
      // get the binary file as a typed `Uint8Array` array. If the data is compressed,
      // the buffer is cloned so that it is "transferable" for web workers.
      .then(start => this.archive.getData(start, compressedSize, !isFileStored)
        .then(bytes => {
          if (isFileStored) {
            // File is stored (no compression) -> ignore @keepRawData
            return this.decryptAndSetContent(bytes);
          }

          const workerManager = this.archive.getWorkerManager();
          return this.extractionPromise = workerManager.addToQueue(bytes, priority)
            .then(data => {
              if (data.length !== uncompressedSize) {
                logger.warn(`${TAG}: File size (${data.length} bytes) not equal to the value given in header (${uncompressedSize} bytes)`);
              }

              // Release allocated memory for zipped binary data
              if (!keepRawData) {
                this.archive.releaseData(start);
              }

              return this.decryptAndSetContent(data);
            });
        })
      );
  }


  /**
   * Returns file's path within the archive.
   *
   * @return {String} The file's path within the archive
   */
  getPath() {
    return this.headers.fileName;
  }


  /**
   * Returns the size (in bytes) of the compressed archive entry
   *
   * @returns {Number} Size (in bytes) of the compressed archive entry
   */
  getCompressedSize() {
    return this.headers.compressedSize;
  }


  /**
   * Returns the size (in bytes) of the uncompressed archive entry
   *
   * @returns {Number} Size (in bytes) of the uncompressed archive entry
   */
  getUncompressedSize() {
    return this.headers.uncompressedSize;
  }


  /**
   * Returns the file's content as a string
   *
   * @return {String} The file's content as a string
   */
  getFileContentAsString() {
    if (this.fileContentAsString) {
      return this.fileContentAsString;
    }

    if (!this.fileContent) {
      return '';
    }

    const isUTF16 = ByteArray.hasUTF16ByteOrderMark(this.fileContent);
    this.fileContentAsString = isUTF16
      ? ByteArray.asUTF16String(this.fileContent)
      : ByteArray.asUTF8String(this.fileContent);

    return this.fileContentAsString;
  }


  /**
   * Returns the file's content as a Base64 string
   *
   * @return {String} The file's content as a Base64 string
   */
  getFileContentAsBase64() {
    return this.fileContent
      ? ByteArray.asBase64(this.fileContent)
      : '';
  }


  /**
   * Returns the file content as a parsed DOM element
   *
   * @param {String} [mimeType="text/xml"] MIME type used to parse the file content,
   *                 "text/xml", "text/html" or "application/xhtml+xml"
   * @return {Document} The file's content as a Document according to
   *                    @mimeType, e.g. XMLDocument for "text/xml"
   */
  getFileContentAsDOM(mimeType = 'text/xml') {
    const __getParsedFileContent = () =>
      this.parsedFileContent.cloneNode(true);

    if (this.parsedFileContent instanceof Document) {
      return __getParsedFileContent();
    }

    try {
      let content = unescapeEntities(this.getFileContentAsString(), true);
      if (content.indexOf('xml version="1.1"') > -1) {
        content = content.replace('xml version="1.1"', 'xml version="1.0"');
      }

      const parser = new DOMParser();

      this.parsedFileContent = parser.parseFromString(content, mimeType);
      return __getParsedFileContent();
    } catch (e) {
      logger.error(`${TAG} Parsing error in "getFileContentAsDOM" ${e}`);
      return null;
    }
  }


  /**
   * Returns the file content as a parsed CSS RuleSet object.
   * The parsed content is cached in instance variable `parsedCSS`.
   *
   * @return {RuleSet|String} CSS RuleSet object if the file content could be
   *                  parsed as CSS rules, otherwise the file content as string.
   */
  getFileContentAsCSSRuleSet() {
    const getParsedCSS = () => this.parsedCSS;

    if (this.parsedCSS) {
      return getParsedCSS();
    }
    const fileContent = this.getFileContentAsString();
    this.parsedCSS = parse(fileContent);
    return getParsedCSS();
  }

  // -----------------------------------------------------------------------------
  // CRYPTOGRAPHY / DRM
  // -----------------------------------------------------------------------------

  /**
   * Store information to decrypt the file represented by this instance of
   * this class.
   *
   * @param {object} cryptInfo { type, userId, deviceId, rawKey }
   */
  setCryptoInfo(cryptoInfo) {
    this.cryptoInfo = cryptoInfo;
  }

  /**
   * Loads kobo services and inits the wasm module used for decryption
   * The module is also used for checking if content is actually crypted
   */
  decryptKoboRXContent(bufferView) {
    const drmService = this.services.drm;
    const readContentService = this.services.readContent;
    const sessionService = this.services.session;

    if (drmService && readContentService && sessionService) {
      //chapterURL.replace(/streamer\/.+?\//, '')
      const productId = readContentService.getCurrentProductId();
      const fileName = this.headers.fileName;
      return sequential([
        () => drmService.fetchWASM(productId),
        () => sessionService.fetchSession()
      ])
        .then(([wasm, sessionId]) => {
          const shouldDecrypt = wasm?.should_get(fileName);
          if (shouldDecrypt) {
            const decryptedContent = wasm.get(
              sessionId.replace(/\-/g, ''),
              fileName,
              bufferView.buffer
            );

            return decryptedContent;
          }
          return bufferView;
        });
    }
  }

  /**
   * Helper to fetch if app is using kobo services
   */
  async isKoboWebRX() {
    const featureFlagService = this.services.featureFlag;
    const readContentService = this.services.readContent;
    const localConfigService = this.services.localConfig;

    if (!readContentService) {
      return false;
    }

    const appConfig = localConfigService.getLocalConfig();
    const rc = await readContentService.fetchReadContent();

    return featureFlagService.isApplicationFeatureEnabled(appConfig.FEATURES.DRM_KOBO)
      && rc?.contentDownloadInfo.drmType === 'KDRM';
  }


  decryptAndSetContent(data) {
    return this.decryptDRM(data)
      .then((decryptedContent) => this.fileContent = decryptedContent)
      .finally(() => this.extractionPromise = null);
  }


  /**
   * Decrypts @data if there's a DRM key available, otherwise returns @data.
   *
   * @param {Uint8Array} data Unzipped binary file data
   * @return {Uint8Array} Decrypted @data
   */
  async decryptDRM(data) {
    // check if we need to decrypt kobo RX content
    if (await this.isKoboWebRX()) {
      return this.decryptKoboRXContent(data);
    } else {

      const cryptoInfo = this.cryptoInfo;
      if (cryptoInfo) {
        const crypto = new this.archive.getCryptoService.initCrypto();

        // We handle various types of encryption in here for different business
        // units. In a future release, this could be split up into various
        // services/methods.
        // KOBO
        if (cryptoInfo.encryptionType === 'KOBO_DRM' && cryptoInfo.rawKey) {
          crypto.setKoboKey(cryptoInfo);
          return Promise.resolve(crypto.decryptECB(data));
        }

        // TOLINO
        if (cryptoInfo.devKey) {
          crypto.setDeviceKey(cryptoInfo.devKey);
          const decryptedData = crypto.decryptCBC(cryptoInfo.key, cryptoInfo.vector, data);
          if (decryptedData !== null) {
            return Promise.resolve(decryptedData);
          }
        }
      }

    }
    // No information to decrypt or decryption failed, so just restore the raw data
    return Promise.resolve(data);

  }

}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default ArchiveEntry;
