/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

export const TAG = '[tolino] [service/dogear-kobo]';

export const DOGEAR_ERROR_CODES = Object.freeze({

  MISSING_ID: {
    message: "No id given to load publication",
    name: "MissingId"
  },

  MISSING_PLAYLOAD: {
    message: "No dogear to save last reading position.",
    name: "MissingPayload"
  },

  FAULTY_PAYLOAD: {
    message: "Last reading position failed",
    name: "FaultyPayload"
  },

  FAILES_REQUEST: {
    message: "Last reading position failed. Fetch failed or server not responded with valid json.",
    name: "FailedRequest"
  },

  ERROR: {
    message: "Unexpected error",
    name: "Error"
  }
});
