/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import color from "./color-palette";
import COMMON_COLORS_DEFAULT from "./../common/colors-default";

// -----------------------------------------------------------------------------
// DEFAULT COLORS
// -----------------------------------------------------------------------------

const COLORS_DEFAULT = {
  ...COMMON_COLORS_DEFAULT,

  // Named color definitions shared by each color theme
  mediumGrey:          color.mediumGrey,
  sepia:               color.sepiaBright,
  whiteWarm:           color.whiteWarm,

  // Some colors from all themes need to be made available in other themes
  themeTextCopyCharcoal:   color.ghostWhite,
  themeTextCopyDark:       color.lightGrey,
  themeTextCopyLight:      color.slateGrey,
  themeTextCopySepia:      color.sepiaBlack,
  
  themeBackground: {
    charcoal:          color.anthracite,
    dark:              color.black,
    light:             color.white,
    sepia:             color.sepiaBright
  },
  
  // Exceptions
  dropdownBackgroundHover: color.tolinoBlueMedium,
  inputBorder:             color.mediumGrey,
  inputBackgroundHover:    color.altWhite,
  toggleActive:            color.tolinoBlue
};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default COLORS_DEFAULT;
