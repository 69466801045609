/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import React, { createContext, useContext } from "react";

// Internal dependencies
import { getThemes } from "@rakuten/themes/";
import { locateService } from '~services/locator/locator';

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

let config = {};

// Initialize service dependencies
/*(function(){
  return locateService("localConfig")
    .then(localConfigService => {
      config = localConfigService.getLocalConfig();
    });
})();*/

/**
 * Map of themes from the default theme set.
 * Each item in `THEMES` is a JS theme object referenced by the theme name.
 * @type {Object}
 */
const THEMES = getThemes();

/**
 * List of theme names as defined in `THEMES`, e.g. "theme-tolino-light"
 * @type {Array}
 */
const THEME_NAMES = Object.keys(THEMES);

// -----------------------------------------------------------------------------
// HELPER
// -----------------------------------------------------------------------------

/**
 * Derives the theme name from @props. The derivation of the theme can become
 * more complicated if more parameters are added in the future.
 *
 * @param {Object} props Properties of the ThemeContextProvider component
 * @return {String} theme name derived from @props
 */
function getThemeName(props) {
  return props.appearance;
}


// -----------------------------------------------------------------------------
// THEME CONTEXT AND THEME PROVIDER
// -----------------------------------------------------------------------------

export const ThemeContext = createContext();


export default class ThemeContextProvider extends React.Component {

  constructor(props) {
    super(props);

    // The list of change listeners, components _may_ register event listeners
    // to update their styles when the theme is changing
    this.changeListeners = [];

    const themeName = getThemeName(props) || THEME_NAMES[0];

    // The internal state is passed with the `ThemeContext` as value to the theme
    // consumers.
    this.state = {
      themeName,                // Current theme name
      theme: THEMES[themeName], // Current theme object (`colors`, `variables`, ...)
      breakpoint: {},
      orientation: null,
      themes: THEMES,           // Map of all themes
      themeNames: THEME_NAMES,  // Array of available theme names
      addChangeListener: this.addChangeListener,
      removeChangeListener: this.removeChangeListener,
      setTheme: this.setTheme
    };

    this.subscriptions = {
      breakpointChange: null
    };

    locateService?.("localConfig")
      .then(localConfigService => {
        config = localConfigService.getLocalConfig();
      });
  }

  /**
   * Any context consumer may register a listener to update the styles automatically
   * when the theme has changed. The callback is called with the new theme as argument.
   *
   * @param {Function} callback Callback to calculate a component's style
   */
  addChangeListener = (callback) => {
    if (this.changeListeners.indexOf(callback) === -1) {
      this.changeListeners.push(callback);
    }
  }

  /**
   * Removes a previously registered change listener @callback.
   *
   * @param {Function} callback Callback to calculate a component's style
   */
  removeChangeListener = (callback) => {
    const index = this.changeListeners.indexOf(callback);
    if (index >= 0) {
      this.changeListeners.splice(index, 1);
    }
  }

  /**
   * Sets the theme identified by @themeName as the current theme and executes
   * all registered change listeners with the new theme object as argument.
   *
   * @param {String} themeName Name of the theme to be set
   */
  setTheme = (themeName) => {
    if (themeName === this.state.themeName) {
      return;
    }
    const theme = THEMES[themeName];
    // change listeners must be called _before_ `setState`!
    // On value (state) change, the context provider triggers a re-render of all
    // context consumers -> component styles should be updated before render
    this.changeListeners.forEach((callback) => callback(theme));

    this.setState({ themeName, theme });

    // Update the application's background color to prevent accidental flashes
    // ATTENTION: This method has to be implemented for all subclasses, it is
    //            not part of this class. If the method is not available, the
    //            app will crash!
    this.updateApplicationBackground({ themeName, theme });
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE HOOKS
  // ---------------------------------------------------------------------------

  componentDidMount() {
    locateService('messageBus')
      .then(messageBus => {
        this.subscriptions.breakpointChange = messageBus.subscribe(
          config.MESSAGE_BUS.CHANNEL.APP,
          config.MESSAGE_BUS.MESSAGE.DEVICE_BREAKPOINT_CHANGE,
          breakpoint => {
            this.setState({
              breakpoint
            });
          }
        );
        this.subscriptions.orientationChange = messageBus.subscribe(
          config.MESSAGE_BUS.CHANNEL.APP,
          config.MESSAGE_BUS.MESSAGE.DEVICE_ORIENTATION_CHANGE,
          orientation => {
            this.setState({
              orientation
            });
          }
        );
      });
  }

  componentWillUnmount() {
    locateService('messageBus')
      .then(mB => mB.unsubscribe(this.subscriptions.breakpointChange));
  }

  shouldComponentUpdate(nextProps) {
    const themeName = getThemeName(nextProps);

    if (themeName && themeName !== this.state.themeName) {
      this.setTheme(themeName);
      return true;
    }
    // If the ThemeContextProvider is used within Storybook, we get new
    // child components whenever a control/prop changes. Therefore, we need
    // to always update this component.
    return nextProps.breakpoint !== this.state.breakpoint
      || this.props.context === 'storybook';
  }


  render() {
    return (
      <ThemeContext.Provider value={this.state}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}


/**
 * Convenience function returning a component with current theme as additional
 * property.
 * Note: Other values or functions from the theme context are _not_ provided.
 *
 * @param {React.Component} Component
 * @return {React.Component} Component with additional `theme` property
 */
export function withTheme(Component) {
  function ComponentWithTheme(props, ref) {
    const context = useContext(ThemeContext);
    return <Component {...props} ref={ref} theme={context.theme} />;
  }

  // hoistNonReactStatics(ComponentWithTheme, Component);
  ComponentWithTheme.displayName = `WithTheme(${Component.displayName || Component.name})`;
  return React.forwardRef(ComponentWithTheme);
}

/** Alternative implementation using the `ThemeContext` component
export function withTheme(Component) {
  return function ThemeComponent(props) {
    return (
      <ThemeContext.Consumer>
        { (context) => <Component {...props} theme={ context.theme } /> }
      </ThemeContext.Consumer>
    )
  }
}
*/

/**
 * Check if the provided @themeName is a supported theme
 *
 * @param {string} themeName The name of the theme to check
 * @return {boolean} Whether or not @themeName is supported
 */
export function isValidTheme(themeName) {
  return THEME_NAMES.indexOf(themeName) > -1;
}


/**
 * Re-export the breakpoint IDs to have easier access to them within
 * style functions.
 */
export function getBreakpointIDs() {
  return config?.MEDIA_QUERY?.BREAKPOINT_IDS || {};
}


/**
 * Re-export the breakpoints, i.e. the pixel measurements, to have easier
 * access to them within style functions.
 */
export function getBreakpoints() {
  return config?.MEDIA_QUERY?.BREAKPOINTS || {};
}
