/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { TAG } from "./dogear.constants";
import { DogearError } from "./dogear.error";
import { preventConcurrentExec } from "~common/util/promise";

// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["dogear", ["localConfig", "session", "fetch"], createDogearService];

// -----------------------------------------------------------------------------
// Kobo Dogear SERVICE
// -----------------------------------------------------------------------------

/**
 * Factory function which creates a service with given
 * dependencies
 */
export function createDogearService(localConfig, sessionService, fetchService) {

  // App configuration
  const appConfig = localConfig.getLocalConfig();
  let dogears = [];

  // Add or Delete dogears, depending on passed body
  const patchAnnotations = (body, entitlementId) => {
    return sessionService.fetchSession()
      .then(() => {
        return fetchService.fetch({
          data: JSON.stringify(body),
          options: {
            credentials: 'include',
            method: 'PATCH',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            responseType: 'json',
            keepalive: true
          },
          url: `${appConfig.READINGSERVICES.BASE_URL}/api/v3/content/${entitlementId}/annotations`,
          timout: 10000
        })
          .then(response => {
            return response;
          })
          .catch((error) => {

            //pass through for DogearErrors
            if (error instanceof DogearError) {
              return Promise.reject(error);
            }
            return Promise.reject(new DogearError("ERROR", { detail: { parent: error } }));

          });
      });
  }

  // Prevent multiple calls to functions that create the same promise
  const guardedPromise = preventConcurrentExec();

  const fetchDogears = (id, pageOffsetToken) => {
    const url = pageOffsetToken? `?pageOffsetToken=${pageOffsetToken}` : ''
    return sessionService.fetchSession()
      .then(() => {
        return fetchService.fetch({
          url: `${appConfig.READINGSERVICES.BASE_URL}/api/v3/content/${id}/annotations${url}`,
          method: 'GET',
          options: {
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          },
          timout: 10000
        })
          .then(response => {
            dogears=[...dogears, ...response.annotations];
            if (response.nextPageOffsetToken)
            //manage multiple calls
              return fetchDogears(id, response.nextPageOffsetToken);
            else return {annotations: dogears?.filter((a) => a.type === 'dogear')};
          })
          .catch((error) => {

            //pass through for DogearErrors
            if (error instanceof DogearError) {
              return Promise.reject(error);
            }
            return Promise.reject(new DogearError("ERROR", { detail: { parent: error } }));

          });
      });
  }

  /**
   * getDogears: get all the current dogears
   */
  function getDogears(entitlementId) {

    return guardedPromise(`${TAG}:getDogears`, () => {

      if (!entitlementId) {
        return Promise.reject(new DogearError("MISSING_ID"));
      }

      return fetchDogears(entitlementId);
    });
  }

  /**
   * addDogear add the requested dogear on server side
   */
  function addDogear(dogear, entitlementId) {

    return guardedPromise(`${TAG}:addDogear`, () => {

      if (!entitlementId) {
        return Promise.reject(new DogearError("MISSING_ID"));
      }

      if (!dogear) {
        return Promise.reject(new DogearError("MISSING_PAYLOAD"));
      }

      const body = {
        updatedAnnotations: [
          {
            type: 'dogear',
            clientLastModifiedUtc: new Date(Date.now()).toISOString(),
            context: dogear.context,
            location: {
              span: {
                startPath: dogear.begin?.serializeString,
                endPath: dogear.end?.serializeString,
                chapterTitle: dogear.title,
                chapterFilename: (dogear.begin?.serializeString.split(':'))?.[0] || dogear.chapter,
                chapterProgress: dogear.begin?.pagePercentageInChapter * 100,
                startChar: dogear.begin?.charOffset,
                endChar: dogear.end?.charOffset
              }
            }
          }
        ]
      }
      return patchAnnotations(body, entitlementId);

    });
  }

  /**
    * removeDogear remove the requested dogear on server side
    */
  function deleteDogears(dogearIds, entitlementId) {

    return guardedPromise(`${TAG}:deleteDogear`, () => {

      if (!entitlementId) {
        return Promise.reject(new DogearError("MISSING_ID"));
      }

      if (!dogearIds || dogearIds.length <= 0) {
        return Promise.reject(new DogearError("MISSING_PAYLOAD"));
      }

      const body = {
        deletedAnnotationIds: dogearIds
      }

      return patchAnnotations(body, entitlementId);

    });
  }
  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    getDogears,
    addDogear,
    deleteDogears
  };
}
