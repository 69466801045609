import Color from "@rakuten/common-util/color";
import defaultColorPalette from "./../common/color-palette";

// base colors as `Color` objects, used to derive colors with `lightness`,
// `saturation`, or `withAlpha`
const lightYellow = new Color("hsl(59, 100%, 83%)");
const tolinoBlue  = new Color(70, 152, 203);  // hsl(203, 56%, 54%)
const orangeRed   = new Color(227, 82, 5);

const colorPalette = {
  ...defaultColorPalette,

  tolinoBlue:        tolinoBlue.toString(),                                       // base color for tolino themes
  tolinoBlue12:      tolinoBlue.withAlpha(0.12).toString(),
  tolinoBlueBright:  tolinoBlue.lightness(95).withoutAlpha().toRGBString(),     // rgb(235, 244, 249)
  tolinoBlueLight:   tolinoBlue.lightness(88).saturation(58).toRGBString(),     // rgb( 86, 162, 210)
  tolinoBlueMedium:  tolinoBlue.lightness(76.7).toRGBString(),                  // rgb(162, 203, 229)
  tolinoBluePastel:  tolinoBlue.lightness(34.3).saturation(37.1).toRGBString(), // rgb( 55,  95, 120)
  tolinoBlueShaded:  tolinoBlue.lightness(38.6).saturation(27.9).toRGBString(), // rgb( 71, 104, 126)
  tolinoBlueShine:   tolinoBlue.lightness(88).saturation(58).toRGBString(),     // rgb(207, 229, 242)
  tolinoBlueTinted:  tolinoBlue.lightness(44).saturation(56).toRGBString(),     // rgb( 49, 127, 175)

  lightYellow:       lightYellow.toRGBString(),
  gentleYellow:      "hsla(59, 81%, 79%, 0.60)",
  orangeRed:         orangeRed.toRGBString(),

  // sepia variants
  sepiaBlack:        "hsl(26, 35.6%, 17.6%)",          // rgb( 61,  43,  29)
  sepiaBright:       "hsl(38, 45.5%, 84.9%)",          // rgb(234, 221, 199)

  // shades of grey
  darkCharcoal:      "rgb(20, 20, 20)",                // hsl(0, 0%, 8%)
  charcoal:          "rgb(35, 35, 35)",                // hsl(0, 0%, 13.7%)
  nightGrey:         "rgb(51, 51, 51)",                // hsl(0, 0%, 20%)
  anthracite:        "rgb(73, 73, 74)",                // hsl(240, 1%, 29%)
  slateGrey:         "rgb(76, 76, 76)",                // hsl(0, 0%, 30%)
  slateGrey16:       "rgba(76, 76, 76, 0.16)",         // hsla(0, 0%, 30%, .16)
  gentleGrey:        "rgb(117, 117, 117)",             // hsl(0, 0%, 46%)
  flintGrey:         "rgb(125, 123, 120)",             // hsl(32, 2%, 48%)
  mediumGrey:        "rgb(127, 127, 127)",             // hsl(0, 0%, 50%)
  quickSilver:       "rgb(153, 153, 153)",             // hsl(0, 0%, 60%)
  quickSilver50:     "rgba(153, 153, 153, 0.50)",      // hsla(0, 0%, 60%, .50)
  cloudGrey:         "rgb(204, 204, 204)",             // hsl(0, 0%, 80%)
  cloudGrey32:       "rgba(204, 204, 204, 0.32)",      // hsla(0, 0%, 80%, .32)
  cloudGrey50:       "rgba(204, 204, 204, 0.5)",       // hsla(0, 0%, 80%, .5)
  lightGrey:         "rgb(211, 211, 211)",             // hsl(0, 0%, 82.7%)
  platinumGrey:      "rgb(217, 217, 217)",             // hsl(0, 0%, 85%)
  whiteWarm:         "rgb(224, 224, 224)",             // hsl(0, 0%, 88%)
  ghostWhite:        "rgb(242, 242, 242)",             // hsl(0, 0%, 95%)
  ghostWhite32:      "rgba(242, 242, 242, 0.32)",      // hsla(0, 0%, 95%, .32)
  altWhite:          "rgb(247, 247, 247)"              // hsl(0, 0%, 97%)
};

export default colorPalette;
