/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { memoize } from '@rakuten/common-util/function';
import { decodeString } from '@rakuten/common-util/string';
import SHA256 from './SHA256';
import AESDecrypt from './AESDecrypt';
export * as SHA1 from './SHA1';
export * as ROT8000 from './crypto.service';

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Tag for log output etc.
const TAG = '[services/crypto]';

const SHARED_KEY_TOLINO = "e\t\n\v\f\0\x0E\x16\x10õ\x12#\n\0\x16d";

// Memoized SHA256 function to optimize performance for subsequent calls w/ same params
const SHA256memo = memoize(SHA256, { noRefs: true, numArgs: 1 });

const PADDING_TYPE = {
  NONE : 0,
  PKCS5: 0x07,
  PKCS7: 0xFF
};


// -----------------------------------------------------------------------------
// PRIVATE MODULE METHODS
// -----------------------------------------------------------------------------

/**
 * Converts an unsigned integer value to a 2 character hex string
 *
 * @param {number} intValue Integer value to be converted to a hex string
 * @return {String} Hex string for @intValue, padded to a length of 2 characters
 */
function int2HexString(intValue) {
  return intValue < 16
    ? '0' + intValue.toString(16)
    : intValue.toString(16);
}


/**
 * Transforms a 16-byte array to a 4-Uint32 array
 *
 * @param {Array} arr16 Array to be converted
 * @return {Array} Array of (signed) 32-bit integers
 */
function byte2intArray(arr16) {
  var int32Array = new Array(4);

  for (var i = 0, j = 0; i < 16; i += 4) {
    // transform a 4-byte block to a (signed) integer:
    int32Array[j++] = arr16[i] << 24 | arr16[i + 1] << 16 | arr16[i + 2] << 8 | arr16[i + 3];
  }
  return int32Array;
}


/**
 * Returns the given byte array without trailing padding (PKCS5/7)
 * see https://en.wikipedia.org/wiki/Padding_%28cryptography%29
 *
 * @param {Uint8Array} byteData Plaintext data with padding, aligned to a block size
 * @param {Number} [paddingType=PKCS7] Removes optional block padding
 * @return {Uint8Array} byteData without trailing padding
 */
function removePadding(byteData, paddingType = PADDING_TYPE.PKCS7) {
  if (paddingType === PADDING_TYPE.NONE) {
    return byteData;
  }
  const length = byteData.length;
  // with padding, the last byte is defining the number of trailing bytes (padding length)
  const lastByte = byteData[length - 1] & paddingType;
  return byteData.subarray(0, length - lastByte);
}


// -----------------------------------------------------------------------------
// CRYPTO CLASS
// -----------------------------------------------------------------------------

class Crypto {

  constructor() {
    this.deviceKey = null;
    this.aesDecrypt = new AESDecrypt();
  }


  // ---------------------------------------------------------------------------
  // "PRIVATE METHODS
  // ---------------------------------------------------------------------------

  /**
   * Decrypts the given key string to an array of bytes
   *
   * @param {String} key Key to be decrypted
   * @return {Uint8Array} Decrypted key as array of bytes
   */
  _decryptKey2Array(key) {
    this.aesDecrypt.setDecryptionKey(byte2intArray(this.deviceKey));
    return this.decryptECB(key, PADDING_TYPE.NONE);
  }


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  /**
   * Sets the device key required for the subsequent decryption processes
   *
   * @param {String} deviceKey Base64-encoded device key to be decrypted
   */
  setDeviceKey(deviceKey) {
    console.assert(!!deviceKey, "setDeviceKey: Device key required");

    const decodedDeviceKey = decodeString(deviceKey);
    const sharedKey = SHARED_KEY_TOLINO.split("").map((c) => c.charCodeAt(0));

    this.aesDecrypt.setDecryptionKey(byte2intArray(sharedKey));
    this.deviceKey = this.decryptECB(decodedDeviceKey, PADDING_TYPE.NONE);
  }


  /**
   * Generates and sets the decryption key from @keys required for the Kobo decryption process.
   *
   * @param {Object} keys Map of keys w/ attributes `deviceId`, `userId`, and `rawKey`
   */
  setKoboKey(keys) {
    const decodedRawKey = decodeString(keys.rawKey);

    const decryptRawKey = () => {
      const keyAES = SHA256memo(keys.deviceId + keys.userId).slice(4);
      this.aesDecrypt.setDecryptionKey(keyAES);
      return this.decryptECB(decodedRawKey, PADDING_TYPE.NONE);
    };

    const decryptionKey = keys.deviceId && keys.userId
      ? decryptRawKey()
      : decodedRawKey;

    this.aesDecrypt.setDecryptionKey(byte2intArray(decryptionKey));
  }


  /**
   * Decrypts the given publication @key
   *
   * @param {String} key Publication key
   * @return {String} Decrypted content as UTF-8 string
   */
  decryptPassword(key) {
    console.assert(!!this.deviceKey, "deviceKey is set");
    var decryptedKey = this._decryptKey2Array(key);
    return Array.prototype.map.call(decryptedKey, int2HexString).join("");
  }


  /**
   * Decrypt AES @cipherData in cipher-block chaining mode with padding (PKCS5/7)
   * using the user @key and initialization vector @initVector.
   *
   * @param {String} key Publication key
   * @param {Array} initVector Initialization vector as array of bytes
   * @param {Uint8Array} cipherData The cipher data as array of bytes
   * @return {Uint8Array} decrypted plain text
   */
  decryptCBC(key, initVector, cipherData) {
    console.assert(!!this.deviceKey, "deviceKey is set");

    const size = cipherData.length;
    if (size % 16 !== 0) {
      // TODO: Replace with logger
      console.warn(`${TAG} decryptCBC: Invalid AES block length (${size})`);
      return null;
    }

    const decryptedKey = this._decryptKey2Array(key);
    this.aesDecrypt.setDecryptionKey(byte2intArray(decryptedKey));

    var outData = new Uint8Array(new ArrayBuffer(size));
    var i = 0, i16, c = 0;

    // decrypt first block of 16 bytes using the initialization vector,
    // the result is written as to ArrayView outData starting at position i:
    this.aesDecrypt.decrypt(cipherData, outData, i);
    while (i < 16) {
      outData[i++] ^= initVector[c++];
    }
    // decrypt remaining blocks of cipher data using the previous block as XOR-vector:
    c = 0;
    while (i < size) {
      this.aesDecrypt.decrypt(cipherData, outData, i);
      for (i16 = i + 16; i < i16; ) {
        outData[i++] ^= cipherData[c++];
      }
    }
    // remove optional padding and return
    return removePadding(outData, PADDING_TYPE.PKCS7);
  }


  /**
   * Decrypts an AES cipher in electronic codebook mode
   *
   * @param {Array} cipherData The binary cipher data
   * @param {Number} [paddingType=PKCS7] Removes optional block padding when set !== NONE
   * @return {Uint8Array} decrypted plain text
   */
  decryptECB(cipherData, paddingType = PADDING_TYPE.PKCS7) {
    const size = cipherData.length;

    if (size % 16 !== 0) {
      throw new Error("Invalid AES block length");
    }

    const outData = new Uint8Array(new ArrayBuffer(size));

    for (let i = 0; i < size; i += 16) {
      // decrypt cipher data, the result is written to ArrayView outData starting at position i:
      this.aesDecrypt.decrypt(cipherData, outData, i);
    }
    return removePadding(outData, paddingType);
  }

}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default Crypto;
export {
  PADDING_TYPE
};
