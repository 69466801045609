/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import color from "./color-palette";

// -----------------------------------------------------------------------------
// DEFAULT COLORS
// -----------------------------------------------------------------------------

const COLORS_DEFAULT = {

  // Named color definitions shared by all themes
  black:               color.black,
  black12:             color.black12,
  black15:             color.black15,
  black20:             color.black20,
  black42:             color.black42,
  transparent:         color.transparent,
  white:               color.white,
  red:                 color.red,
  blue:                color.blue,
  yellow:              color.yellow

};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default COLORS_DEFAULT;
