/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

const BUFFER_LENGTH = 256;

// Decryption tables
const T_DEC_0   = new Int32Array(new ArrayBuffer(BUFFER_LENGTH * 4));
const T_DEC_1   = new Int32Array(new ArrayBuffer(BUFFER_LENGTH * 4));
const T_DEC_2   = new Int32Array(new ArrayBuffer(BUFFER_LENGTH * 4));
const T_DEC_3   = new Int32Array(new ArrayBuffer(BUFFER_LENGTH * 4));
const S_BOX     = new Uint8Array(new ArrayBuffer(BUFFER_LENGTH));
const S_BOX_INV = new Uint8Array(new ArrayBuffer(BUFFER_LENGTH));


/**
 * Initialize decryption tables using an IIFE to hide local variables
 */
(function initiDecryptionTables() {
  var d = [], th = [];
  var x, xInv, x2, x4, x8, s, tDec;

  // compute double and third tables
  for (var i = 0; i < 256; i++) {
    th[(d[i] = i << 1 ^ (i >> 7) * 283) ^ i] = i;
  }

  for (x = xInv = 0; !S_BOX[x]; x ^= x2 || 1, xInv = th[xInv] || 1) {
    // compute S_BOX
    s = xInv ^ xInv << 1 ^ xInv << 2 ^ xInv << 3 ^ xInv << 4;
    s = s >> 8 ^ s & 255 ^ 99;
    S_BOX[x] = s;
    S_BOX_INV[s] = x;
    // compute MixColumns
    x8 = d[x4 = d[x2 = d[x]]];
    tDec = x8 * 0x1010101 ^ x4 * 0x10001 ^ x2 * 0x101 ^ x * 0x1010100;

    T_DEC_0[s] = tDec = tDec << 24 ^ tDec >>> 8;
    T_DEC_1[s] = tDec = tDec << 24 ^ tDec >>> 8;
    T_DEC_2[s] = tDec = tDec << 24 ^ tDec >>> 8;
    T_DEC_3[s] = tDec = tDec << 24 ^ tDec >>> 8;
  }
})();


// -----------------------------------------------------------------------------
// PRIVATE MODULE METHODS
// -----------------------------------------------------------------------------

/**
 * Converts an unsigned 32bit integer to an array of 4 bytes in big endian
 * order, used as a replacement for the DataView setInt32 method since
 * DataViews are not supported by FireFox (up to v15).
 *
 * @param {Uint8Array} buffer The decrypted plaintext, this param is used as return value
 * @param {number} offset Start index of @buffer for writing the byte data to
 * @param {number} value Number to be converted
 */
function setInt32(buffer, offset, value) {
  buffer[offset++] = value >>> 24 & 0xFF;
  buffer[offset++] = value >>  16 & 0xFF;
  buffer[offset++] = value >>   8 & 0xFF;
  buffer[offset  ] = value        & 0xFF;
}


// -----------------------------------------------------------------------------
// AESDECRYPT CLASS
// -----------------------------------------------------------------------------

class AESDecrypt {

  constructor() {
    this.decryptionKey = null;
    this.numRounds = 0;
  }


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------


  /**
   * Schedules out an AES key for both encryption and decryption.
   *
   * @param {Array} key Decryption key as an array of 4, 6 or 8 words.
   */
  setDecryptionKey(key) {
    var keyLen = key.length;
    if (keyLen !== 4 && keyLen !== 6 && keyLen !== 8) {
      throw new Error(`Invalid AES key size (${keyLen}) ${key}`);
    }

    var keyBufferSize = 4 * keyLen + 28;
    var encKey = new Int32Array(new ArrayBuffer(keyBufferSize * 4));

    this.decryptionKey = new Int32Array(new ArrayBuffer(keyBufferSize * 4));
    this.numRounds = keyLen + 5;

    var i, j, tmp, val, rcon = 1;

    // initialize encryption key with key
    for (i = 0; i < keyLen; i++) {
      encKey[i] = key[i];
    }
    // schedule encryption keys
    for (i = keyLen; i < keyBufferSize; i++) {
      tmp = encKey[i - 1];
      // apply S_BOX
      if (i % keyLen === 0 || (keyLen === 8 && i % keyLen === 4)) {
        tmp = S_BOX[tmp >>> 24] << 24 ^ S_BOX[tmp >> 16 & 255] << 16 ^ S_BOX[tmp >> 8 & 255] << 8 ^ S_BOX[tmp & 255];

        // shift rows and add rcon
        if (i % keyLen === 0) {
          tmp = tmp << 8 ^ tmp >>> 24 ^ rcon << 24;
          rcon = rcon << 1 ^ (rcon >> 7) * 283;
        }
      }
      val = encKey[i - keyLen] ^ tmp;
      encKey[i] = val;
    }

    // schedule decryption keys
    for (j = 0; i; j++, i--) {
      tmp = encKey[j & 3 ? i : (i - 4)];
      val = (i <= 4 || j < 4)
          ? tmp
          : T_DEC_0[S_BOX[tmp >>> 24]] ^ T_DEC_1[S_BOX[tmp >> 16 & 255]] ^ T_DEC_2[S_BOX[tmp >> 8 & 255]] ^ T_DEC_3[S_BOX[tmp & 255]];
      this.decryptionKey[j] = val;
    }
  }


  /**
   * Decrypts an array of 4x4 bytes using 4 32bit (big-endian) words.
   *
   * @param {Uint8Array} data Ciphertext as an array of 16 bytes (data.length == 16)
   * @param {Uint8Array} out Decrypted plaintext, this param is used as return value
   * @param {Integer} offset Start index of the sliding window reading from @data and writing to @out
   */
  decrypt(data, out, offset) {
    const key = this.decryptionKey;
    const numRounds = this.numRounds;

    let i = offset;
    let a = (data[i++]<<24 | data[i++]<<16 | data[i++]<<8 | data[i++]) ^ key[0];
    let d = (data[i++]<<24 | data[i++]<<16 | data[i++]<<8 | data[i++]) ^ key[3];
    let c = (data[i++]<<24 | data[i++]<<16 | data[i++]<<8 | data[i++]) ^ key[2];
    let b = (data[i++]<<24 | data[i++]<<16 | data[i++]<<8 | data[i++]) ^ key[1];
    let a2, b2, c2, k;

    // inner rounds:
    for (i = 0, k = 4; i < numRounds; i++) {
      a2 = T_DEC_0[a>>>24 & 0xFF] ^ T_DEC_1[b>>16 & 0xFF] ^ T_DEC_2[c>>8 & 0xFF] ^ T_DEC_3[d & 0xFF] ^ key[k++];
      b2 = T_DEC_0[b>>>24 & 0xFF] ^ T_DEC_1[c>>16 & 0xFF] ^ T_DEC_2[d>>8 & 0xFF] ^ T_DEC_3[a & 0xFF] ^ key[k++];
      c2 = T_DEC_0[c>>>24 & 0xFF] ^ T_DEC_1[d>>16 & 0xFF] ^ T_DEC_2[a>>8 & 0xFF] ^ T_DEC_3[b & 0xFF] ^ key[k++];
      d  = T_DEC_0[d>>>24 & 0xFF] ^ T_DEC_1[a>>16 & 0xFF] ^ T_DEC_2[b>>8 & 0xFF] ^ T_DEC_3[c & 0xFF] ^ key[k++];
      a  = a2; b = b2; c = c2;
    }
    // apply last round (unfolded loop) to assign the result to the out buffer:
    setInt32(out, offset +  0, S_BOX_INV[a>>>24]<<24 ^ S_BOX_INV[b>>16 & 0xFF]<<16 ^ S_BOX_INV[c>>8 & 0xFF]<<8 ^ S_BOX_INV[d & 0xFF] ^ key[k++]);
    setInt32(out, offset + 12, S_BOX_INV[b>>>24]<<24 ^ S_BOX_INV[c>>16 & 0xFF]<<16 ^ S_BOX_INV[d>>8 & 0xFF]<<8 ^ S_BOX_INV[a & 0xFF] ^ key[k++]);
    setInt32(out, offset +  8, S_BOX_INV[c>>>24]<<24 ^ S_BOX_INV[d>>16 & 0xFF]<<16 ^ S_BOX_INV[a>>8 & 0xFF]<<8 ^ S_BOX_INV[b & 0xFF] ^ key[k++]);
    setInt32(out, offset +  4, S_BOX_INV[d>>>24]<<24 ^ S_BOX_INV[a>>16 & 0xFF]<<16 ^ S_BOX_INV[b>>8 & 0xFF]<<8 ^ S_BOX_INV[c & 0xFF] ^ key[k  ]);
  }

}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default AESDecrypt;
