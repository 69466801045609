export default function mergeRefs(...args) {
  return function forwardRef(node) {
    args.forEach((ref) => {
      if (ref == null) {
        return;
      }
      if (typeof ref === 'function') {
        ref(node);
        return;
      }
      if (typeof ref === 'object') {
        ref.current = node;
        return;
      }
      console.error(
        `mergeRefs cannot handle Refs of type boolean, number or string, received ref ${String(ref)}`
      );
    });
  };
}
