/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

const CSS_RULE = {
  "UNKNOWN_RULE":          0,
  "STYLE_RULE":            1,
  "CHARSET_RULE":          2,
  "IMPORT_RULE":           3,
  "MEDIA_RULE":            4,
  "FONT_FACE_RULE":        5,
  "PAGE_RULE":             6,
  "KEYFRAMES_RULE":        7,
  "KEYFRAME_RULE":         8,
  "MOZ_KEYFRAMES_RULE":    7,
  "MOZ_KEYFRAME_RULE":     8,
  "WEBKIT_KEYFRAMES_RULE": 7,
  "WEBKIT_KEYFRAME_RULE":  8,
  "NAMESPACE_RULE":       10,
  "COUNTER_STYLE_RULE":   11,
  "SUPPORTS_RULE":        12
};

const DUMMY_SELECTOR = ".xxx";

/**
 * Regular expression to match any unquoted CSS url, e.g. url(Font/Univers.otf)
 * @type {RegExp}
 */
const RE_UNQUOTED_URL = /url\(([^"'][^\)]*)\)/ig;


// -----------------------------------------------------------------------------
// PRIVATE METHODS
// -----------------------------------------------------------------------------

/**
 * Parses the passed @cssText into a list of CSS rules.
 *
 * @param {String} cssText CSS text to be parsed
 * @return {CSSRuleList} CSS rule list of the parsed @cssText
 */
function parseCSSText(cssText = "") {
  const doc = document.implementation.createHTMLDocument("");
  const styleElem = document.createElement("style");

  // The @cssText can contain unquoted url declarations, which is valid according
  // to the specification. However, if quotes are missing, the internal parser
  // will try to resolve the given URL immediately. Since the referenced file is
  // _not_ in the file system, the CSS parser would discard the declaration.
  styleElem.textContent = cssText.replace(RE_UNQUOTED_URL, (_, url) => `url("${url}")`);
  doc.body.appendChild(styleElem);

  return styleElem.sheet.cssRules;
}


// -----------------------------------------------------------------------------
// PUBLIC API
// -----------------------------------------------------------------------------

function RuleSet(cssRules) {
  // this.descriptors = this.cssRules = [];
  if (cssRules) {
    this.descriptors = this.cssRules = cssRules;
  }
}
RuleSet.prototype.cssText = function() {
  return this.descriptors?.map((rule) => rule.cssText).join("\n") || "";
}


function Rule(type, selectorText, cssText) {
  this.type = type || CSS_RULE.UNKNOWN_RULE;
  this.selectorText = selectorText || "";
  this.cssText = cssText || "";
}
Rule.prototype.STYLE_RULE         = CSS_RULE.STYLE_RULE;
Rule.prototype.CHARSET_RULE       = CSS_RULE.CHARSET_RULE;
Rule.prototype.IMPORT_RULE        = CSS_RULE.IMPORT_RULE;
Rule.prototype.MEDIA_RULE         = CSS_RULE.MEDIA_RULE;
Rule.prototype.FONT_FACE_RULE     = CSS_RULE.FONT_FACE_RULE;
Rule.prototype.PAGE_RULE          = CSS_RULE.PAGE_RULE;
Rule.prototype.KEYFRAMES_RULE     = CSS_RULE.KEYFRAMES_RULE;
Rule.prototype.KEYFRAME_RULE      = CSS_RULE.KEYFRAME_RULE;
Rule.prototype.NAMESPACE_RULE     = CSS_RULE.NAMESPACE_RULE;
Rule.prototype.COUNTER_STYLE_RULE = CSS_RULE.COUNTER_STYLE_RULE;
Rule.prototype.SUPPORTS_RULE      = CSS_RULE.SUPPORTS_RULE;


/**
 * Parses @cssText into a CSS RuleSet object.
 *
 * @param {String} cssText CSS text to be parsed
 * @return {Object} CSS Rule object of the parsed @cssText
 */
function parse(cssText) {
  return new RuleSet(Array.from(parseCSSText(cssText)));
}


/**
 * Parses @cssText as a map of CSS style declarations.
 * @cssText may not contain any selectors or rules!
 *
 * @param {String} cssText CSS declaration text to be parsed
 * @return {CSSStyleDeclaration} CSS style object representing the parsed styles of @cssText
 */
function parseDeclarations(cssText) {
  const parsedRuleSet = parseCSSText(`${DUMMY_SELECTOR} { ${cssText} }`);
  return parsedRuleSet[0]?.style;
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export {
  RuleSet,
  Rule,

  parse,
  parseDeclarations
};
